import { Button, Card, Dropdown, Form, Table } from 'react-bootstrap';
import styled from 'styled-components';

export const HeaderModal = styled.div`
  display: flex;
  width: 100%;
  height: 56px;
  color: ${({ theme }) => theme.colors.shape};
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.secundary};
  padding: 10px 18px;
`;

export const Description = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: left;
  color: ${({ theme }) => theme.colors.text};
  overflow: auto;
`;

export const Info = styled.div`
  display: flex;
  align-items: center;
`;

export const TitleModal = styled.span`
  margin-left: 5px;
  text-transform: uppercase;
  font-weight: 500;
`;

export const BoxCloseModal = styled.span`
  align-items: center;
  display: flex;
  border: none;
  cursor: pointer;
`;

export const CardHeader = styled(Card.Header)`
  display: flex;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.shape};
  align-items: center;
  padding: 20px;
`;

export const Title = styled.p`
  font-size: 24px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.secundary};
  margin: 0;
`;

export const InfoTitle = styled.p`
  font-family: 'Roboto';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.30000001192092896px;
  text-align: left;
  color: ${({ theme }) => theme.colors.text};
`;

export const InformationDescription = styled.p`
  font-family: 'Roboto';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 1px;
  text-align: left;
  opacity: 0.6;
  color: ${({ theme }) => theme.colors.text_secundary};
`;
export const SelectFilter = styled(Form.Select)`
  border: 1.5px solid #e1e6ef;
  border-radius: 0;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  letter-spacing: 0.3px;
  color: #1e212a;
  max-width: 142px;
  height: 44px;
  @media screen and (max-width: 768px) {
    max-width: 100%;
    margin-bottom: 10px;
  }
`;

export const CustomTable = styled(Table)`
  margin-top: 4px;

  .table-header {
    background-color: ${({ theme }) => theme.colors.secundary};
    color: ${({ theme }) => theme.colors.shape};
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 700;
    border: none;
    margin: 0;
  }

  .table-body td {
    padding: 30px 10px;
  }

  .table-body {
    border-top: none !important;
  }

  .table-body tr:nth-of-type(odd) {
    background-color: rgba(14, 134, 254, 0.05);
  }
`;

export const DropdownCustom = styled(Dropdown)`
  .dropdown-toggle::after {
    display: none !important;
  }

  .drop-button {
    background-color: #f8f9fa00;
    border: none;
    color: ${({ theme }) => theme.colors.text};
  }
`;

export const DropdownItemCustom = styled(Dropdown.Item)`
  font-size: 16px;
  color: ${({ theme }) => theme.colors.text};
  font-weight: 400;

  .icon-action {
    color: ${({ theme }) => theme.colors.text};
    font-size: 15px;
    margin-right: 8px;
  }
`;

export const ButtonReturn = styled(Button)`
  background-color: ${({ theme }) => theme.colors.secundary};

  &:hover {
    background-color: ${({ theme }) => theme.colors.secundary} !important;
    filter: brightness(80%);
    color: ${({ theme }) => theme.colors.shape};
  }
  &:focus {
    background-color: ${({ theme }) => theme.colors.secundary} !important;
  }
`;

export const Box = styled.div`
  display: flex;
  align-items: center;
`;

export const CardIcon = styled.div`
  width: 56px;
  height: 56px;
  background-color: ${({ theme }) => theme.colors.background_icon};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  color: ${({ theme }) => theme.colors.primary};
  margin-right: 16px;
`;

export const CardFooterCustom = styled(Card.Footer)`
  background-color: ${({ theme }) => theme.colors.shape};
`;

export const ContainerButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 16px 0px;
`;
