import React from 'react';
import Select from 'react-select';

type DataProps = {
  value: string;
  label: string;
};

type Props = {
  isLoading?: boolean;
  isDisabled?: boolean;
  options: any;
  name: string;
  onChange: (value: DataProps) => void;
  value: any;
  placeholder: string;
}

const SelectWithSearch = ({
  isDisabled,
  isLoading,
  options,
  name,
  onChange,
  value,
  placeholder
}: Props) => {
  return (
    <Select
      className="basic-single"
      classNamePrefix="select"
      isDisabled={isDisabled}
      isLoading={isLoading}
      isSearchable={true}
      name={name}
      options={options}
      placeholder={placeholder}
      onChange={onChange}
      menuPortalTarget={document.body}
      menuPosition={'fixed'}
      noOptionsMessage={() => 'Sem resultados.'}
      value={value}
      loadingMessage={() => 'Carregando...'}
      
    />
  )
}

export default SelectWithSearch;