import { useState, useEffect } from "react";
import {
  Card,
  Col,
  Container,
  Dropdown,
  Row,
  Tabs,
  Tab,
} from "react-bootstrap";
import {
  MdInfo,
  MdMoreVert,
  MdVisibility,
  MdEdit,
  MdAddCircle,
} from "react-icons/md";
import { IoMdTrash } from "react-icons/io";
import { useParams, useHistory } from "react-router-dom";
import Loading from "../../Components/Loading";
import {
  CardHeader,
  Title,
  InfoTitle,
  InformationDescription,
  CustomTable,
  DropdownCustom,
  DropdownItemCustom,
  ButtonReturn,
  Box,
  CardIcon,
  CardFooterCustom,
  BoxButton,
  ButtonCreate,
  NoFoundData,
} from "./style";
import { cpfMask } from "../../utils/cpfFormat";
import { HeaderProps, HeadersList, OrganProps } from "../../@types/organ";
import {
  deleteOrganHeader,
  getListHeader,
  getOrganDataById,
  getSectorData,
  getUserData,
} from "../../services/organ";
import dateFormatToBR from "../../utils/dateFormat";
import ModalInformationHeader from "./ModalInformationHeader";
import DeleteModal from "../../Components/DeleteModal";
import { useStateValue } from "../../providers/StateProvider";
import { actionTypes } from "../../store/reducer";
import ModalSector from "./ModalSector";
import Paginate from "../../Components/Paginate";
import { OrganUsers } from "./index.d";

const OrganInfo = () => {
  const [organInfo, setOrganInfo] = useState<OrganProps>();
  const [organHeaders, setOrganHeaders] = useState<HeadersList>();
  const [organSectors, setOrganSectors] = useState<any>();
  const [organUsers, setOrganUsers] = useState<OrganUsers>();
  const [modalSector, setModalSector] = useState<boolean>(false);
  const [userName, setUserName] = useState<string>();
  const [dataSelected, setDataSelected] = useState<HeaderProps>();
  const [showModalInformation, setShowModalInformation] =
    useState<boolean>(false);
  const [showModalDelete, setShowModalDelete] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [, dispatch] = useStateValue();
  const [userId, setUserId] = useState<number>();
  const { orgao_id } = useParams<{ orgao_id: string }>();
  const organId = parseInt(orgao_id);

  const history = useHistory();

  const getOrganData = async () => {
    try {
      setLoading(true);
      const { data } = await getOrganDataById(organId);
      setOrganInfo(data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  const getUserDataByOrganId = async ({ page }: { page: number }) => {
    try {
      setLoading(true);
      const { data } = await getUserData(organId, page);
      setOrganUsers(data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  const getSectorDataByOrgan = async ({ page }: { page: number }) => {
    try {
      setLoading(true);
      const { data } = await getSectorData(organId, page);
      setOrganSectors(data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  const getListHeaders = async () => {
    try {
      setLoading(true);
      const { data } = await getListHeader(organId);
      setOrganHeaders(data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const deleteHeader = async (id: string, organ: number) => {
    try {
      setLoading(true);
      await deleteOrganHeader(id, organ);
      await getListHeaders();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      setDataSelected(null);
      dispatch({
        type: actionTypes.SET_FLASH_MESSAGE,
        flashMessage: {
          show: true,
          type: "error-alt",
          title: "Excluido",
          message: "Cabeçalho excluido!",
        },
      });
      history.go(0);
    }
  };

  const handleShowModal = (data: HeaderProps) => {
    setDataSelected(data);
    setShowModalInformation(true);
  };

  const handleCloseModal = () => {
    setDataSelected(null);
    setShowModalInformation(false);
  };

  const handleDeleteModal = (del?: boolean, data?: HeaderProps): void => {
    setDataSelected(data);
    if (del) {
      setShowModalDelete(!showModalDelete);
      deleteHeader(dataSelected.id, dataSelected.orgao);
    } else {
      setShowModalDelete(!showModalDelete);
    }
  };

  useEffect(() => {
    getOrganData();
    getListHeaders();
    getSectorDataByOrgan({ page: 1 });
    getUserDataByOrganId({ page: 1 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loading ? (
        <div className="d-flex mt-4 justify-content-center">
          <Loading />
        </div>
      ) : undefined}
      <Container style={{ marginTop: 56 }}>
        {organInfo && (
          <>
            <Card>
              <CardHeader>
                <Box>
                  <CardIcon>
                    <MdInfo size={20} />
                  </CardIcon>
                  <Title>Informações do Órgão</Title>
                </Box>
              </CardHeader>
              <Card.Body>
                <Row>
                  <Col sm={4}>
                    <InfoTitle>Sigla</InfoTitle>
                    <InformationDescription>
                      {organInfo.sigla}
                    </InformationDescription>

                    <InfoTitle>Nome</InfoTitle>
                    <InformationDescription>
                      {organInfo.nome}
                    </InformationDescription>
                  </Col>
                  <Col sm={4}>
                    <InfoTitle>Slug</InfoTitle>
                    <InformationDescription>
                      {organInfo.slug}
                    </InformationDescription>
                    <InfoTitle>Adicionado</InfoTitle>
                    <InformationDescription>
                      {dateFormatToBR(organInfo.created_at, true)}
                    </InformationDescription>
                  </Col>
                  <Col>
                    <InfoTitle>Atualizado</InfoTitle>
                    <InformationDescription>
                      {dateFormatToBR(organInfo.updated_at, true)}
                    </InformationDescription>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
            <Container style={{ marginTop: 56, marginBottom: 20, padding: 0 }}>
              <Card>
                <CardHeader>
                  <Box>
                    <CardIcon>
                      <MdInfo size={20} />
                    </CardIcon>
                    <Title>Outras Informações</Title>
                  </Box>
                </CardHeader>
                {modalSector && (
                  <ModalSector
                    show={modalSector}
                    handleShow={() => setModalSector(false)}
                    organId={organId}
                    userId={userId}
                    userName={userName}
                    organUsers={organUsers?.results}
                    handleReload={getUserDataByOrganId}
                  />
                )}
                <Card.Body>
                  <Tabs
                    defaultActiveKey="users"
                    id="uncontrolled-tab-example"
                    className="mb-3"
                  >
                    <Tab eventKey="users" title="Usuários">
                      <CustomTable responsive="md">
                        {organUsers?.results?.length ? (
                          <>
                            <thead className="table-header">
                              <tr>
                                <th>nome</th>
                                <th>cpf</th>
                                <th>função</th>
                                <th>setor</th>
                                <th>ações</th>
                              </tr>
                            </thead>
                            <tbody className="table-body">
                              {organUsers?.results?.map(
                                (
                                  {
                                    first_name,
                                    username,
                                    perfil,
                                    orgao_usuario,
                                  },
                                  index
                                ) => (
                                  <tr key={username}>
                                    <td style={{ width: "40%" }}>
                                      {first_name}
                                    </td>
                                    <td>{cpfMask(username)}</td>
                                    <td>{perfil}</td>
                                    <td>
                                      {perfil !== "Coordenador"
                                        ? "Não aplicável"
                                        : orgao_usuario[0].id_setor === null
                                        ? "Sem setor"
                                        : orgao_usuario.length <= 4
                                        ? orgao_usuario
                                            .map((orgao) => orgao.setor_sigla)
                                            .join(", ")
                                        : `${orgao_usuario
                                            .slice(0, 4)
                                            .map((orgao) => orgao.setor_sigla)
                                            .join(", ")} + ${
                                            orgao_usuario.length - 4
                                          } setores`}
                                    </td>
                                    <td>
                                      {perfil !== "Coordenador" ? (
                                        "Não aplicável"
                                      ) : (
                                        <DropdownCustom>
                                          <Dropdown.Toggle
                                            id="dropdown-basic"
                                            className="drop-button"
                                          >
                                            <MdMoreVert
                                              size={20}
                                              className="icon"
                                            />
                                          </Dropdown.Toggle>
                                          <Dropdown.Menu>
                                            <DropdownItemCustom
                                              onClick={() => {
                                                setModalSector(true);
                                                setUserName(username);
                                                setUserId(
                                                  orgao_usuario[0]
                                                    .id_usuario_unidade
                                                );
                                              }}
                                            >
                                              {orgao_usuario[0].setor_sigla ? (
                                                <>
                                                  <MdEdit className="icon-action" />
                                                  Editar Setor
                                                </>
                                              ) : (
                                                <>
                                                  <MdAddCircle className="icon-action" />
                                                  Adicionar Setor
                                                </>
                                              )}
                                            </DropdownItemCustom>
                                          </Dropdown.Menu>
                                        </DropdownCustom>

                                        // <CustomButton
                                        //   variant="outline-secondary"
                                        //   onClick={() => {
                                        //     setModalSector(true);
                                        //     setUserId(id);
                                        //   }}
                                        // >
                                        //   <MdMoreVert
                                        //     size={20}
                                        //     className="icon"
                                        //   />
                                        // </CustomButton>
                                      )}
                                    </td>
                                  </tr>
                                )
                              )}
                            </tbody>
                            <tfoot className="table-foot">
                              {organUsers?.total_pages > 0 ? (
                                <Paginate
                                  flowData={organUsers}
                                  setPage={getUserDataByOrganId}
                                  page={organUsers?.current}
                                />
                              ) : undefined}
                            </tfoot>
                          </>
                        ) : (
                          <NoFoundData>Sem resultados!</NoFoundData>
                        )}
                      </CustomTable>
                    </Tab>
                    <Tab eventKey="sectors" title="Setores">
                      <CustomTable responsive="md">
                        {organSectors?.results?.length ? (
                          <>
                            <thead className="table-header">
                              <tr>
                                <th>sigla</th>
                                <th>nome</th>
                                <th>criado em</th>
                              </tr>
                            </thead>
                            <tbody className="table-body">
                              {organSectors?.results.map((sector: any) => (
                                <tr key={sector.id}>
                                  <td style={{ width: "40%" }}>
                                    {sector.sigla}
                                  </td>
                                  <td>{sector.nome}</td>
                                  <td>
                                    {dateFormatToBR(sector.updated_at, true)}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                            <tfoot className="table-foot">
                              {organSectors?.total_pages > 0 ? (
                                <Paginate
                                  flowData={organSectors}
                                  setPage={getSectorDataByOrgan}
                                  page={organSectors?.current}
                                />
                              ) : undefined}
                            </tfoot>
                          </>
                        ) : (
                          <NoFoundData>Sem resultados!</NoFoundData>
                        )}
                      </CustomTable>
                    </Tab>
                    <Tab eventKey="headers" title="Cabeçalhos">
                      <>
                        <BoxButton>
                          <ButtonCreate
                            variant="primary"
                            onClick={() => {
                              history.push(
                                `/orgaos/${orgao_id}/criar-cabecalho`
                              );
                            }}
                          >
                            <MdAddCircle
                              size={20}
                              style={{ marginRight: 10 }}
                            />
                            Criar cabeçalho
                          </ButtonCreate>
                        </BoxButton>
                        {organHeaders && organHeaders.results.length > 0 ? (
                          <>
                            <CustomTable responsive="md">
                              <thead className="table-header">
                                <tr>
                                  <th>titulo</th>
                                  <th>criado por</th>
                                  <th style={{ width: "10%" }}>ações</th>
                                </tr>
                              </thead>
                              <tbody className="table-body">
                                {organHeaders?.results.map((header, id) => (
                                  <tr key={id}>
                                    <td>{header.titulo}</td>
                                    <td>
                                      {header.user
                                        ? header.user.first_name
                                        : "Atendente"}
                                    </td>
                                    <td>
                                      <DropdownCustom>
                                        <Dropdown.Toggle
                                          id="dropdown-basic"
                                          className="drop-button"
                                        >
                                          <MdMoreVert
                                            size={20}
                                            className="icon"
                                          />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                          <DropdownItemCustom
                                            onClick={() =>
                                              history.push(
                                                `/orgaos/${header.id}/${header.orgao}/editar`
                                              )
                                            }
                                          >
                                            <MdEdit className="icon-action" />
                                            Editar
                                          </DropdownItemCustom>
                                          <DropdownItemCustom
                                            onClick={() =>
                                              handleShowModal(header)
                                            }
                                          >
                                            <MdVisibility className="icon-action" />
                                            Visualizar
                                          </DropdownItemCustom>
                                          <DropdownItemCustom
                                            onClick={() => {
                                              handleDeleteModal(false, header);
                                            }}
                                          >
                                            <IoMdTrash className="icon-action" />
                                            Excluir
                                          </DropdownItemCustom>
                                        </Dropdown.Menu>
                                      </DropdownCustom>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </CustomTable>
                          </>
                        ) : (
                          <NoFoundData>Sem resultados!</NoFoundData>
                        )}
                      </>
                    </Tab>
                  </Tabs>
                </Card.Body>
                <CardFooterCustom>
                  <ButtonReturn onClick={() => history.push("/orgaos")}>
                    Voltar
                  </ButtonReturn>
                </CardFooterCustom>
              </Card>
            </Container>
          </>
        )}
      </Container>

      {dataSelected ? (
        <ModalInformationHeader
          show={showModalInformation}
          handleShow={handleCloseModal}
          data={dataSelected}
        />
      ) : undefined}
      <DeleteModal show={showModalDelete} handleShow={handleDeleteModal} />
    </>
  );
};

export default OrganInfo;
