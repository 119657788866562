import { Card, Container, Dropdown, Table, Button, Form } from "react-bootstrap";
import { MdSearch } from "react-icons/md";
import styled from "styled-components";


export const Box = styled.div`
  display: flex;
  align-items: center;
`;

export const CardIcon = styled.div`
  width: 56px;
  height: 56px;
  background-color: ${({ theme }) => theme.colors.background_icon};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  color: ${({ theme }) => theme.colors.primary};
  margin-right: 16px;
`;
export const SelectFilter = styled(Form.Select)`
  border: 1.5px solid #e1e6ef;
  border-radius: 0;
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  letter-spacing: 0.3px;
  color: #1e212a;
  max-width: 142px;
  height: 44px;
  @media screen and (max-width: 768px) {
    max-width: 100%;
    margin-bottom: 10px;
  }
`;
export const CardHeader = styled(Card.Header)`
  display: flex;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.shape};
  align-items: center;
  padding: 20px;

  @media screen and (max-width: 768px){
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const Title = styled.p`
  font-size: 24px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.secundary};
  margin: 0;
`;

export const ContainerMain = styled(Container)`
  margin-top: 56px;
  margin-bottom: 20px;
`;

export const CustomTable = styled(Table)`
  margin-top: 32px;

  .table-header {
    background-color: ${({ theme }) => theme.colors.secundary};
    color: ${({ theme }) => theme.colors.shape};
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 700;
    border: none;
  }

  .table-body td {
    padding: 30px 10px;
  }

  .table-body {
    border-top: none !important;
  }

  .table-body tr:nth-of-type(odd) {
  background-color: rgba(14, 134, 254, 0.05);
}
`;

export const DropdownCustom = styled(Dropdown)`
  .dropdown-toggle::after {
    display: none !important;
  }

  .drop-button {
    background-color: transparent !important;
    border-color: rgb(225, 230, 239) !important;
    color: ${({ theme }) => theme.colors.text} !important;
    box-shadow: none !important ;

    :hover{
      background-color: #6c757d !important;
      border-color: #6c757d !important;
    }

    :active{
      background-color: #6c757d !important;
      border-color: #6c757d !important;
    }
    :focus{
      background-color: #6c757d !important;
      border-color: #6c757d !important;
    }

    :hover > .icon{
      color: #fff;
    }
    
    :active > .icon{
      color: #fff !important;
    }

    :focus > .icon{
      color: #fff !important;
    }
  }
`;

export const DropdownItemCustom = styled(Dropdown.Item)`
  font-size: 16px;
  color: ${({ theme }) => theme.colors.text};
  font-weight: 400;

  .icon-action {
    color: ${({ theme }) => theme.colors.text};
    font-size: 15px;
    margin-right: 8px;
  }
`;

export const ButtonLink = styled.button`
  all: unset;
`;

export const NoFoundData = styled.h4`
  display: flex;
  justify-content: center;
  margin-top: 10px;
`;

export const CardFooterCustom = styled(Card.Footer)`
  background-color: ${({ theme }) => theme.colors.shape};
  display: flex;
  justify-content: end;
`;

export const CustomButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.primary};
  border: none !important;

  @media screen and (max-width: 768px) {
    width: 100%;
    margin-top: 20px;
  }
`

export const CustomForm = styled(Form)`
  display: flex;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    width: 100%;
  }
`;

export const BoxSearch = styled.div`
  display: flex;
  align-items: center;
  width: 340px;
  height: 44px;
  background: #f3f5fb;
  border: 1.5px solid #e1e6ef;
  padding-left: 10px;
  
  @media screen and (max-width: 768px) {
    width: 100%;
    margin-bottom: 10px;
  }
`;

export const IconSearch = styled(MdSearch)`
  color: #1e212a;
`;

export const InputSearch = styled(Form.Control)`
  border: none;
  background:#f3f5fb !important;
  outline: none !important;
  box-shadow: none !important;
  width: 100% ;
`;

export const ButtonSearch = styled(Button)`
  margin-top: 0px !important ;
  background-color: ${({ theme }) => theme.colors.primary};
  border: none !important;
  border-radius: 0 4px 4px 0;
`;

export const ButtonClearSearch = styled(Button)`
  margin-top: 0px !important ;  
  background-color: ${({ theme }) => theme.colors.primary};
  border: none !important;
  border-radius: 0 4px 4px 0;
  display: flex;
  align-items: center;

  @media screen and (max-width: 768px) {
    justify-content: center ;
  }
`;