import React from "react";
import { Box, Button, CircularProgress, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { MdCheckCircle } from "react-icons/md";
import getCookie from "../../utils/getCookies";

interface SuccessContentProps {
  loading?: boolean;
  showButton?: boolean;
  onFinishButton: () => void;
}

const useStyles = makeStyles(() => ({
  title: {
    fontFamily: "Roboto",
    fontWeight: 500,
    color: "#264259",
    textTransform: "uppercase",
    textAlign: "center",
    fontSize: 16,
    padding: 20,
  },
  buttonForm: {
    backgroundColor: "#264259",
  },
  containerInfo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  }
}));

export default function SuccessContentForm({
  loading,
  showButton,
  onFinishButton,
}: SuccessContentProps): JSX.Element {
  const classes = useStyles();

  const renderProtocol = () => {
    if (!showButton) { // bool para saber se aplicação é 156
      const dataForm = JSON.parse(getCookie('gov_user_form_started') || '{}');

      return (
        <Typography variant="h6" className={classes.title}>
          Protocolo de Atendimento: {dataForm?.protocolo || ''}
        </Typography>
      )
    }
  }

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <Grid>
        <MdCheckCircle size={30} color="#0BB07B" />
      </Grid>
      <Box className={classes.containerInfo}>
        {renderProtocol()}
        <Typography variant="h6" className={classes.title}>
          Solicitação realizada com sucesso!
        </Typography>
      </Box>

      {loading ? (
        <CircularProgress />
      ) : (
        <>
          {showButton && (
            <Button
              className={classes.buttonForm}
              variant="contained"
              fullWidth
              color="primary"
              type="submit"
              style={{ marginTop: 20 }}
              onClick={onFinishButton}
            >
              Detalhamento da solicitação
            </Button>
          )}
        </>
      )}
    </Grid>
  );
}
