import { Toast, ToastContainer } from "react-bootstrap";
import { MdTaskAlt } from "react-icons/md";
import styled from "styled-components";


export const CustomToastContainer = styled(ToastContainer)`
    position: fixed !important;
    top: 70px !important;
    right: 40px !important;
    z-index: 9999 !important;
    
    @media screen and (max-width: 768px){
        right: 16px !important;
    }
`;

type Props = {
    type: 'success' | 'success-alt' | 'error' | 'error-alt';
}

export const ToastContent = styled(Toast) <Props>`
    width: 300px;
    border-radius: 4px;
    background: ${({type}) => {
        switch (type) {
            case 'success':
                return '#FFFFFF';
            case 'success-alt':
                return '#27AB6E';
            case 'error':
                return '#FFFFFF';
            case 'error-alt':
                return '#EC4C47';
            default:
                return '#FFFFFF';
        }
    }};
    border: 1px solid ${({type}) => {
    switch (type) {
        case 'success':
            return '#E1E6EF';
        case 'success-alt':
            return 'rgba(255, 255, 255, 0.56)';
        case 'error':
            return '#E1E6EF';
        case 'error-alt':
            return 'rgba(255, 255, 255, 0.56)';
        default:
            return '#E1E6EF';
    }
    }};
    padding: 16px 16px 0 16px;
`;

export const ToastHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: top;
`;

export const TaskAltIcon = styled(MdTaskAlt).attrs({
    size: 20,
})`
    color: ${({ type }) => {
        switch (type) {
            case 'success':
                return '#27AB6E';
            case 'success-alt':
                return '#FFFFFF';
            case 'error':
                return '#EC4C47';
            case 'error-alt':
                return '#FFFFFF';
            default:
                return '#27AB6E';
        }
    }};
`;

export const ToastTitle = styled.p<Props>`
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    height: 16px;
    width: 180px;
    color: ${({ type }) => {
        switch (type) {
            case 'success':
                return '#506176';
            case 'success-alt':
                return '#FFFFFF';
            case 'error':
                return '#506176';
            case 'error-alt':
                return '#FFFFFF';
            default:
                return '#506176';
        }
    }};
`;

export const ToastCloseButton = styled.div<Props>`
    padding-right: 15px;
    width: 10px;
    height: 10px;
    cursor: pointer;
    font-size: 16px;

    color: ${({ type }) => {
        switch (type) {
            case 'success':
                return '#506176';
            case 'success-alt':
                return '#FFFFFF';
            case 'error':
                return '#506176';
            case 'error-alt':
                return '#FFFFFF';
            default:
                return '#506176';
        }
    }};
`;

export const ToastMessages = styled.p<Props>`
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    color: ${({ type }) => {
        switch (type) {
            case 'success':
                return '#506176';
            case 'success-alt':
                return '#FFFFFF';
            case 'error':
                return '#506176';
            case 'error-alt':
                return '#FFFFFF';
            default:
                return '#506176';
        }
    }};
`;

export const ToastBody = styled.div`
    margin-left: 45px;
`;