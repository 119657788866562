import { Pagination } from "react-bootstrap";

interface PaginateProps {
  setPage: ({ page }: { page?: number }) => void;
  flowData: any;
  page: number;
}

const Paginate = ({
  setPage,
  flowData,
  page
}: PaginateProps) => {

  const handleNextPage = () => setPage({ page: page + 1 });

  const handlePrevPage = () => setPage({ page: page - 1 });

  const handlePaginate = () => {
    const totalPages = flowData.total_pages;
    const currentPage = flowData.current;
    const displayPages = 8;

    const pages = [];

    for (let number = 1; number <= totalPages; number++) {
      if (number === currentPage) {
        pages.push(
          <Pagination.Item
            key={number}
            active
          >
            {number}
          </Pagination.Item>
        );
      } else if (
        number <= displayPages ||
        number === currentPage - 1 ||
        number === currentPage + 1 ||
        number >= totalPages - 1
      ) {
        pages.push(
          <Pagination.Item
            key={number}
            onClick={() => setPage({ page: number })}
          >
            {number}
          </Pagination.Item>
        );
      } else if (
        number === displayPages + 1 ||
        number === totalPages - 2
      ) {
        pages.push(
          <Pagination.Ellipsis
            key={number}
            disabled
          />
        );
      }
    }

    return pages;
  };

  return (
    <Pagination>
      <Pagination.Prev
        disabled={page === 1}
        onClick={handlePrevPage}
      />
      {handlePaginate()}
      <Pagination.Next
        onClick={handleNextPage}
        disabled={page === flowData.total_pages}
      />
    </Pagination>
  );
}

export default Paginate;