import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import {
  Button,
  Col,
  Modal,
  Row,
  Spinner,
  Tab,
  Tabs,
} from "react-bootstrap";
import {
  MdExpandLess,
  MdExpandMore,
  MdVisibility,
} from "react-icons/md";
import { ListAlt } from '@material-ui/icons';
import { useHistory, useParams } from "react-router-dom";
import { RequestDetailsProps } from "../../@types/requests";
import Loading from "../../Components/Loading";
import Status from "../../Components/Status";
import { getCPFData, getRequestForId } from "../../services/requests";
import { cpfMask } from "../../utils/cpfFormat";
import dateFormat from "../../utils/dateFormat";
import {
  BoxBodyCard,
  BoxBodyCardTable,
  BoxHeaderCard,
  BoxTextsTitleCard,
  ButtonReturn,
  Card,
  CardFooterCustom,
  ContainerMain,
  CustomTable,
  InfoIcon,
  SubTextMark,
  TextMark,
  TextsTitleCard,
  TitleHeadCard,
} from "./styles";

type Params = {
  identificador: string;
  protocolo_atendimento?: string;
};

const RequestSimplePath = ({ identificadorParams }: { identificadorParams?: string }): JSX.Element => {
  const downloadLink = useRef(null);
  const [isDocumentLoaded, setIsDocumentLoaded] = useState(true);
  const [loadingAttachments, setLoadingAttachments] = useState(false);
  const history = useHistory();
  const [countAllDocumentUnreaded, setCountAllDocumentUnreaded] =
    React.useState<number>(0);
  const { identificador, protocolo_atendimento } = useParams<Params>();
  const [loading, setLoading] = React.useState(false);
  const [requestData, setRequestData] = React.useState<
    RequestDetailsProps | undefined
  >(undefined);
  const [showDetails, setShowDetails] = React.useState<string>("");
  const [showModalDocReceived, setShowModalDocReceived] =
    React.useState<boolean>(false);
  const [docReceivedSelected, setDocReceivedSelected] = React.useState<{
    id: string;
    nome: string;
    url: string;
  }>();
  const [showModalAnexos, setShowModalAnexos] = React.useState<boolean>(false);
  const [anexosReceived, setAnexosReceivedSelected] = React.useState<{
    id: string;
    arquivo: string;
  }>();
  const [userRequest, setUserRequest] = React.useState<string | null>(null);

  React.useEffect(() => {
    const getDetailsRequest = async () => {
      try {
        setLoading(true);
        const { data } = await getRequestForId(identificador || identificadorParams, protocolo_atendimento);
        if (data?.documentos_solicitacao) {
          let count = 0;
          data.documentos_solicitacao.forEach((doc: any) => {
            if (doc.verificado_em === null) {
              count += 1;
            }
          });
          setCountAllDocumentUnreaded(count);
        }
        setRequestData(data);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    getDetailsRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleIframeLoad = () => {
    setLoadingAttachments(false);
  };

  const handleIframeError = () => {
    setIsDocumentLoaded(false);
    setLoadingAttachments(false);
  };

  const isImage = (url) => {
    return url && /\.(jpg|jpeg|png)$/i.test(url);
  };

  useEffect(() => {
    async function getAllUserData(cpf: string) {
      try {
        setLoading(true);
        const { data } = await getCPFData(cpf);
        setUserRequest(data.nome);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }

    if (requestData?.cpf) {
      getAllUserData(requestData.cpf);
    }
  }, [requestData]);

  return (
    <ContainerMain>
      <Row>
        <Col sm={12} md={12}>
          <Card>
            <BoxHeaderCard>
              <TitleHeadCard>
                <InfoIcon />
                <BoxTextsTitleCard>
                  <TextsTitleCard>Informações da solicitação</TextsTitleCard>
                </BoxTextsTitleCard>
              </TitleHeadCard>
            </BoxHeaderCard>
            {loading ? (
              <Loading />
            ) : (
              <BoxBodyCard>
                <Col sm={12} md={4}>
                  <TextMark>Protocolo</TextMark>
                  <SubTextMark>
                    {requestData?.protocolo || "Sem informação"}
                  </SubTextMark>

                  <TextMark>Fluxo</TextMark>
                  <SubTextMark>
                    {requestData?.flow?.titulo || "Sem informação"}
                  </SubTextMark>
                </Col>
                <Col sm={12} md={4}>
                  {userRequest && (
                    <>
                      <TextMark>Nome</TextMark>
                      <SubTextMark>{userRequest}</SubTextMark>
                    </>
                  )}
                  <TextMark>Registrado por</TextMark>
                  <SubTextMark>{requestData?.registrado_por?.nome}</SubTextMark>
                </Col>
                <Col sm={12} md={4}>
                  <TextMark>CPF</TextMark>
                  <SubTextMark>{cpfMask(requestData?.cpf || "")}</SubTextMark>
                </Col>
              </BoxBodyCard>
            )}
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <BoxHeaderCard>
              <TitleHeadCard>
                <InfoIcon />
                <BoxTextsTitleCard>
                  <TextsTitleCard>Outras Informações</TextsTitleCard>
                </BoxTextsTitleCard>
              </TitleHeadCard>
            </BoxHeaderCard>
            <BoxBodyCardTable>
              <Tabs defaultActiveKey="doc_request" className="mx-4">
                <Tab
                  eventKey="doc_request"
                  title="Documentos"
                >
                  <CustomTable striped responsive>
                    <thead className="table-header">
                      <tr>
                        <th>Ordem</th>
                        <th>Título</th>
                        <th>Conformidade</th>
                        <th>Verificado em</th>
                        <th>Ações</th>
                      </tr>
                    </thead>
                    <tbody className="table-body">
                      {loading ? (
                        <tr className="text-center">
                          <td colSpan={5}>
                            <Loading />
                          </td>
                        </tr>
                      ) : (
                        <>
                          {" "}
                          {requestData?.documentos?.length ? (
                            requestData?.documentos?.map(
                              (item, index) => (
                                <Fragment key={item.id}>
                                  <tr>
                                    <td>#{index + 1}</td>
                                    <td>{item.titulo}</td>
                                    <td>
                                      <Status
                                        type={item?.conformidade.toString()}
                                      />
                                    </td>
                                    <td>
                                      {item.verificado_em
                                        ? dateFormat(item.verificado_em, true)
                                        : "Sem informação"}
                                    </td>
                                    <td className="text-right">
                                      <Button
                                        variant="outline-secondary mx-2"
                                        onClick={() =>
                                          history.push(
                                            `/solicitacoes/documento/${item?.id}/${requestData.identificador}/visualizar-documentos-simples`
                                          )
                                        }
                                      >
                                        <MdVisibility size={22} />
                                      </Button>
                                      <Button
                                        variant="outline-secondary"
                                        onClick={() => {
                                          showDetails && showDetails === item.id
                                            ? setShowDetails("")
                                            : setShowDetails(item.id);
                                        }}
                                      >
                                        {showDetails &&
                                          showDetails === item.id ? (
                                          <MdExpandMore />
                                        ) : (
                                          <MdExpandLess />
                                        )}
                                      </Button>
                                    </td>
                                  </tr>
                                  {showDetails && showDetails === item.id && (
                                    <>
                                      <tr className="hiddenDetails">
                                        <th colSpan={3}>VERIFICADO POR</th>
                                        <td colSpan={2} className="text-right">
                                          {!item.verificado_por &&
                                            "Não informado"
                                          }
                                        </td>
                                      </tr>
                                      {item.verificado_por &&
                                        <td colSpan={2} style={{ padding: '15px 30px' }}>
                                          <>
                                            <div>Nome:  {item.verificado_por?.nome} </div>
                                            <div>CPF:  {item.verificado_por?.cpf} </div>
                                            <div>
                                              Data da Verificação:
                                              {new Date(item?.verificado_em).toLocaleDateString("pt-BR")}
                                            </div>
                                          </>
                                        </td>
                                      }
                                    </>
                                  )}
                                </Fragment>
                              )
                            )
                          ) : (
                            <tr className="text-center">
                              <td colSpan={5}>Nenhum histórico encontrado</td>
                            </tr>
                          )}
                        </>
                      )}
                    </tbody>
                  </CustomTable>
                </Tab>
                <Tab
                  eventKey="doc_forms"
                  title="Formulários">
                  <CustomTable striped responsive>
                    <thead className="table-header">
                      <tr>
                        <th>Ordem</th>
                        <th>Título</th>
                        <th>Respondido</th>
                        <th>Criado em</th>
                        <th>Ações</th>
                      </tr>
                    </thead>
                    <tbody className="table-body">
                      {loading ? (
                        <tr className="text-center">
                          <td colSpan={5}>
                            <Loading />
                          </td>
                        </tr>
                      ) : (
                        <>
                          {" "}
                          {requestData?.formularios?.length ? (
                            requestData?.formularios?.map(
                              (item, index) => (
                                <Fragment key={item.id}>
                                  <tr>
                                    <td>#{index + 1}</td>
                                    <td>{item.titulo}</td>
                                    <td>
                                      <Status
                                        type={item?.respondido.toString()}
                                      />
                                    </td>
                                    <td>{dateFormat(item.created_at, true)}</td>
                                    <td>
                                      {!item.respondido && (
                                        <>
                                          <Button
                                            variant="outline-secondary mx-2"
                                            onClick={() => {
                                              if (protocolo_atendimento) {
                                                return history.push(`/formularios-atendente/${item.id}/${requestData.id}/${identificador || identificadorParams}/${protocolo_atendimento}`)
                                              }
                                              return history.push(`/formularios-atendente/${item.id}/${requestData.id}/${identificador || identificadorParams}`);
                                            }
                                            }
                                          >
                                            <ListAlt />
                                          </Button>
                                        </>
                                      )}
                                    </td>
                                  </tr>
                                </Fragment>
                              )
                            )
                          ) : (
                            <tr className="text-center">
                              <td colSpan={5}>Nenhum histórico encontrado</td>
                            </tr>
                          )}{" "}
                        </>
                      )}
                    </tbody>
                  </CustomTable>
                </Tab>
                <Tab eventKey="docs_access" title="Registro de Acessos">
                  <CustomTable striped responsive>
                    <thead className="table-header">
                      <tr>
                        <th>Ordem</th>
                        <th>Ação</th>
                        <th>Usuário</th>
                        <th>DATA/HORA</th>
                      </tr>
                    </thead>
                    <tbody className="table-body">
                      {loading ? (
                        <tr className="text-center">
                          <td colSpan={5}>
                            <Loading />
                          </td>
                        </tr>
                      ) : (
                        <>
                          {" "}
                          {requestData?.acessos?.length ? (
                            requestData?.acessos?.map(
                              // eslint-disable-next-line array-callback-return
                              (item, index) => {
                                if (item.id) {
                                  return (
                                    <Fragment key={item.id}>
                                      <tr>
                                        <td>#{index + 1}</td>
                                        <td>Visualização</td>
                                        <td>{item?.user_data?.nome}</td>
                                        <td>
                                          {dateFormat(item.created_at, true)}
                                        </td>
                                      </tr>
                                    </Fragment>
                                  );
                                }
                              }
                            )
                          ) : (
                            <tr className="text-center">
                              <td colSpan={5}>Nenhum histórico encontrado</td>
                            </tr>
                          )}{" "}
                        </>
                      )}
                    </tbody>
                  </CustomTable>
                </Tab>
              </Tabs>
            </BoxBodyCardTable>
            <CardFooterCustom>
              {protocolo_atendimento === undefined && (
                <ButtonReturn onClick={() => history.push("/solicitacoes")}>
                  Voltar
                </ButtonReturn>
              )}
            </CardFooterCustom>
          </Card>
        </Col>
      </Row>
      <Modal
        show={showModalDocReceived}
        onHide={() => setShowModalDocReceived(false)}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>{docReceivedSelected?.nome}</Modal.Title>

        </Modal.Header>
        <Modal.Body style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '750px' }}>
          {isImage(docReceivedSelected?.url) ? (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
              <img
                src={docReceivedSelected?.url}
                alt={docReceivedSelected?.nome}
                style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }}
              />
            </div>
          ) : (
            <>
              {loadingAttachments && <Spinner />}
              <iframe
                title={docReceivedSelected?.nome}
                src={`https://docs.google.com/gview?url=${encodeURIComponent(docReceivedSelected?.url)}&embedded=true`}
                style={{
                  width: "100%",
                  height: "750px",
                  border: "none",
                  display: loadingAttachments ? 'none' : 'block'
                }}
                onLoad={handleIframeLoad}
                onError={handleIframeError}
              />
              <div style={{ textAlign: 'center' }}>
                <p>Se o documento não foi carregado automaticamente, clique no botão abaixo para fazer o download.</p>
              </div>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <a
            ref={downloadLink}
            href={docReceivedSelected?.url || "#"}
            download
            className="btn btn-primary"
            style={{ marginLeft: '10px' }}
          >
            Download
          </a>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showModalAnexos}
        onHide={() => setShowModalAnexos(false)} size='lg'>
        <Modal.Header closeButton>
          <Modal.Title>Arquivo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <object
            aria-label={anexosReceived?.id}
            data={anexosReceived?.arquivo || ''}
            style={{
              width: '100%',
              minHeight: 900,
            }} />
        </Modal.Body>
      </Modal>
    </ContainerMain >
  );
};

export default RequestSimplePath;
