import React, { useState, useEffect, Fragment, useCallback } from 'react';
import {
  Button,
  Grid,
  Typography,
} from '@material-ui/core';
import {
  Text,
  Area,
  TextEmail,
  TextNumber,
  TextDate,
  Phone,
  Select,
  Multiple,
  MultCheckboxe,
} from '../Inputs';
import { makeStyles, Theme } from '@material-ui/core/styles';
import {
  Formulario,
  Campo,
} from '../../../interfaces/form-flow';
import Alert from '@material-ui/lab/Alert';
import Arquivo from '../Inputs/Arquivo';
import SelectAPI from '../Inputs/SelectAPI';
import MultipleAPI from '../Inputs/MultipleAPI';
import { answerForm, getStatusFormFlow } from '../../../services/flow';
import getCookie from '../../../utils/getCookies';
import { searchCep } from '../../../services/ouvidoria';
import { useStateValue } from '../../../providers/StateProvider';
import { actionTypes } from '../../../store/reducer';
import { sanitizeHtml } from '../../../utils/sanitizeHtml';

interface LoadDataProps {
  nome: string | null;
  cpf: string | null;
  email: string | null;
  telefone: string | null;
}
interface Props {
  page: number;
  formData: Formulario[];
  handleStep: (pageOrStep: number) => void;
  handleSubmit: (values: any, errors?: any) => void;
  formsAnswerData?: any[];
  loadData: LoadDataProps;
}

interface AddressFields<T> {
  bairro?: T;
  município?: T;
  uf?: T;
  logradouro?: T;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: '#264259',
      },
    },
  },
  title: {
    fontFamily: 'Roboto',
    fontWeight: 500,
    color: '#264259',
    textTransform: 'uppercase',
    textAlign: 'center',
    fontSize: 16,
    padding: 20,
  },
  subtitle: {
    opacity: 0.6,
    textAlign: 'center',
    fontWeight: 'normal',
    fontSize: 16,
  },
  formControl: {
    minWidth: 120,
  },
  adornedEnd: {
    paddingRight: 0,
  },
  buttonForm: {
    backgroundColor: '#264259',
  },
}));

export default function Flow({
  page,
  handleStep,
  formData,
  handleSubmit,
  formsAnswerData,
  loadData
}: Props): JSX.Element {
  const [, dispatch] = useStateValue();
  const classes = useStyles();
  const tokenSSO: string | null = getCookie('gov_access_token');
  const [currentPageData, setCurrentPageData] = useState<Formulario>(formData[page]);
  const [currentFormsAnswer, setCurrentFormsAnswer] = useState(formsAnswerData[page]);
  const [values, setValues] = useState<any>({});
  const [errors, setErrors] = useState<any>({});
  const [statusFormFlow, setStatusFormFlow] = useState<any>({});
  const [addressFieldsId, setAddressFieldsId] = useState<AddressFields<number>>({ bairro: 0, município: 0, uf: 0, logradouro: 0 });
  const [cepLoading, setCepLoading] = useState(false);

  useEffect(() => {
    const upcomingPageData = formData[page];
    const upcomingAnswerData = formsAnswerData[page];

    if (upcomingAnswerData) {
      setCurrentFormsAnswer(upcomingAnswerData);
    }
    if (upcomingPageData) {
      setCurrentPageData(upcomingPageData);

      if (upcomingPageData.campos.length) {
        // VALUES
        setValues(currentValues => {
          const newValues = upcomingPageData.campos.reduce((obj, field) => {
            if (upcomingAnswerData) {

              obj[field.id] = upcomingAnswerData?.resposta[field.id].resposta || '';
            } else {
              obj[field.id] = '';
            }
            return obj;
          }, {});


          return Object.assign({}, currentValues, newValues);
        });

        // SET ERRORS
        setErrors(currentValues => {
          const newValues = upcomingPageData.campos.reduce((obj, field) => {
            obj[field.id] = '';
            return obj;
          }, {});
          return Object.assign({}, newValues, currentValues);
        });
      }
    }
  }, [page, formData, formsAnswerData]);

  // Valida campos, para tratamento externo
  const validate = useCallback(() => {
    let invalids = [];
    let currentValues = {};

    if (currentPageData?.campos?.length) {
      currentPageData.campos.forEach((field) => {
        let fieldId = field.id;
        let value = values[fieldId];
        // Marca os erros
        if (field.obrigatorio && !value) {
          currentValues[fieldId] = 'Campo obrigatório';
        }
        if (field.validador) {
          let reg = new RegExp(field.validador, 'g').test(value);
          if (!reg) {
            currentValues[fieldId] = 'Campo no formato inválido';
          }
        }

        if (currentValues[fieldId]) {
          invalids.push(currentValues[fieldId]);
        }

        setErrors(currentValues);
      });
    }

    return !invalids.length;
  }, [currentPageData?.campos, values]);

  const handleSave = async (slug) => {
    let formDataUser = JSON.parse(getCookie('gov_user_form_started') || '{}');
    let user = JSON.parse(getCookie('gov_user_data') || '{}');
    if (formDataUser) {

      let forResposta = {};

      currentPageData.campos.forEach((field) => {
        let fieldId = field.id;
        let value = values[fieldId];
        if (field.tipo === 'Data' && value && (value instanceof Date)) {
          value = value.toLocaleDateString('pt-BR');
        }

        forResposta[fieldId] = {
          id: fieldId,
          resposta: value,
          titulo: field.titulo,
        };
      })

      let formatData = {
        flow_resposta: formDataUser.id,
        formulario: slug,
        cpf: user.cpf,
        resposta: forResposta,
      }

      try {
        const response = await answerForm(tokenSSO, formatData);

        if (response) {
          handleStep(page + 1);
        }
      } catch (error) {
        if (error.response.status === 406) {
          handleStep(page + 1);
        }
        console.log('error ao salvar resposta', error);
      }
    }

  };

  const onSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault();
    if (validate()) {
      const totalPage = formData.length - 1;
      //const nextPage = page + 1;
      if (page <= totalPage) {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        await handleSave(currentPageData.slug);
        // handleStep(nextPage);
      }


      if (page === totalPage) {
        await checkStatusFormFlow()
        handleSubmit(values, errors);
      }
    }
  }


  const checkStatusFormFlow = async () => {
    let formDataUser = JSON.parse(getCookie('gov_user_form_started') || '{}');
    let user = JSON.parse(getCookie('gov_user_data') || '{}');
    if (formDataUser?.identificador && formDataUser?.cpf) {
      try {
        const { data } = await getStatusFormFlow(tokenSSO, {
          identificador: formDataUser.identificador,
          cpf: formDataUser?.cpf,
        });
        setStatusFormFlow(data);
      } catch (error) {
        console.log('error ao verificar status form flow', error);
      }

    }
  }

  // set os Inputs
  const fieldChanged = (fieldId: number, value: any) => {
    setValues(currentValues => {
      currentValues[fieldId] = value;
      return currentValues;
    });

    setCurrentPageData(currentPageData => {
      return Object.assign({}, currentPageData);
    });

    if (errors) {
      setErrors((currentError) => currentError[fieldId] = '');
    }
  }

  // determina a proxima pagina (Navegação entre forms)
  const handlePrev = () => {
    const pagePrev = page - 1;
    if (pagePrev >= 0) {
      handleStep(pagePrev);
    }
  }

  // Testa a proxima pagina
  const hasPageNext = () => {
    return (page >= 0 && page < formData.length - 1) || (formData.length - 1 === page);
  }

  const handleSearchAddress = async (campo: Campo, cep: string) => {
    const unmaskedCep = cep.replace(/[^0-9]/g, "")
    fieldChanged(campo.id, unmaskedCep)
    if (!cep || unmaskedCep.length < 8) {
      setCepLoading(false)
      return
    };

    try {
      dispatch({
        type: actionTypes.SET_FLASH_MESSAGE,
        flashMessage: {
          show: true,
          title: 'success-alt',
          message: 'Buscando CEP',
        },
      });
      setCepLoading(true)
      setValues((currentValues) => {
        currentValues[addressFieldsId.bairro] = "";
        currentValues[addressFieldsId.uf] = "";
        currentValues[addressFieldsId.município] = "";
        currentValues[addressFieldsId.logradouro] = "";
        return currentValues;
      });

      const { data } = await searchCep(unmaskedCep, '', 'BA');

      setValues((currentValues) => {
        currentValues[addressFieldsId.bairro] = data?.bairro || "";
        currentValues[addressFieldsId.uf] = data?.uf || "";
        currentValues[addressFieldsId.município] = data?.cidade || "";
        currentValues[addressFieldsId.logradouro] = data?.logradouro || "";
        return currentValues;
      });

      setCepLoading(false)
    } catch (error) {
      if (error?.response?.data) {
        dispatch({
          type: actionTypes.SET_FLASH_MESSAGE,
          flashMessage: {
            show: true,
            type: 'error-alt',
            title: 'Erro ao buscar cep',
            message: 'CEP não encontrado.',
          },
        });
        setCepLoading(false)
      }
      console.error(error);
      setCepLoading(false)
    } finally {
      setCepLoading(false)
    }
  };


  function getAddressFieldId(campo: Campo) {
    if (!Object.keys(addressFieldsId)?.includes(campo.titulo.toLowerCase())) return;

    setAddressFieldsId((currentIdList) => {
      currentIdList[campo.titulo.toLowerCase()] = campo.id
      return currentIdList;
    });
  }

  const getValueFromUser = useCallback((titulo: string, fieldId: number) => {
    if (!loadData) return '';
    if (titulo?.toLowerCase() === 'cpf' && loadData?.cpf) {
      setValues((currentValue) => {
        currentValue[fieldId] = loadData.cpf;
        return currentValue;
      });
    } else if (titulo?.toLowerCase() === 'nome' && loadData?.nome) {
      setValues((currentValue) => {
        currentValue[fieldId] = loadData.nome;
        return currentValue;
      });
    } else {
      return '';
    }
  }, [loadData]);

  const getEmailFromUser = useCallback((fieldId: number) => {
    if (!loadData) return '';
    if (loadData?.email) {
      setValues(currentValues => {
        currentValues[fieldId] = loadData.email;
        return currentValues;
      });
    }
  }, [loadData]);

  const getTelefoneFromUser = useCallback((fieldId: number) => {
    if (!loadData) return '';
    if (loadData?.telefone) {
      setValues(currentValues => {
        currentValues[fieldId] = loadData.telefone;
        return currentValues;
      });
    }
  }, [loadData]);



  return (
    <>
      {currentPageData && (
        <>
          <Grid
            container
            alignItems="center"
            justifyContent="center"
          >
            <Grid item xs={12}>
              <Typography variant="h6" className={classes.title}>
                {currentPageData.titulo}
              </Typography>
            </Grid >
            <Grid style={{ textAlign: 'center', marginBottom: 20 }} item xs={12}>
              <Typography className={classes.subtitle}>
                <div
                  dangerouslySetInnerHTML={{ __html: sanitizeHtml(currentPageData.descricao) }}
                />
              </Typography>

            </Grid>
          </Grid>

          <form onSubmit={onSubmit} className={classes.root}>
            {!currentPageData.campos.length ? (
              <Alert severity="warning" style={{ marginBottom: 10 }}>Nenhum campo disponível</Alert>
            ) : (
              <Grid container spacing={2}>
                {currentPageData.campos.map((campo: Campo) => (
                  <Fragment key={campo.id}>
                    {addressFieldsId[campo.titulo.toLowerCase()] === 0 && getAddressFieldId(campo)}
                    {campo.tipo === 'Texto' &&
                      <Grid sm={12} md={6} item>
                        <Text
                          required={campo.obrigatorio}
                          id={`${campo.tipo}-${campo.id}`}
                          value={values[campo.id] ? values[campo.id] : getValueFromUser(campo.titulo, campo.id)}
                          label={campo.titulo}
                          disabled={(currentFormsAnswer?.formulario === currentPageData.slug) || !campo.editavel}
                          onChange={(e) => fieldChanged(campo.id, e.target.value)}
                          error={errors[campo.id] ? true : false}
                          helperText={errors[campo.id] ? errors[campo.id] : campo.texto_ajuda}
                          mask={campo.mascara !== 'mascara' ? campo.mascara : ''}
                        />
                      </Grid>
                    }
                    {campo.tipo === 'Area' &&
                      <Grid sm={12} md={12} item >
                        <Area
                          required={campo.obrigatorio}
                          id={`${campo.tipo}-${campo.id}`}
                          label={campo.titulo}
                          value={values[campo.id] ? values[campo.id] : ''}
                          error={errors[campo.id] ? true : false}
                          onChange={(e) => fieldChanged(campo.id, e.target.value)}
                          helperText={errors[campo.id] ? errors[campo.id] : campo.texto_ajuda}
                          disabled={(currentFormsAnswer?.formulario === currentPageData.slug) || !campo.editavel}
                        />
                      </Grid>
                    }
                    {campo.tipo === 'Email' &&
                      <Grid sm={12} md={4} item >
                        <TextEmail
                          required={campo.obrigatorio}
                          id={`${campo.tipo}-${campo.id}`}
                          label={campo.titulo}
                          value={values[campo.id] ? values[campo.id] : getEmailFromUser(campo.id)}
                          error={errors[campo.id] ? true : false}
                          onChange={(e) => fieldChanged(campo.id, e.target.value)}
                          helperText={errors[campo.id] ? errors[campo.id] : campo.texto_ajuda}
                          disabled={(currentFormsAnswer?.formulario === currentPageData.slug) || !campo.editavel}
                        />
                      </Grid>
                    }
                    {campo.tipo === 'Numero' &&
                      <Grid sm={12} md={6} item >
                        <TextNumber
                          id={`${campo.tipo}-${campo.id}`}
                          label={campo.titulo}
                          value={values[campo.id] ? values[campo.id] : ''}
                          required={campo.obrigatorio}
                          onChange={(e) => fieldChanged(campo.id, e.target.value)}
                          helperText={errors[campo.id] ? errors[campo.id] : campo.texto_ajuda}
                          disabled={(currentFormsAnswer?.formulario === currentPageData.slug) || !campo.editavel}
                        />
                      </Grid>
                    }
                    {campo.tipo === 'Imagem' &&
                      <Grid sm={12} md={6} item>
                        <Arquivo
                          type='image'
                          required={campo.obrigatorio}
                          id={`${campo.tipo}-${campo.id}`}
                          label={campo.titulo}
                          value={values[campo.id] ? values[campo.id] : ''}
                          error={errors[campo.id] ? true : false}
                          onChange={(value) => {
                            if (typeof value !== 'string') {
                              fieldChanged(campo.id, value.url);
                            }
                          }}
                          helperText={errors[campo.id] ? errors[campo.id] : campo.texto_ajuda}
                          disabled={(currentFormsAnswer?.formulario === currentPageData.slug) || !campo.editavel}
                        />
                      </Grid>
                    }
                    {campo.tipo === 'Telefone' &&
                      <Grid sm={12} md={3} item >
                        <Phone
                          required={campo.obrigatorio ? true : false}
                          id={`${campo.tipo}-${campo.id}`}
                          label={campo.titulo}
                          value={values[campo.id] ? values[campo.id] : getTelefoneFromUser(campo.id)}
                          error={errors[campo.id] ? true : false}
                          onChange={(e) => fieldChanged(campo.id, e.target.value)}
                          mask={campo.mascara}
                          helperText={errors[campo.id] ? errors[campo.id] : campo.texto_ajuda}
                          disabled={(currentFormsAnswer?.formulario === currentPageData.slug) || !campo.editavel}
                        />
                      </Grid>
                    }
                    {campo.tipo === 'Data' &&
                      <Grid sm={12} md={3} item >
                        <TextDate
                          required={campo.obrigatorio}
                          label={campo.titulo}
                          value={values[campo.id] ? values[campo.id] : null}
                          error={errors[campo.id] ? true : false}
                          onChange={(data) => fieldChanged(campo.id, data)}
                          InputLabelProps={{ shrink: true }}
                          helperText={errors[campo.id] ? errors[campo.id] : campo.texto_ajuda}
                          disabled={(currentFormsAnswer?.formulario === currentPageData.slug) || !campo.editavel}
                        />
                      </Grid>
                    }
                    {(campo.tipo === 'Alternativas' && campo.tipo_alternativa === 'RadioButton') &&
                      <Grid sm={12} md={12} item >
                        <MultCheckboxe
                          required={campo.obrigatorio}
                          id={`${campo.tipo}-${campo.id}`}
                          label={campo.titulo}
                          value={values[campo.id] ? values[campo.id] : ''}
                          error={errors[campo.id] ? true : false}
                          onChange={(e) => fieldChanged(campo.id, e.target.value)}
                          helperText={errors[campo.id] ? errors[campo.id] : campo.texto_ajuda}
                          itens={campo.alternativas}
                          disabled={currentFormsAnswer?.formulario === currentPageData.slug}
                        />
                      </Grid>
                    }
                    {(campo.tipo === 'Alternativas' && campo.tipo_alternativa === 'Select') &&
                      <Grid sm={12} md={6} item >
                        <Select
                          required={campo.obrigatorio}
                          id={`${campo.tipo}-${campo.id}`}
                          label={campo.titulo}
                          value={values[campo.id] ? values[campo.id] : ''}
                          error={errors[campo.id] ? true : false}
                          onChange={(e) => fieldChanged(campo.id, e.target.value)}
                          helperText={errors[campo.id] ? errors[campo.id] : campo.texto_ajuda}
                          itens={campo.alternativas}
                          getOptionLabel={(option) => option.titulo}
                          disabled={currentFormsAnswer?.formulario === currentPageData.slug}
                        />
                      </Grid>
                    }
                    {(campo.tipo === 'Alternativas_API') &&
                      <Grid sm={12} md={6} item >
                        <SelectAPI
                          required={campo.obrigatorio}
                          id={`${campo.tipo}-${campo.id}`}
                          label={campo.titulo}
                          value={values[campo.id] ? values[campo.id] : ''}
                          error={errors[campo.id] ? true : false}
                          onChange={(value) => fieldChanged(campo.id, value)}
                          helperText={errors[campo.id] ? errors[campo.id] : campo.texto_ajuda}
                          itens={campo.alternativas}
                          getOptionLabel={(option) => option.label}
                          disabled={currentFormsAnswer?.formulario === currentPageData.slug}
                        />
                      </Grid>
                    }
                    {campo.tipo === 'Multiplos' &&
                      <Grid sm={12} md={12} item >
                        <Multiple
                          required={campo.obrigatorio}
                          label={campo.titulo}
                          values={values[campo.id] ? values[campo.id] : []}
                          error={errors[campo.id] ? true : false}
                          onChange={(value) => fieldChanged(campo.id, value)}
                          InputLabelProps={{ shrink: true }}
                          helperText={errors[campo.id] ? errors[campo.id] : campo.texto_ajuda}
                          itens={campo.alternativas}
                          getOptionLabel={(option) => option.label}
                          disabled={currentFormsAnswer?.formulario === currentPageData.slug}
                        />
                      </Grid>
                    }
                    {campo.tipo === 'Multiplos_API' &&
                      <Grid sm={12} md={6} item >
                        <MultipleAPI
                          required={campo.obrigatorio}
                          label={campo.titulo}
                          values={values[campo.id] ? values[campo.id] : []}
                          error={errors[campo.id] ? true : false}
                          onChange={(value) => fieldChanged(campo.id, value)}
                          InputLabelProps={{ shrink: true }}
                          helperText={errors[campo.id] ? errors[campo.id] : campo.texto_ajuda}
                          itens={campo.alternativas}
                          getOptionLabel={(option) => option.label}
                          disabled={currentFormsAnswer?.formulario === currentPageData.slug}
                        />
                      </Grid>
                    }

                    {campo.tipo === 'Arquivo' &&
                      <Grid sm={12} md={6} item >
                        <Arquivo
                          required={campo.obrigatorio}
                          id={`${campo.tipo}-${campo.id}`}
                          label={campo.titulo}
                          value={values[campo.id] ? values[campo.id] : ''}
                          error={errors[campo.id] ? true : false}
                          onChange={(value) => fieldChanged(campo.id, value)}
                          helperText={errors[campo.id] ? errors[campo.id] : campo.texto_ajuda}
                          disabled={(currentFormsAnswer?.formulario === currentPageData.slug) || !campo.editavel}
                        />
                      </Grid>
                    }
                    {campo.tipo === "CEP" && (
                      <Grid sm={12} md={6} item>
                        <Text
                          required={campo.obrigatorio}
                          id={`${campo.tipo}-${campo.id}`}
                          value={values[campo.id] || ""}
                          label={campo.titulo}
                          disabled={cepLoading || (currentFormsAnswer?.formulario ===
                            currentPageData.slug) || !campo.editavel}
                          onChange={(e) =>
                            handleSearchAddress(campo, e.target.value)
                          }
                          error={errors[campo.id] ? true : false}
                          helperText={
                            errors[campo.id]
                              ? errors[campo.id]
                              : campo.texto_ajuda
                          }
                          mask={
                            campo.mascara !== "mascara" ? campo.mascara : ""
                          }
                        />
                      </Grid>
                    )}
                  </Fragment>
                ))}

              </Grid>
            )}

            {/* Navegação padrão */}
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item xs={3}>
                {page > 0 &&
                  <Button style={{ color: '#264259' }} onClick={() => handlePrev()}>
                    VOLTAR
                  </Button>
                }
              </Grid>
              <Grid item xs={3}>
                {hasPageNext() &&
                  <Button
                    className={classes.buttonForm}
                    variant="contained"
                    fullWidth
                    color="primary"
                    type="submit"
                    disabled={(statusFormFlow?.pendentes?.length) && (page === formData.length - 1)}
                  >
                    {formData.length - 1 === page ? 'SALVAR' : 'SALVAR / AVANÇAR'}
                  </Button>
                }
              </Grid>
            </Grid>
          </form>
        </>
      )}
    </>
  );
}
