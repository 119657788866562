import React, { useEffect } from "react";
import ReactApexChart from "react-apexcharts";

import { nameSplitTwo } from "../../utils/nameFormat";
import {
  AccountTreeIcon,
  BoxChart,
  BoxHeaderCard,
  BoxServices,
  BoxTextsTitleCard,
  Card,
  Container,
  Content,
  ContentLeft,
  ContentRight,
  DashboardIcon,
  Greetings,
  GreetingsTwo,
  Header,
  PostAddIcon,
  TableSevices,
  TdNumber,
  TdText,
  TextsSubTitleCard,
  TextsTitleCard,
  TitleCountHeadCard,
  TitleHeadCard,
  Tr
} from "./style";
import { useStateValue } from "../../providers/StateProvider";
import { HomeResponse } from "../../@types/home";
import { getDataHome } from "../../services/flow";
import Loading from "../../Components/Loading";
import dateFormat from "../../utils/dateFormat";
import getCookie from "../../utils/getCookies";
import { useProfile } from "../../providers/ProfileProvider";


export default function Home(): JSX.Element {
  const { verifyProfileAllowed } = useProfile();
  const [{ user }] = useStateValue();

  const [homeData, setHomeData] = React.useState<undefined | HomeResponse>(undefined);

  const [loading, setLoading] = React.useState(false);

  const [servicesMore, setServicesMore] = React.useState([]);

  const [categories, setCategories] = React.useState([]);

  const [series, setSeries] = React.useState([]);

  React.useEffect(() => {
    async function getDataHomeResponse() {
      try {
        setLoading(true);
        const token: string | null = getCookie(
          "gov_access_token"
        );
        const { data } = await getDataHome(token || '');
        if (data) {
          setHomeData(data);
        }
      } catch (error) {
        console.log(error);

      } finally {
        setLoading(false);
      }
    }
    getDataHomeResponse();

  }, [])

  useEffect(() => {
    if (homeData?.total_por_servico) {
      const servicesMore: any = [];
      for (const key in homeData.total_por_servico) {
        servicesMore.push({
          name: key,
          total: homeData.total_por_servico[key]
        });
      }
      setServicesMore(servicesMore);
    }
    if (homeData?.total_solicitacoes_periodo) {
      setCategories(Object.keys(homeData.total_solicitacoes_periodo));
      setSeries(Object.values(homeData.total_solicitacoes_periodo));
    }
  }, [homeData]);





  return (
    <>
      <Container>
        <Header>
          <Greetings>Olá, {user && `${nameSplitTwo(user?.nome?.toLowerCase())}`}</Greetings>
          <GreetingsTwo>Bem vindo(a) ao seu {process.env.REACT_APP_NAME_APP || "Formflow"} 📑</GreetingsTwo>
        </Header>
        <Content>
          <ContentLeft sm={12} md={6}>
            {!verifyProfileAllowed && (
              <Card>
                <BoxHeaderCard>
                  <TitleHeadCard>
                    <AccountTreeIcon />
                    <BoxTextsTitleCard>
                      <TextsTitleCard>Fluxos</TextsTitleCard>
                      <TextsSubTitleCard>Adicionados</TextsSubTitleCard>
                    </BoxTextsTitleCard>
                  </TitleHeadCard>
                  <TitleCountHeadCard>
                    {loading ? <Loading /> : homeData?.total_fluxos}
                  </TitleCountHeadCard>
                </BoxHeaderCard>
              </Card>
            )}

            <Card>
              <BoxHeaderCard>
                <TitleHeadCard>
                  <PostAddIcon />
                  <BoxTextsTitleCard>
                    <TextsTitleCard>Solicitações</TextsTitleCard>
                    <TextsSubTitleCard>Aguardando Resposta</TextsSubTitleCard>
                  </BoxTextsTitleCard>
                </TitleHeadCard>
                <TitleCountHeadCard> {loading ? <Loading /> : homeData?.total_solicitacoes}</TitleCountHeadCard>
              </BoxHeaderCard>
              <BoxChart>
                {loading ? <Loading /> : <ReactApexChart options={{
                  chart: {
                    height: 350,
                    type: 'area',
                  },
                  dataLabels: {
                    enabled: false
                  },

                  stroke: {
                    curve: 'straight'
                  },
                  xaxis: {
                    type: 'category',
                    categories: categories.map(item => dateFormat(item)),
                  },
                }}
                  series={[{
                    name: 'Solicitações',
                    data: series
                  }]} type="area" height={400} />}

              </BoxChart>
            </Card>
          </ContentLeft>
          <ContentRight sm={12} md={6}>
            <Card>
              <BoxHeaderCard>
                <TitleHeadCard>
                  <DashboardIcon />
                  <BoxTextsTitleCard>
                    <TextsTitleCard>Serviços</TextsTitleCard>
                    <TextsSubTitleCard>Mais solicitados</TextsSubTitleCard>
                  </BoxTextsTitleCard>
                </TitleHeadCard>

              </BoxHeaderCard>
              <BoxServices>

                <TableSevices >
                  <tbody className="table-body">
                    {loading ? <Loading /> : servicesMore.map(({ name, total }) => (
                      <Tr key={name}>
                        <TdText>{name}</TdText>
                        <TdNumber>{total}</TdNumber>
                      </Tr>
                    ))}
                  </tbody>

                </TableSevices>

              </BoxServices>
            </Card>
          </ContentRight>
        </Content>

      </Container>
    </>
  );
}
