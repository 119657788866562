import React, { useEffect, useState } from "react";
import { workflowRequest } from "../../../../services/flow";
import getCookie from "../../../../utils/getCookies";
import Loading from "../../../../Components/Loading";
import { CustomTable, NoFoundData, CardFooterCustom } from "../../styles";
import dateFormat from "../../../../utils/dateFormat";
import Paginate from "../../../../Components/Paginate";


const RequestsWorkflowList = (): JSX.Element => {
  const tokenSSO: string | null = getCookie('gov_access_token');
  const [loading, setLoading] = useState(false);
  const [requestData, setRequestData] = useState<any>();

  const getRequest = async ({ page }: { page: number | 1 }) => {
    setLoading(true);
    setRequestData(undefined);
    try {
      const { data } = await workflowRequest({ tokenSSO, page });

      setRequestData(data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getRequest({ page: 1 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <>
      {loading && <Loading />}
      {requestData && requestData.results.length > 0 ? (
        <CustomTable responsive>
          <thead className="table-header">
            <tr>
              <th>ID</th>
              <th>Workflow</th>
              <th>Solicitação</th>
              <th>Status</th>
              <th>Resultado</th>
              <th>Criado em</th>
              <th>Atualizado em</th>
            </tr>
          </thead>
          <tbody className="table-body">
            {requestData.results.map((request, index) => {
              return (
                <tr key={request.id}>
                  <td>{request?.id || ''}</td>
                  <td>
                    {request?.workflow_data?.titulo || ''}
                    <br />
                    URL: {request?.workflow_data?.url || ''}
                  </td>

                  <td>
                    ID: {request?.solicitacao_data?.identificador || ''}
                    <br />
                    PROTOCOLO: {request?.solicitacao_data?.protocolo || ''}
                  </td>
                  <td>
                    {request?.tipo || ''}
                  </td>
                  <td>
                    {request?.resultado || ''}
                  </td>
                  <td>{dateFormat(request.created_at, true)}</td>
                  <td>{dateFormat(request.updated_at, true)}</td>
                </tr>
              )
            })}
          </tbody>
        </CustomTable>
      ) : (
        <>{!loading && <NoFoundData>Sem resultados!</NoFoundData>}</>
      )}
      {requestData?.results?.length > 0 && (
        <CardFooterCustom>
          <Paginate
            flowData={requestData}
            setPage={getRequest}
            page={requestData?.current}
          />
        </CardFooterCustom>
      )}
    </>
  )
}

export default RequestsWorkflowList;