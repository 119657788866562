import { Card, Container, Form, Button } from 'react-bootstrap';
import styled from 'styled-components';

export const ContainerMain = styled(Container)`
  margin-top: 56px;
  margin-bottom: 20px;
`;

export const CardHeader = styled(Card.Header)`
  display: flex;
  background-color: ${({ theme }) => theme.colors.shape};
  align-items: center;
  padding: 20px;
`;

export const Title = styled.p`
  font-size: 24px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.secundary};
  margin: 0;
`;

export const FormGroupCustom = styled(Form.Group)`
  padding: 0px 40px;
  margin: 20px 0px;
`;

export const FormLabelCustom = styled(Form.Label)`
  &:after {
    color: ${({ theme }) => theme.colors.error};
    content: ' *';
    display: ${({ required }) => (required ? 'inline' : 'none')};
  }
`;

export const CardFooterCustom = styled(Card.Footer)`
  background-color: ${({ theme }) => theme.colors.shape};
  display: flex;
  justify-content: center;
  padding: 0px;
`;

export const ActionButton = styled(Button)`
  border: none;
  border-radius: 0px;
  display: flex;
  justify-content: center;
  width: 50%;
  padding: 15px 0;
`;

export const ContainerOption = styled.div`
  display: flex;
  gap: 20px;
`;

export const ContainerList = styled.div`
  display: flex;
`;

export const MainOption = styled.ul`
  margin: 10px 0px;
`;

export const OptionList = styled.li`
  margin: 5px 0px;
  font-size: 14px;
  strong {
    margin-right: 8px;
  }
`;
