import { AxiosResponse } from 'axios';
import { RequestDetailsProps, RequestsProps } from '../@types/requests';
import { apinewforms, apisso } from './api';
import getCookie from '../utils/getCookies';

export const getRequestForStatus = (
  status:
    | 'Aguardando'
    | 'Solicitado'
    | 'Em Andamento'
    | 'Concluído'
    | 'Cancelado'
    | 'Restituído'
    | 'Encaminhado'
    | 'Cancelado por falta de resposta'
    | 'Outros'
    | 'Enviado',
  filter: {
    page?: number;
    filtro?: string;
    busca?: string;
  }
): Promise<AxiosResponse<RequestsProps>> => {
  if (status === 'Enviado') {
    return apinewforms.get(`/flow/solicitacoes/`, {
      params: { enviado: true, ...filter },
      headers: {
        Authorization: `Bearer ${getCookie('gov_access_token')}`,
      },
    });
  } else {
    return apinewforms.get(`/flow/solicitacoes/`, {
      params: { status, ...filter },
      headers: {
        Authorization: `Bearer ${getCookie('gov_access_token')}`,
      },
    });
  }
};

export const getRequestForId = (
  identificador: string,
  protocolo_atendimento?: string,
  enviado?: boolean
): Promise<AxiosResponse<RequestDetailsProps>> =>
  apinewforms.get(`/flow/carregar_solicitacao`, {
    params: { identificador, protocolo_atendimento, enviado },
    headers: {
      Authorization: `Bearer ${getCookie('gov_access_token')}`,
    },
  });

export const getDocumentsRequest = (
  id: string,
  conformidade: string
): Promise<AxiosResponse> =>
  apinewforms.get(
    `/flow/documentos-solicitacao/?identificador_solicitacao=${id}`,
    {
      params: { id, conformidade },
      headers: {
        Authorization: `Bearer ${getCookie('gov_access_token')}`,
      },
    }
  );

export const getDocumentById = (id: string, solicitacao_id: string, enviado?: boolean) =>
  apinewforms.get(`/flow/documentos-solicitacao/${id}/`, {
    params: { identificador_solicitacao: solicitacao_id, enviado },
    headers: {
      Authorization: `Bearer ${getCookie('gov_access_token')}`,
    },
  });

export const putDocument = (
  id: string,
  conformidade: boolean,
  solicitacao_id: string
) =>
  apinewforms.put(
    `/flow/documentos-solicitacao/${id}/`,
    {
      id,
      conformidade,
    },
    {
      params: { identificador_solicitacao: solicitacao_id },
      headers: {
        Authorization: `Bearer ${getCookie('gov_access_token')}`,
      },
    }
  );

export const attachDocumentRequests = (
  solicitacao_id: string,
  formData: FormData
) =>
  apinewforms.post(`/flow/anexo-resposta/`, formData, {
    params: { solicitacao_id },
    headers: {
      Authorization: `Bearer ${getCookie('gov_access_token')}`,
    },
  });

export const attachDocumentRequestsIntern = (
  identificador_solicitacao: string,
  formData: FormData
) =>
  apinewforms.post(`/flow/anexos-internos/`, formData, {
    params: { identificador_solicitacao },
    headers: {
      Authorization: `Bearer ${getCookie('gov_access_token')}`,
    },
  });

export const getCPFData = (cpf: string) =>
  apisso.post(
    '/admin/checar/atendente/cpf/',
    {
      cpf,
    },
    {
      headers: {
        Authorization: `Bearer ${getCookie('gov_access_token')}`,
      },
    }
  );
