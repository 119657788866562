import React, { ReactElement, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Header } from '../..';
import { sendCodeForSISECI, setCookie } from '../../../services/auth';
// import Header from '../../Header';

interface Props {
  children: JSX.Element;
  window?: () => Window;
}

export default function Dash(props: Props): ReactElement {
  const { children } = props;
  const history = useHistory();
  const location = useLocation();
  useEffect(() => {
    const url = (window as any).location.search;
    const { pathname } = location;
    const query = new URLSearchParams(url);
    const code: any = query.get('code');
    if (code) {
      setCookie('pathname_redirect_gov', pathname, 1);
      sendCodeForSISECI(code, history);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Header />
      {children}
    </>
  );
}
