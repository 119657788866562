import { Button, Card, Dropdown, Table } from "react-bootstrap";
import styled from "styled-components";

export const CardHeader = styled(Card.Header)`
  display: flex;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.shape};
  align-items: center;
  padding: 20px;
`;

export const Title = styled.p`
  font-size: 24px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.secundary};
  margin: 0;
`;

export const InfoTitle = styled.p`
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.30000001192092896px;
  text-align: left;
  color: ${({ theme }) => theme.colors.text};
  text-transform: capitalize;
`;

export const InformationDescription = styled.p`
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 1px;
  text-align: left;
  opacity: 0.6;
  color: ${({ theme }) => theme.colors.text_secundary};
`;

export const CustomTable = styled(Table)`
  margin-top: 4px;

  .table-header {
    background-color: ${({ theme }) => theme.colors.secundary};
    color: ${({ theme }) => theme.colors.shape};
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 700;
    border: none;
    margin: 0;
  }

  .table-body td,
  .table-header th {
    padding: 20px;
  }

  .table-body {
    border-top: none !important;
  }

  .table-body tr:nth-of-type(odd) {
    background-color: rgba(14, 134, 254, 0.05);
  }
`;

export const DropdownCustom = styled(Dropdown)`
  .dropdown-toggle::after {
    display: none !important;
  }

  .drop-button {
    background-color: transparent !important;
    border-color: rgb(225, 230, 239) !important;
    color: ${({ theme }) => theme.colors.text_secundary};
    box-shadow: none !important;
    
    :hover {
      background-color: #6c757d !important;
      border-color: #6c757d !important;
    }

    :active {
      background-color: #6c757d !important;
      border-color: #6c757d !important;
    }
    :focus {
      background-color: #6c757d !important;
      border-color: #6c757d !important;
    }

    :hover > .icon {
      color: #fff;
    }

    :active > .icon {
      color: #fff !important;
    }

    :focus > .icon {
      color: #fff !important;
    }
  }
`;

export const DropdownItemCustom = styled(Dropdown.Item)`
  font-size: 16px;
  color: ${({ theme }) => theme.colors.text};
  font-weight: 400;

  .icon-action {
    color: ${({ theme }) => theme.colors.text};
    font-size: 15px;
    margin-right: 8px;
  }
`;

export const ButtonReturn = styled(Button)`
  background-color: ${({ theme }) => theme.colors.secundary};
  border-color: ${({ theme }) => theme.colors.secundary};

  &:hover {
    background-color: ${({ theme }) => theme.colors.secundary} !important;
    filter: brightness(80%);
    color: ${({ theme }) => theme.colors.shape};
  }
  &:focus {
    background-color: ${({ theme }) => theme.colors.secundary} !important;
  }
`;

export const Box = styled.div`
  display: flex;
  align-items: center;
`;

export const CardIcon = styled.div`
  width: 56px;
  height: 56px;
  background-color: ${({ theme }) => theme.colors.background_icon};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  color: ${({ theme }) => theme.colors.primary};
  margin-right: 16px;
`;

export const CardFooterCustom = styled(Card.Footer)`
  background-color: ${({ theme }) => theme.colors.shape};
`;

export const BoxButton = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 16px 32px;
  @media screen and (max-width: 575px) {
    padding: 16px 0;
  }
`;
export const ButtonCreate = styled(Button)`
  display: flex;
  align-items: center;
  padding: 10px 12px;
`;
export const NoFoundData = styled.h4`
  display: flex;
  justify-content: center;
  margin-top: 10px;
`;
