import * as Yup from "yup";

const schema = Yup.object().shape({
  ordem: Yup.number().required("O campo ordem é obrigatório."),
  texto_ajuda: Yup.string().required("O campo texto ajuda é obrigatório."),
  titulo: Yup.string().required("O campo título é obrigatório."),
  tipo_arquivo: Yup.string().required("O campo tipo arquivo é obrigatório."),
  alternativa_titulo: Yup.string().required("O campo título é obrigatório."),
  nome_campo: Yup.string().required("O campo nome campo é obrigatório."),
});

export { schema };
