import styled from "styled-components";
import { Form } from "react-bootstrap";

export const HeaderModal = styled.div`
  display: flex;
  width: 100%;
  height: 56px;
  color: ${({ theme }) => theme.colors.shape};
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.secundary};
  padding: 10px 18px;
`;

export const Content = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: ${({ theme }) => theme.colors.text};
`;

export const Info = styled.div`
  display: flex;
  align-items: center;
`;

export const TitleModal = styled.span`
  margin-left: 13px;
  text-transform: uppercase;
  font-weight: 500;
`;

export const BoxCloseModal = styled.span`
  align-items: center;
  display: flex;
  border: none;
  cursor: pointer;
`;

export const SelectFilter = styled(Form.Select)`
  border: 1.5px solid #e1e6ef;
  border-radius: 0;
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  letter-spacing: 0.3px;
  color: #1e212a;
  height: 44px;
  width: 100%;
`;
