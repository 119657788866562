import React, { Fragment, useCallback, useMemo, useState } from "react";
import { Campo, Formulario } from "../../../interfaces/form-flow";
import { Button, CircularProgress, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import {
  Text,
  Area,
  TextEmail,
  TextNumber,
  TextDate,
  Phone,
  Select,
  Multiple,
  MultCheckboxe,
} from "../Inputs";
import Arquivo from "../Inputs/Arquivo";
import SelectAPI from "../Inputs/SelectAPI";
import MultipleAPI from "../Inputs/MultipleAPI";
import getCookie from "../../../utils/getCookies";
import { answerForm } from "../../../services/flow";
import SuccessContentForm from "../../SuccessContentForm";
import { useHistory, useLocation } from "react-router-dom";
import { sanitizeHtml } from "../../../utils/sanitizeHtml";

interface FormAttendantProps {
  formData: Formulario[];
  id: number;
  identificador: string;
  protocolo: string;
}

const useStyles = makeStyles(() => ({
  root: {
    "& .MuiOutlinedInput-root": {
      "&:hover fieldset": {
        borderColor: "#264259",
      },
    },
  },
  title: {
    fontFamily: "Roboto",
    fontWeight: 500,
    color: "#264259",
    textTransform: "uppercase",
    textAlign: "center",
    fontSize: 16,
    padding: 20,
  },
  subtitle: {
    opacity: 0.6,
    textAlign: "center",
    fontWeight: "normal",
    fontSize: 16,
  },
  formControl: {
    minWidth: 120,
  },
  adornedEnd: {
    paddingRight: 0,
  },
  buttonForm: {
    backgroundColor: "#264259",
  },
}));

export function FormAttendant({
  formData,
  identificador,
  protocolo,
  id,
}: FormAttendantProps): JSX.Element {
  const history = useHistory();
  const tokenSSO: string | null = getCookie("gov_access_token");
  const classes = useStyles();
  const [values, setValues] = useState<any>({});
  const [errors, setErrors] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [saved, setSaved] = useState<boolean>(false);
  const form = formData[0];

  function useQuery() {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
  }

  const query = useQuery();

  const validate = useCallback(() => {
    let invalids = [];
    let currentValues = {};

    if (form?.campos?.length) {
      form.campos.forEach((field) => {
        let fieldId = field.id;
        let value = values[fieldId];
        // Marca os erros
        if (field.obrigatorio && !value) {
          currentValues[fieldId] = "Campo obrigatório";
        }
        if (field.validador) {
          let reg = new RegExp(field.validador, "g").test(value);
          if (!reg) {
            currentValues[fieldId] = "Campo no formato inválido";
          }
        }

        if (currentValues[fieldId]) {
          invalids.push(currentValues[fieldId]);
        }

        setErrors(currentValues);
      });
    }

    return !invalids.length;
  }, [form?.campos, values]);

  async function onSubmit(event: React.SyntheticEvent) {
    event.preventDefault();
    if (validate()) {
      let user = JSON.parse(getCookie("gov_user_data") || "{}");
      if (id) {
        let forResposta = {};

        form.campos.forEach((field) => {
          let fieldId = field.id;
          let value = values[fieldId];
          if (field.tipo === "Data" && value && value instanceof Date) {
            value = value.toLocaleDateString("pt-BR");
          }

          forResposta[fieldId] = {
            id: fieldId,
            resposta: value,
            titulo: field.titulo,
          };
        });

        let formatData = {
          flow_resposta: id,
          formulario: form.slug,
          cpf: user.cpf,
          resposta: forResposta,
        };

        try {
          setLoading(true);
          const { data, status } = await answerForm(tokenSSO, formatData);

          if (data && status === 201) {
            setSaved(true);
          }
        } catch (error) {
          console.log("error ao salvar resposta", error);
        } finally {
          setLoading(false);
        }
      }
    }
  }

  // set os Inputs
  const fieldChanged = (fieldId: number, value: any) => {
    setValues((currentValue) => {
      let newValue = (currentValue[fieldId] = value);

      return Object.assign({}, newValue, currentValue);
    });

    if (errors) {
      setErrors((currentError) => (currentError[fieldId] = ""));
    }
  };

  const handleReturn = () => {
    if (protocolo) {
      history.push(
        `/solicitacoes/detalhes/${identificador}/visualizar-simples/${protocolo}`
      );
    } else {
      history.push(
        `/solicitacoes/detalhes/${identificador}/visualizar-simples`
      );
    }
  };

  return (
    <>
      {form && !saved && (
        <>
          <Grid container alignItems="center" justifyContent="center">
            <Grid item xs={12}>
              <Typography variant="h6" className={classes.title}>
                {form.titulo}
              </Typography>
            </Grid>
            <Grid
              style={{ textAlign: "center", marginBottom: 20 }}
              item
              xs={12}
            >
              <Typography className={classes.subtitle}>
                <div dangerouslySetInnerHTML={{ __html: sanitizeHtml(form.descricao) }} />
              </Typography>
            </Grid>
          </Grid>
          <form onSubmit={onSubmit} className={classes.root}>
            {!form.campos.length ? (
              <Alert severity="warning" style={{ marginBottom: 10 }}>
                Nenhum campo disponível
              </Alert>
            ) : (
              <Grid container spacing={2}>
                {form.campos.map((campo: Campo) => (
                  <Fragment key={campo.id}>
                    {campo.tipo === "Texto" && (
                      <Grid sm={12} md={6} item>
                        <Text
                          required={campo.obrigatorio}
                          id={`${campo.tipo}-${campo.id}`}
                          value={values[campo.id] ? values[campo.id] : ""}
                          label={campo.titulo}
                          onChange={(e) =>
                            fieldChanged(campo.id, e.target.value)
                          }
                          error={errors[campo.id] ? true : false}
                          helperText={
                            errors[campo.id]
                              ? errors[campo.id]
                              : campo.texto_ajuda
                          }
                          mask={
                            campo.mascara !== "mascara" ? campo.mascara : ""
                          }
                        />
                      </Grid>
                    )}
                    {campo.tipo === "Area" && (
                      <Grid sm={12} md={12} item>
                        <Area
                          required={campo.obrigatorio}
                          id={`${campo.tipo}-${campo.id}`}
                          label={campo.titulo}
                          value={values[campo.id] ? values[campo.id] : ""}
                          error={errors[campo.id] ? true : false}
                          onChange={(e) =>
                            fieldChanged(campo.id, e.target.value)
                          }
                          helperText={
                            errors[campo.id]
                              ? errors[campo.id]
                              : campo.texto_ajuda
                          }
                        />
                      </Grid>
                    )}
                    {campo.tipo === "Email" && (
                      <Grid sm={12} md={4} item>
                        <TextEmail
                          required={campo.obrigatorio}
                          id={`${campo.tipo}-${campo.id}`}
                          label={campo.titulo}
                          value={values[campo.id] ? values[campo.id] : ""}
                          error={errors[campo.id] ? true : false}
                          onChange={(e) =>
                            fieldChanged(campo.id, e.target.value)
                          }
                          helperText={
                            errors[campo.id]
                              ? errors[campo.id]
                              : campo.texto_ajuda
                          }
                        />
                      </Grid>
                    )}
                    {campo.tipo === "Numero" && (
                      <Grid sm={12} md={6} item>
                        <TextNumber
                          id={`${campo.tipo}-${campo.id}`}
                          label={campo.titulo}
                          value={values[campo.id] ? values[campo.id] : ""}
                          required={campo.obrigatorio}
                          onChange={(e) =>
                            fieldChanged(campo.id, e.target.value)
                          }
                          helperText={
                            errors[campo.id]
                              ? errors[campo.id]
                              : campo.texto_ajuda
                          }
                        />
                      </Grid>
                    )}
                    {campo.tipo === "Telefone" && (
                      <Grid sm={12} md={3} item>
                        <Phone
                          required={campo.obrigatorio ? true : false}
                          id={`${campo.tipo}-${campo.id}`}
                          label={campo.titulo}
                          value={values[campo.id] ? values[campo.id] : ""}
                          error={errors[campo.id] ? true : false}
                          onChange={(e) =>
                            fieldChanged(campo.id, e.target.value)
                          }
                          mask={campo.mascara}
                          helperText={
                            errors[campo.id]
                              ? errors[campo.id]
                              : campo.texto_ajuda
                          }
                        />
                      </Grid>
                    )}
                    {campo.tipo === "Data" && (
                      <Grid sm={12} md={3} item>
                        <TextDate
                          required={campo.obrigatorio}
                          label={campo.titulo}
                          value={values[campo.id] ? values[campo.id] : null}
                          error={errors[campo.id] ? true : false}
                          onChange={(data) => fieldChanged(campo.id, data)}
                          InputLabelProps={{ shrink: true }}
                          helperText={
                            errors[campo.id]
                              ? errors[campo.id]
                              : campo.texto_ajuda
                          }
                        />
                      </Grid>
                    )}
                    {campo.tipo === "Alternativas" &&
                      campo.tipo_alternativa === "RadioButton" && (
                        <Grid sm={12} md={12} item>
                          <MultCheckboxe
                            required={campo.obrigatorio}
                            id={`${campo.tipo}-${campo.id}`}
                            label={campo.titulo}
                            value={values[campo.id] ? values[campo.id] : ""}
                            error={errors[campo.id] ? true : false}
                            onChange={(e) =>
                              fieldChanged(campo.id, e.target.value)
                            }
                            helperText={
                              errors[campo.id]
                                ? errors[campo.id]
                                : campo.texto_ajuda
                            }
                            itens={campo.alternativas}
                          />
                        </Grid>
                      )}
                    {campo.tipo === "Alternativas" &&
                      campo.tipo_alternativa === "Select" && (
                        <Grid sm={12} md={6} item>
                          <Select
                            required={campo.obrigatorio}
                            id={`${campo.tipo}-${campo.id}`}
                            label={campo.titulo}
                            value={values[campo.id] ? values[campo.id] : ""}
                            error={errors[campo.id] ? true : false}
                            onChange={(e) =>
                              fieldChanged(campo.id, e.target.value)
                            }
                            helperText={
                              errors[campo.id]
                                ? errors[campo.id]
                                : campo.texto_ajuda
                            }
                            itens={campo.alternativas}
                            getOptionLabel={(option) => option.titulo}
                          />
                        </Grid>
                      )}
                    {campo.tipo === "Alternativas_API" && (
                      <Grid sm={12} md={6} item>
                        <SelectAPI
                          required={campo.obrigatorio}
                          id={`${campo.tipo}-${campo.id}`}
                          label={campo.titulo}
                          value={values[campo.id] ? values[campo.id] : ""}
                          error={errors[campo.id] ? true : false}
                          onChange={(value) => fieldChanged(campo.id, value)}
                          helperText={
                            errors[campo.id]
                              ? errors[campo.id]
                              : campo.texto_ajuda
                          }
                          itens={campo.alternativas}
                          getOptionLabel={(option) => option.label}
                        />
                      </Grid>
                    )}
                    {campo.tipo === "Multiplos" && (
                      <Grid sm={12} md={12} item>
                        <Multiple
                          required={campo.obrigatorio}
                          label={campo.titulo}
                          values={values[campo.id] ? values[campo.id] : []}
                          error={errors[campo.id] ? true : false}
                          onChange={(value) => fieldChanged(campo.id, value)}
                          InputLabelProps={{ shrink: true }}
                          helperText={
                            errors[campo.id]
                              ? errors[campo.id]
                              : campo.texto_ajuda
                          }
                          itens={campo.alternativas}
                          getOptionLabel={(option) => option.label}
                        />
                      </Grid>
                    )}
                    {campo.tipo === "Multiplos_API" && (
                      <Grid sm={12} md={6} item>
                        <MultipleAPI
                          required={campo.obrigatorio}
                          label={campo.titulo}
                          values={values[campo.id] ? values[campo.id] : []}
                          error={errors[campo.id] ? true : false}
                          onChange={(value) => fieldChanged(campo.id, value)}
                          InputLabelProps={{ shrink: true }}
                          helperText={
                            errors[campo.id]
                              ? errors[campo.id]
                              : campo.texto_ajuda
                          }
                          itens={campo.alternativas}
                          getOptionLabel={(option) => option.label}
                        />
                      </Grid>
                    )}

                    {campo.tipo === "Arquivo" && (
                      <Grid sm={12} md={6} item>
                        <Arquivo
                          required={campo.obrigatorio}
                          id={`${campo.tipo}-${campo.id}`}
                          label={campo.titulo}
                          value={values[campo.id] ? values[campo.id] : ""}
                          error={errors[campo.id] ? true : false}
                          onChange={(value) => fieldChanged(campo.id, value)}
                          helperText={
                            errors[campo.id]
                              ? errors[campo.id]
                              : campo.texto_ajuda
                          }
                        />
                      </Grid>
                    )}
                  </Fragment>
                ))}
              </Grid>
            )}

            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item xs={3}>
                <Button style={{ color: "#264259" }} onClick={handleReturn}>
                  VOLTAR
                </Button>
              </Grid>
              <Grid item xs={3}>
                <Button
                  className={classes.buttonForm}
                  variant="contained"
                  fullWidth
                  color="primary"
                  type="submit"
                  disabled={loading}
                >
                  {loading ? <CircularProgress /> : "SALVAR"}
                </Button>
              </Grid>
            </Grid>
          </form>
        </>
      )}
      {saved && (
        <SuccessContentForm
          onFinishButton={handleReturn}
          showButton
        />
      )}
    </>
  );
}
