import { AxiosResponse } from 'axios';
import { apinewforms } from './api';
import getCookie from '../utils/getCookies';

export const createFormInFlow = (
  ordem: string,
  titulo: string,
  descricao: string,
  flow: string,
  orgao: number,
  ativo: boolean,
  para: string,
  tipo: string
): Promise<AxiosResponse> =>
  apinewforms.post(
    `/flow/formulario-apagar/`,
    {
      ordem,
      titulo,
      descricao,
      flow,
      orgao,
      ativo,
      para,
      tipo,
    },
    {
      headers: {
        Authorization: `Bearer ${getCookie('gov_access_token')}`,
      },
    }
  );

export const getFormInFlowById = (
  flow_slug: string,
  form_slug: string
): Promise<AxiosResponse> =>
  apinewforms.get(`/flow/formulario-apagar/${form_slug}/?flow=${flow_slug}`, {
    headers: {
      Authorization: `Bearer ${getCookie('gov_access_token')}`,
    },
  });

export const updateFormInFlow = (
  flow_slug: string,
  form_slug: string,
  titulo: string,
  descricao: string,
  ativo: boolean,
  orgao: number,
  ordem: string,
  para: string,
  tipo: string
): Promise<AxiosResponse> =>
  apinewforms.put(
    `/flow/formulario-apagar/${form_slug}/?flow=${flow_slug}`,
    {
      slug: form_slug,
      titulo,
      descricao,
      ativo,
      orgao,
      ordem,
      para,
      tipo,
      flow: flow_slug,
    },
    {
      headers: {
        Authorization: `Bearer ${getCookie('gov_access_token')}`,
      },
    }
  );

export const getHeadersDocuments = (orgao_id: string) =>
  apinewforms.get(`/core/cabecalho_orgao/?orgao_id=${orgao_id}`, {
    headers: {
      Authorization: `Bearer ${getCookie('gov_access_token')}`,
    },
  });
export const getSelectorsDocuments = (orgao_id: string) =>
  apinewforms.get(`/core/cabecalho_orgao/?orgao_id=${orgao_id}`, {
    headers: {
      Authorization: `Bearer ${getCookie('gov_access_token')}`,
    },
  });

export const updateDocumentInFlow = (
  flowId: string,
  documentId: string,
  active: boolean
): Promise<AxiosResponse> =>
  apinewforms.post(
    `/flow/fluxo-documento/ativar-desativar/?flow=${flowId}`,
    {
      id: documentId,
      ativo: active,
    },
    {
      headers: {
        Authorization: `Bearer ${getCookie('gov_access_token')}`,
      },
    }
  );
