import { Card, Container, Form, Button } from 'react-bootstrap';
import styled from 'styled-components';

export const ContainerMain = styled(Container)`
  margin-top: 56px;
  margin-bottom: 20px;
`;

export const CardHeader = styled(Card.Header)`
  display: flex;
  background-color: ${({ theme }) => theme.colors.shape};
  align-items: center;
  padding: 20px;
`;

export const Title = styled.p`
  font-size: 24px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.secundary};
  margin: 0;
`;

export const FormGroupCustom = styled(Form.Group)`
  padding: 0px 40px;
  margin: 20px 0px;
`;

export const FormLabelCustom = styled(Form.Label)`
  &:after {
    color: ${({ theme }) => theme.colors.error};
    content: ' *';
    display: ${({ required }) => (required ? 'inline' : 'none')};
  }
`;

export const CardFooterCustom = styled(Card.Footer)`
  background-color: ${({ theme }) => theme.colors.shape};
  display: flex;
  justify-content: center;
  padding: 0px;
`;

export const ActionButton = styled(Button)`
  border: none;
  border-radius: 0px;
  display: flex;
  justify-content: center;
  width: 50%;
  padding: 15px 0;
`;

export const ContainerOption = styled.div`
  display: flex;
  gap: 20px;
`;

export const BoxLoading = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 700px;
  justify-content: center;
  align-items: center;
`;

export const ContainerLegend = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
`;
export const TitleLoading = styled.h1`
  font-family: 'Roboto';
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  letter-spacing: 0.30000001192092896px;
  text-align: center;
`;
export const SubTitleLoading = styled.p`
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.30000001192092896px;
  text-align: center;
`;
