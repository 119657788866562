import { apinewforms } from "./api";

export const sendFile = (
    arquivo
) =>{
    const formData = new FormData();
    formData.append('arquivo', arquivo);

    return apinewforms.post(`/flow/imagens-upload/`, formData, {
        headers: {
            'content-type': 'multipart/form-data',
        }});

}
