import React from 'react';

import { LoadingComponent } from './styles';

const Loading = () => {
  return (
    <LoadingComponent
      animation="border"
    />
  );
}

export default Loading;