import React from "react";
import { Modal } from "react-bootstrap";
import { MdClose, MdVisibility } from "react-icons/md";
import { HeaderProps } from "../../../@types/organ";
import { BoxCloseModal, Content, HeaderModal, Info, TitleModal } from "./style";
import { sanitizeHtml } from "../../../utils/sanitizeHtml";
interface Props {
  show: boolean;
  handleShow: () => void;
  data: HeaderProps;
}
const ModalInformationHeader = ({ show, handleShow, data }: Props) => {
  return (
    <Modal show={show} onHide={handleShow} centered size="lg">
      <HeaderModal>
        <Info>
          <MdVisibility size={22} />
          <TitleModal>{data.titulo}</TitleModal>
        </Info>
        <BoxCloseModal onClick={handleShow}>
          <MdClose size={22} />
        </BoxCloseModal>
      </HeaderModal>
      <Modal.Body>
        <Content dangerouslySetInnerHTML={{ __html: sanitizeHtml(data.conteudo) }}></Content>
      </Modal.Body>
    </Modal>
  );
};

export default ModalInformationHeader;
