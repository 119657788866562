import { Container, Card as BCard, Table, Button } from "react-bootstrap";
import { MdAssignment, MdDone, MdInfo } from "react-icons/md";
import styled, { css } from "styled-components";

export const ContainerMain = styled(Container)`
  margin-top: 56px;
  margin-bottom: 20px;
`;

export const Card = styled(BCard)`
  margin-bottom: 20px;
`;

export const BoxHeaderCard = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100px;
  margin-right: 20px;
`;

export const TitleHeadCard = styled.div`
  display: flex;
  align-items: center;
  padding-left: 20px;
`;

export const InfoIcon = styled(MdInfo)`
  color: #0e86fe;
  background-color: rgba(14, 134, 254, 0.08);
  border-radius: 8px;
  width: 56px;
  height: 56px;
  padding: 15px;
`;

export const BoxTextsTitleCard = styled.div`
  padding-left: 20px;
`;

export const TextsTitleCard = styled.div`
  font-size: 20px;
  font-weight: 600;
  color: #1e212a;
`;

export const BoxBodyCard = styled.div`
  padding-left: 20px;
  padding-top: 35px;
  border-top: 1.5px solid #e1e6ef;
  min-height: 550px;
  padding-bottom: 20px;
`;

export const BoxBodyCardTable = styled.div`
  padding-top: 35px;
  border-top: 1.5px solid #e1e6ef;
`;

export const TextMark = styled.p`
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  color: #1e212a;
  margin-bottom: 10px;
`;
export const SubTextMark = styled.p`
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 1px;
  color: #506176;
  margin-bottom: 25px;
  opacity: 0.6;
  word-wrap: break-word;
`;

export const CustomTable = styled(Table)`
  margin-top: 5px;

  .table-header {
    background-color: ${({ theme }) => theme.colors.secundary};
    color: ${({ theme }) => theme.colors.shape};
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 700;
    border: none;
  }
  .table-header th {
    padding: 20px;
  }

  .table-body td {
    padding: 30px;
  }

  .table-body tr .text-right {
    text-align: right !important;
  }

  .no-extra-spacing * {
    margin: 0;
    padding: 0;
  }

  .table-body .hiddenDetails td {
    padding: 10px 30px;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px !important;
    align-items: center !important;
    text-align: right !important;
    letter-spacing: 0.3px !important;
    color: #1e212a !important;
    background-color: #ffffff !important;
  }

  .table-body {
  }
  .table-body tr:nth-of-type(odd) {
    background-color: rgba(14, 134, 254, 0.05);
  }

  .table-body .hiddenDetails {
  }

  .table-body .hiddenDetails th {
    padding: 10px 30px;
    color: #506176;
    font-size: 14px;
    font-weight: bold;
    background-color: #ffffff !important;
    letter-spacing: 1px;
  }
`;

export const BoxButtonsAttachments = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 15px;
`;

export const ButtonAttachment = styled(Button)`
  margin-right: 20px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  background-color: ${({ theme }) => theme.colors.primary};
  width: 196px;
  height: 44px;
  border-radius: 4px;
`;

export const ButtonCreateDocument = styled(Button)`
  margin-right: 20px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  background-color: ${({ theme }) => theme.colors.primary};
  width: 196px;
  height: 44px;
  border-radius: 4px;
`;

export const BoxTimeLine = styled.div`
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 400px;
  padding-top: 20px;
`;

export const TimeLine = styled.div`
  position: relative;
  &::after {
    content: "";
    position: absolute;

    top: 50px;
    bottom: -50px;
    left: 60px;
    margin-left: -3px;
    border-left: 1.5px dashed #e1e6ef;
    box-sizing: border-box;
  }
  box-sizing: border-box;
`;

export const DoneIcon = styled(MdDone)``;

export const TimeLineContainer = styled.div<{
  content: string;
  checked?: boolean;
}>`
  padding: 10px 40px;
  position: relative;
  font-family: "Roboto";

  left: 57px;
  &::after {
    display: flex;
    align-items: center;
    justify-content: center;

    content: "${(props) => props.content}";
    position: absolute;
    width: 32px;
    height: 32px;
    font-size: 14px;
    font-weight: 500;
    top: 15px;
    border-radius: 50%;
    z-index: 1;
    left: -16px;
    top: 20px;
    ${(props) =>
      props.checked
        ? css`
            background: #27ab6e;
            color: #ffffff;
            content: url("/assets/images/check.svg");
          `
        : css`
            background: #e1e6ef;
            color: #506176;
          `}
  }
`;

export const TimeLineContent = styled.div`
  background-color: white;
  position: relative;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e1e6ef;
  width: 100%;
`;

export const AssignmentIcon = styled(MdAssignment)`
  color: #0e86fe;
  margin-right: 10px;
`;

export const TimeLineContentBoxText = styled.div``;

export const TimeTitle = styled.h1`
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  color: #1e212a;
  margin: 0;
`;
export const TimeSubTitle = styled.p`
  font-family: "Roboto";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  color: #506176;
`;

export const CardFooterCustom = styled(Card.Footer)`
  background-color: ${({ theme }) => theme.colors.shape};
`;

export const ButtonReturn = styled(Button)`
  background-color: ${({ theme }) => theme.colors.secundary};

  &:hover {
    background-color: ${({ theme }) => theme.colors.secundary} !important;
    filter: brightness(80%);
    color: ${({ theme }) => theme.colors.shape};
  }
  &:focus {
    background-color: ${({ theme }) => theme.colors.secundary} !important;
  }
`;

export const ContainerActions = styled.div`
  display: flex;
  gap: 8px;
`;
