export const limit = (
  str: string,
  limit: number = 130,
  suffix: string = ' [...]',
) => {
  return str.substring(0,limit) + suffix;
}

export const stripTag = (str: string) => {
  return str.replace(/<[^>]*>?/gm, '');
}

export const hidePartOfEmail = (email: string) => {
  let hide = email.split("@")[0].length - 2;
  var r = new RegExp(".{" + hide + "}@", "g");
  return email.replace(r, "***@");
}