import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  title: {
    fontFamily: 'Roboto',
    fontWeight: 500,
    marginTop: 15,
    fontSize: 20,
    color: '#264259',
  },
  description: {
    marginTop: 10,
    fontWeight: 'normal',
    fontSize: 14,
    color: '#264259',
    opacity: 0.6,
  },
  mainCard: {
    color: '#373F41',
    minHeight: 100,
    marginTop: 20,
    padding: 20,
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  buttonForm: {
    backgroundColor: '#264259',
  },
}));

export default useStyles;
