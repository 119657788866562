import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Card, Form, Tabs, Tab } from 'react-bootstrap';
import Loading from '../../Components/Loading';

import {
  ContainerMain,
  CardHeader,
  Title,
  FormGroupCustom,
  FormLabelCustom,
  CardFooterCustom,
  CardIcon,
  ButtonSave,
  ButtonCancel,
  BoxLoading,
  CardBody,
  ButtonSaveAssigned
} from './styles';
import { MdDescription, } from 'react-icons/md';
import ModalCodeValidate from './Components/ModalCodeValidate';
import CancelModal from '../../Components/CancelModal';
import { useStateValue } from '../../providers/StateProvider';
import { actionTypes } from '../../store/reducer';
import { createRequestDocument, getVisualizationResponse, updateRequestDocument } from '../../services/documents';
import { getOrganHeader } from '../../services/organ';
import MyEditor from '../../Components/MyEditor';
import ActionModal from '../../Components/ActionModal';

interface HeaderProps {
  conteudo: string;
  created_at: string;
  id: string;
  orgao: number;
  titulo: string;
  updated_at: string;
  user: any;
}
export interface DocumentProps {
  assunto: string;
  cabecalho: string;
  conteudo: string;
  created_at: string;
  hash?: string;
  id: string;
  solicitacao: number;
  updated_at: string;
  user: any;
}

const AttachDocument = () => {
  const history = useHistory();
  const { solicitacao_id, orgao_id, solicitacao, id_documento } = useParams<{ solicitacao_id: string; orgao_id: string; solicitacao?: string, id_documento: string }>();
  const [, dispatch] = useStateValue();
  const [showModalCode, setShowModalCode] = useState(false);
  const [showModalCancel, setShowModalCancel] = useState(false);
  const [showActionModal, setShowActionModal] = useState(false);
  const [assunto, setAssunto] = useState<string>('');
  const [headers, setHeaders] = useState<HeaderProps[]>();
  const [conteudo, setConteudo] = useState<string>('');
  const [selectCabecalho, setSelectCabecalho] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [existDocument, setExistDocument] = useState(null);
  const [handleSetTypeAssigned, setHandleSetTypeAssigned] = useState<'updated' | 'created' | null>(null);

  const redirectToDetails = () => {
    window.location.pathname = `solicitacoes/detalhes/${solicitacao}/`;
  }

  const handleCancel = () => {
    setShowModalCancel(true);
  }

  const handleCloseCancel = () => {
    history.goBack();
    dispatch({
      type: actionTypes.SET_FLASH_MESSAGE,
      flashMessage: {
        show: true,
        type: 'error-alt',
        title: 'Cancelado',
        message: 'Documento não foi criado.',
      },
    });
  }

  const handleUpdateToast = () => {
    dispatch({
      type: actionTypes.SET_FLASH_MESSAGE,
      flashMessage: {
        show: true,
        type: 'success-alt',
        title: 'Sucesso',
        message: 'Documento foi atualizado com sucesso.',
      },
    });
    redirectToDetails()
  }

  const handleCreateToast = () => {
    dispatch({
      type: actionTypes.SET_FLASH_MESSAGE,
      flashMessage: {
        show: true,
        type: 'success-alt',
        title: 'Sucesso',
        message: 'Documento foi criado com sucesso.',
      },
    });
    redirectToDetails()
  }

  const updateDocumentWithoutAssignment = () => {
    updateDocument();
    dispatch({
      type: actionTypes.SET_FLASH_MESSAGE,
      flashMessage: {
        show: true,
        type: 'success-alt',
        title: 'Sucesso',
        message: 'Documento foi atualizado com sucesso.',
      },
    });
    redirectToDetails();
  }

  const updateDocument = async () => {
    const { data } = await updateRequestDocument(parseInt(solicitacao_id), assunto, conteudo, selectCabecalho.id, solicitacao, id_documento);
    return data;
  }

  const createDocument = async () => {
    const { data } = await createRequestDocument(
      parseInt(solicitacao_id),
      assunto,
      conteudo,
      selectCabecalho.id
    );
    return data;
  }

  const handleSubmitAssignedDocument = async (type: 'created' | 'updated') => {
    try {
      setLoading(true);
      setShowModalCode(true);
      setHandleSetTypeAssigned(type);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  const getAllApi = async () => {
    const organ = await getOrganHeader(orgao_id);
    setHeaders(organ.data.results);
    if (id_documento) {
      const document = await getVisualizationResponse(id_documento, solicitacao);
      setExistDocument(document.data)
    }
  }

  const onFinishFunction = {
    'created': createDocument,
    'updated': updateDocument,
  }

  const onFinishMessageFunction = {
    'created': handleCreateToast,
    'updated': handleUpdateToast,
  }

  useEffect(() => {
    getAllApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (existDocument) {
      console.log(existDocument)
      setAssunto(existDocument.assunto);
      setSelectCabecalho(existDocument.cabecalho)
      setConteudo(existDocument.conteudo)
    }
  }, [existDocument]);



  return (
    <>
      {showModalCode && (
        <>
          <ModalCodeValidate
            show={showModalCode}
            setShow={setShowModalCode}
            onFinish={onFinishFunction[handleSetTypeAssigned]}
            onFinishMessage={onFinishMessageFunction[handleSetTypeAssigned]}
          />
        </>
      )}
      <ContainerMain>
          <Card>
            <CardHeader>
              <div>
                <CardIcon>
                  <MdDescription size={20} />
                </CardIcon>
              </div>
              <Title>
                {existDocument ? 'Atualizar ' : 'Criar '}documento
              </Title>
            </CardHeader>
            <Tabs defaultActiveKey="edit" className="mb-3">
              <Tab eventKey="edit" title="Editar">
                <>
                  <Card.Body>
                    <FormGroupCustom className="mb-3" controlId="">
                      <FormLabelCustom required>Assunto</FormLabelCustom>
                      <Form.Control
                        required
                        value={assunto}
                        onChange={(e) => setAssunto(e.target.value)}
                      >
                      </Form.Control>
                      <Form.Text className="text-muted">
                        Informe o assunto do documento
                      </Form.Text>
                    </FormGroupCustom>
                    <FormGroupCustom className="mb-3" controlId="">
                      <FormLabelCustom required>Cabeçalho</FormLabelCustom>
                      <Form.Select onChange={(e) => {
                        const findHeader = headers?.find((header) => header.id === e.target.value);
                        setSelectCabecalho(findHeader);
                      }}>
                        <option value="none">Selecione</option>
                        {headers?.map((header) => (
                          <option selected={header.id === selectCabecalho?.id} value={header.id}>{header.titulo}</option>
                        ))}
                      </Form.Select>
                      <Form.Text className="text-muted">
                        Informe o cabeçalho do documento
                      </Form.Text>
                    </FormGroupCustom>
                    <FormGroupCustom>
                      <FormLabelCustom required>Conteúdo</FormLabelCustom>
                      <MyEditor
                        value={conteudo}
                        setValue={setConteudo}
                        modeEdit='full'
                        addConf={{
                          height: 300
                        }}
                      />
                      <Form.Text className="text-muted">
                        Informe o conteúdo do documento
                      </Form.Text>
                    </FormGroupCustom>
                  </Card.Body>
                  <CardFooterCustom>
                    <ButtonCancel
                      onClick={handleCancel}>
                      Cancelar
                    </ButtonCancel>
                    {existDocument && (
                      <>
                        {loading ? (
                          <BoxLoading>
                            <Loading />
                          </BoxLoading>
                        ) : (
                          <ButtonSave
                            disabled={
                              !assunto ||
                              !selectCabecalho
                            }
                            type="button"
                            onClick={() => setShowActionModal(true)}
                          >
                            Atualizar
                          </ButtonSave>
                        )}
                      </>
                    )}
                    {loading ? (
                      <BoxLoading>
                        <Loading />
                      </BoxLoading>
                    ) : (
                      <ButtonSaveAssigned
                        disabled={
                          !assunto ||
                          !selectCabecalho
                        }
                        type="button"
                        onClick={() => handleSubmitAssignedDocument(existDocument ? 'updated' : 'created')}
                      >
                        {existDocument ? 'Atualizar e Assinar' : 'Salvar e Assinar'}
                      </ButtonSaveAssigned>
                    )}
                  </CardFooterCustom>
                </>
              </Tab>
              <Tab eventKey="preview" title="Prévia">
                <CardBody>
                  <MyEditor
                    value={
                      `${selectCabecalho ? selectCabecalho?.conteudo : ''}
                      ${conteudo ?? ''}`}
                    disabled={true}
                    addConf={{
                      height: 600,
                    }}
                  />
                </CardBody>
              </Tab>
            </Tabs>
          </Card>
      </ContainerMain>

      <CancelModal
        show={showModalCancel}
        handleShow={setShowModalCancel}
        title="Cancelar documento"
        description='Tem certeza que deseja cancelar este documento? Essa ação não pode ser desfeita.'
        handleActionOk={handleCloseCancel}
      />
      <ActionModal
        handleActionOk={updateDocumentWithoutAssignment}
        handleShow={setShowActionModal}
        show={showActionModal}
        title='Atualizar Documento'
        description='Tem certeza que deseja atualizar esse Documento?'
      />
    </>
  );
}

export default AttachDocument;