import React from 'react';
import {
  TextField,
} from '@material-ui/core';
import { TextFieldProps } from '@material-ui/core/TextField';
import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: '#264259',
      },
    },
  },
  textField: {
    marginBottom: theme.spacing(1),
  },
  input: {
    color: theme.palette.primary.main,
  },
}));

export default function TextNumber(props: TextFieldProps): JSX.Element {
  const classes = useStyles();

  return (
    <TextField
      InputProps={{
        className: classes.input,
      }}
      className={classes.textField}
      type="number"
      variant="outlined"
      fullWidth
      autoComplete='off'
      {...props}
    />
  );
}