import { Button, Card, Container, Dropdown, Form } from "react-bootstrap";
import { MdSearch } from "react-icons/md";
import styled from "styled-components";

export const Box = styled.div`
  display: flex;
  align-items: center;
`;

export const CardIcon = styled.div`
  width: 56px;
  height: 56px;
  background-color: ${({ theme }) => theme.colors.background_icon};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  color: ${({ theme }) => theme.colors.primary};
  margin-right: 16px;
`;

export const CardHeader = styled(Card.Header)`
  display: flex;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.shape};
  align-items: center;
  padding: 20px;
  flex-wrap: wrap;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const Title = styled.p`
  font-size: 24px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.secundary};
  margin: 0;
`;

export const ContainerMain = styled(Container)`
  margin-top: 56px;
  margin-bottom: 20px;
`;

export const ButtonLink = styled.button`
  all: unset;
`;

export const NoFoundData = styled.h4`
  display: flex;
  justify-content: center;
  margin-top: 10px;
`;

export const CardFooterCustom = styled(Card.Footer)`
  background-color: ${({ theme }) => theme.colors.shape};
  display: flex;
  justify-content: end;
`;

export const SelectFilter = styled(Form.Select)`
  border: 1.5px solid #e1e6ef;
  border-radius: 0;
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  letter-spacing: 0.3px;
  color: #1e212a;
  max-width: 142px;
  height: 44px;
  @media screen and (max-width: 768px) {
    max-width: 100%;
    margin-bottom: 10px;
  }
`;

export const FormFilter = styled.div`
  display: flex;
  margin-top: 10px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    width: 100%;
  }
`;
export const BoxSearch = styled.div`
  display: flex;
  align-items: center;
  width: 360px;
  height: 44px;
  background: #f3f5fb;
  border: 1.5px solid #e1e6ef;
  padding-left: 10px;
  @media screen and (max-width: 768px) {
    width: 100%;
    margin-bottom: 10px;
    margin-right: 10px;
  }
`;

export const IconSearch = styled(MdSearch)`
  color: #1e212a;
`;

export const InputSearch = styled.input`
  border: none;
  background: none;
  outline: none !important;
`;

export const ButtonSearch = styled(Button)`
  background-color: ${({ theme }) => theme.colors.primary};
  border: none !important;
  border-radius: 0 4px 4px 0;
`;

export const ButtonClearSearch = styled(Button)`
  background-color: ${({ theme }) => theme.colors.primary};
  border: none !important;
  border-radius: 0 4px 4px 0;
  display: flex;
  align-items: center;
`;

export const DropdownCustom = styled(Dropdown)`
  .dropdown-toggle::after {
    display: none !important;
  }
  .drop-button {
    background-color: #f8f9fa00;
    border: 1px solid #e1e6ef;
    color: ${({ theme }) => theme.colors.text};
  }
`;

export const CustomButton = styled(Button)`
  background-color: transparent !important;
  border-color: rgb(225, 230, 239) !important;
  color: ${({ theme }) => theme.colors.text_secundary};
  :hover {
    background-color: #6c757d !important;
    border-color: #6c757d !important;
  }

  :active {
    background-color: #6c757d !important;
    border-color: #6c757d !important;
  }
  :focus {
    background-color: #6c757d !important;
    border-color: #6c757d !important;
  }

  :hover > .icon {
    color: #fff;
  }

  :active > .icon{
    color: #fff !important;
  }

  :focus > .icon {
    color: #fff !important;
  }
`;
