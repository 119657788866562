import { AxiosResponse } from "axios";
import { apinewforms } from "./api";
import { OrganListProps } from "../@types/organ";
import getCookie from "../utils/getCookies";

export const getOrganData = (
  page: number = 1,
  filter?: {
    busca: string;
    filtro: string;
  }
): Promise<AxiosResponse<OrganListProps>> =>
  apinewforms.get(`/core/orgaos/`, {
    params: {
      page,
      ...filter,
    },
    headers: {
      Authorization: `Bearer ${getCookie("gov_access_token")}`,
    },
  });

export const getOrganDataList = (
  tokenSSO: string
): Promise<AxiosResponse<OrganListProps>> =>
  apinewforms.get(`/core/orgaos-list/`, {
    headers: {
      Authorization: `Bearer ${tokenSSO}`,
    },
  });

export const getOrganDataById = (id: number): Promise<AxiosResponse> =>
  apinewforms.get(`/core/orgaos/${id}`, {
    headers: {
      Authorization: `Bearer ${getCookie("gov_access_token")}`,
    },
  });

export const getUserData = (
  organId: number,
  page: number
): Promise<AxiosResponse> =>
  apinewforms.get(`/core/usuario-orgao/?orgao_id=${organId}&page=${page}`, {
    headers: {
      Authorization: `Bearer ${getCookie("gov_access_token")}`,
    },
  });

export const getSectorData = (
  organId: number,
  page: number
): Promise<AxiosResponse> =>
  apinewforms.get(`/core/setor/?orgao_id=${organId}&page=${page}`, {
    headers: {
      Authorization: `Bearer ${getCookie("gov_access_token")}`,
    },
  });

export const putSectorUser = (
  userId: number,
  organId: number,
  sectorId: string[]
) =>
  apinewforms.put(
    `/core/usuario-orgao/${userId}/?orgao_id=${organId}`,
    {
      setores_ids: sectorId,
    },
    {
      headers: {
        Authorization: `Bearer ${getCookie("gov_access_token")}`,
      },
    }
  );

export const getSectorDataNoPaginate = (
  organId: number
): Promise<AxiosResponse> =>
  apinewforms.get(`/core/setor/?orgao_id=${organId}`, {
    headers: {
      Authorization: `Bearer ${getCookie("gov_access_token")}`,
    },
  });

export const getListHeader = (id: number): Promise<AxiosResponse> =>
  apinewforms.get(`/core/cabecalho_orgao/?orgao_id=${id}`, {
    headers: {
      Authorization: `Bearer ${getCookie("gov_access_token")}`,
    },
  });

export const getHeaderById = (
  id: string,
  orgao_id: number
): Promise<AxiosResponse> =>
  apinewforms.get(`/core/cabecalho_orgao/${id}/?orgao_id=${orgao_id}`, {
    headers: {
      Authorization: `Bearer ${getCookie("gov_access_token")}`,
    },
  });

export const createOrganHeader = (
  titulo: string,
  orgao_id: number,
  conteudo: string
) =>
  apinewforms.post(
    `/core/cabecalho_orgao/`,
    {
      titulo: titulo,
      orgao: orgao_id,
      conteudo: conteudo,
    },
    {
      headers: {
        Authorization: `Bearer ${getCookie("gov_access_token")}`,
      },
    }
  );

export const updateOrganHeader = (
  id: string,
  orgao_id: number,
  titulo: string,
  conteudo: string
): Promise<AxiosResponse> =>
  apinewforms.put(
    `/core/cabecalho_orgao/${id}/?orgao_id=${orgao_id}`,
    {
      id: id,
      titulo: titulo,
      orgao: orgao_id,
      conteudo: conteudo,
    },
    {
      headers: {
        Authorization: `Bearer ${getCookie("gov_access_token")}`,
      },
    }
  );

export const deleteOrganHeader = async (id: string, orgao_id: number) => {
  apinewforms.delete(`/core/cabecalho_orgao/${id}/?orgao_id=${orgao_id}`, {
    headers: {
      Authorization: `Bearer ${getCookie("gov_access_token")}`,
    },
  });
};

export const getOrganHeader = (organ_id: string): Promise<AxiosResponse> =>
  apinewforms.get(`core/cabecalho_orgao/?orgao_id=${organ_id}`, {
    headers: {
      Authorization: `Bearer ${getCookie("gov_access_token")}`,
    },
  });
