import React, { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Typography,
  Grid,
  Container,
  Card,
  CardContent,
  CircularProgress,
  Button,
} from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';
import { FluxoFlow, Formulario } from '../../interfaces/form-flow';
import StepperCard from '../../Components/StepperCard';
import useStyles from './styles';
import {
  getAnswerForm,
  getFluxoFlowBySlug,
  getFormBySlugFluxo,
  startRequestFlow
} from '../../services/flow';
import { FormFlow } from '../../Components';
import SignForm from '../../Components/SignForm';
import getCookie from '../../utils/getCookies';
import { setCookie } from '../../services/auth';
import { sanitizeHtml } from '../../utils/sanitizeHtml';

export default function TempForm(): JSX.Element {
  const tokenSSO: string | null = getCookie('gov_access_token');
  const history = useHistory();
  const [fluxoFlow, setFluxoFlow] = useState<FluxoFlow>();
  const [forms, setForms] = useState<Formulario[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [startSign, setStartSign] = useState<boolean>(false);
  const [hasFormStarted, setHasFormStart] = useState<boolean>(false);
  const [formStartedMessage, setFormStartedMessage] = useState<string>('');
  const [formsAnswer, setFormsAnswer] = useState<any[]>([]);
  const [activeStep, setActiveStep] = useState<number>(0);
  const classes = useStyles();

  function useQuery() {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
  }

  const query = useQuery();

  const getAnswers = async () => {
    let user = JSON.parse(getCookie('gov_user_data') || '{}');
    let formStarted = JSON.parse(getCookie('gov_user_form_started') || '{}');
    setLoading(true);
    try {
      if (user.cpf && formStarted.identificador) {
        const { data } = await getAnswerForm(tokenSSO, {
          identificador: formStarted.identificador,
          cpf: user.cpf,
        });
        setFormsAnswer(data.results);
      } else {
        setHasFormStart(true);
      }
    } catch (error) {

    } finally {
      setLoading(false);
    }
  };

  const startFormFlow = async (slug: string) => {
    let user = JSON.parse(getCookie('gov_user_data') || '{}');
    if (user.cpf) {
      try {
        setLoading(true);
        const protocolo_atendimento = query.get('protocolo_atendimento') || null;
        const canal = query?.get('aplicacao') === '156' ? '156' : 'Presencial';
        const nome = query?.get('aplicacao') === '156' ? query.get("nome") : null;

        const { status, data } = await startRequestFlow(
          tokenSSO,
          slug,
          query.get('cpf_cidadao'),
          protocolo_atendimento,
          canal,
          nome
        );
        if (data) {
          setCookie('gov_user_form_started', JSON.stringify(data), 1);
          if (status === 200) {
            await getAnswers();
          }
        }
      } catch (error) {
        setFormStartedMessage(error?.response?.data?.message || '');
        if (error?.response?.status === 406) {
          setHasFormStart(true);
          sessionStorage.removeItem('gov_user_form_started');
          await getAnswers();
        }
      } finally {
        setLoading(false);
      }
    }
  }

  const getFluxoFlow = async () => {
    setLoading(true);
    try {
      const { data } = await getFluxoFlowBySlug(tokenSSO, query.get('slug_servico'));
      setFluxoFlow(data.results[0]);
    } catch (err) {
    } finally {
      setLoading(false);
    }
  }

  const getFormsFlow = async (slug: string) => {
    setLoading(true);
    try {
      const { data } = await getFormBySlugFluxo(tokenSSO, slug);
      setForms(data.results);

    } catch (err) {
    } finally {
      setLoading(false);
    }
  }

  const getOrderByForms = () => {
    return forms.sort((a, b) => {
      if (a.ordem < b.ordem) return -1;
      if (a.ordem > b.ordem) return 1;
      return 0;
    });
  }

  const handleStep = (step: number) => setActiveStep(step);

  useEffect(() => {
    if (query.get('slug_servico')) {
      getFluxoFlow();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);


  useEffect(() => {
    if (fluxoFlow) {
      startFormFlow(fluxoFlow.slug);
      getFormsFlow(fluxoFlow.slug);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fluxoFlow]);


  return (
    <Container>
      <Grid container>
        <Grid item xs={12}>
          <Box padding={2}>
            {fluxoFlow && (
              <Typography className={classes.title}>
                <div
                  dangerouslySetInnerHTML={{ __html: sanitizeHtml(fluxoFlow.titulo) }}
                />
              </Typography>
            )}
            {fluxoFlow &&
              <Typography className={classes.description}>
                <div
                  dangerouslySetInnerHTML={{ __html: sanitizeHtml(fluxoFlow.descricao) }}
                />
              </Typography>
            }
          </Box>
        </Grid>
      </Grid>
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        spacing={2}
      >
        <Grid item xs={12}>
          <Card className={classes.mainCard}>
            <CardContent className={classes.cardContent}>
              {loading ? (
                <Box>
                  <CircularProgress />
                </Box>
              ) : (
                <Box>
                  <StepperCard
                    itens={forms}
                    activeStep={hasFormStarted ? forms.length : activeStep}
                  />
                  {
                    !startSign ?
                      (<>
                        {!hasFormStarted ? (
                          <FormFlow
                            formData={getOrderByForms()}
                            handleStep={(step) => handleStep(step)}
                            page={activeStep}
                            handleSubmit={(value, error) => {
                              setStartSign(true);
                            }}
                            formsAnswerData={formsAnswer}
                            loadData={{
                              cpf: query.get('cpf'),
                              email: query.get('email'),
                              nome: query.get('nome'),
                              telefone: query.get('telefone')
                            }}
                          />
                        ) : (
                          <>
                            <Typography align='center'>{formStartedMessage} </Typography>

                            <Button
                              className={classes.buttonForm}
                              variant="contained"
                              fullWidth
                              color="primary"
                              type="submit"
                              style={{ marginTop: 20 }}
                              onClick={() => history.push('/workspace/meus_servicos')}
                            >
                              Meus serviços
                            </Button>
                            <Button
                              style={{ color: '#0F6FB7', width: '100%' }}
                              variant='text'
                              onClick={() => history.push('/')}>
                              Home
                            </Button>
                          </>
                        )}
                      </>) :
                      <SignForm
                        handleStep={(step) => handleStep(step)}
                        page={activeStep}
                        handleSubmit={(value, error) => console.log(value, error)}
                        hasTerms={fluxoFlow?.termo ? fluxoFlow.termo : null}
                        subscriptionType={fluxoFlow?.tipo_assinatura}
                        typeFlow={fluxoFlow?.tipo}
                        protocolo={query.get('protocolo_atendimento') ? query.get('protocolo_atendimento') : null}
                        application={query?.get('aplicacao') || ''}
                      />
                  }
                </Box>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}