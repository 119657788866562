import { Spinner } from "react-bootstrap";
import { MdBlock, MdCheckCircle } from "react-icons/md";
import {
  CustomFooter,
  BoxWarning,
  CardIcon,
  CustomModal,
  TitleWarning,
  Warning,
  CustomBody,
  ButtonDeny,
  ButtonConfirmed,
} from "./style";
interface Props {
  show: boolean;
  handleShow: (back?: boolean) => void;
  title?: string;
  description?: string;
  handleActionOk?: () => void;
  handleActionCancel?: () => void;
  loading?: boolean;
  type?: 'error' | 'success';
}



const CancelModal = ({
  show,
  handleShow,
  title,
  description,
  handleActionOk,
  handleActionCancel,
  type = 'error',
  loading
}: Props) => {
  return (
    <CustomModal show={show} onHide={handleShow} centered>
      <CustomBody>
        <BoxWarning>
          <CardIcon type={type}>
            {type === 'error' ? <MdBlock size={20} /> : <MdCheckCircle size={20} />}
          </CardIcon>
          <TitleWarning>{title || `Cancelar cabeçalho`}</TitleWarning>
          <Warning>
            {description || `Tem certeza de que deseja cancelar este cabeçalho? Essa ação não pode ser desfeita.`}
          </Warning>
        </BoxWarning>
      </CustomBody>
      <CustomFooter>
        {loading ? (<Spinner animation="border" variant="primary" />) : (<>
          <ButtonDeny onClick={() => {
            handleActionCancel && handleActionCancel();
            handleShow(false)
          }}>Não</ButtonDeny>
          <ButtonConfirmed onClick={() => {
            handleActionOk && handleActionOk();
            handleShow(true)
          }}>Sim</ButtonConfirmed></>)}

      </CustomFooter>
    </CustomModal>
  );
};

export default CancelModal;
