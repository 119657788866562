import { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import dateFormatToBR from "../../utils/dateFormat";
import {
  MdCleaningServices,
  MdAccountBalance,
  MdVisibility,
} from "react-icons/md";
import { CustomTable } from "../Requests/styles";

import {
  Title,
  ContainerMain,
  CardHeader,
  CardFooterCustom,
  Box,
  CardIcon,
  SelectFilter,
  FormFilter,
  InputSearch,
  BoxSearch,
  IconSearch,
  ButtonSearch,
  ButtonClearSearch,
  NoFoundData,
  CustomButton,
} from "./style";

import Status from "../../Components/Status";
import Paginate from "../../Components/Paginate";
import { getOrganData } from "../../services/organ";
import { OrganListProps } from "../../@types/organ";
import Loading from "../../Components/Loading";
import { useHistory } from "react-router-dom";

const Organ = () => {
  const history = useHistory();
  const [searchData, setSearchData] = useState<string>("");
  const [isSearch, setIsSearch] = useState(false);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState<string>("");
  const [organData, setOrganData] = useState<OrganListProps>();

  const getOrganList = async ({ page }: { page: number }) => {
    try {
      setLoading(true);
      let filtro = undefined;

      if (filter.length && searchData.length) {
        console.log(filter, searchData);
        filtro = {
          busca: searchData.toLowerCase(),
          filtro: filter,
        };
      }
      const { data } = await getOrganData(page, filtro);
      setOrganData(data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleResetInputSearch = () => {
    history.go(0);
  };

  useEffect(() => {
    getOrganList({ page: 1 });
    // eslint-disable-next-line
  }, []);
  return (
    <ContainerMain>
      <Row>
        <Col md={12}>
          <Card>
            <CardHeader>
              <Box>
                <CardIcon>
                  <MdAccountBalance size={20} />
                </CardIcon>
                <Title>Órgãos</Title>
              </Box>
              <FormFilter>
                <SelectFilter
                  onChange={(event) => setFilter(event.target.value)}
                >
                  <option value="" selected>
                    Filtrar por
                  </option>
                  <option value="nome">Nome</option>
                  <option value="sigla">Sigla</option>
                </SelectFilter>
                <BoxSearch>
                  <IconSearch />
                  <InputSearch
                    style={{ width: "auto" }}
                    type="text"
                    placeholder="Digite sua busca"
                    value={searchData}
                    onChange={(event) => setSearchData(event.target.value)}
                  />
                </BoxSearch>

                {!isSearch ? (
                  <ButtonSearch
                    disabled={!searchData.length || !filter.length}
                    onClick={() => {
                      setIsSearch(true);
                      getOrganList({ page: 1 });
                    }}
                  >
                    {" "}
                    Buscar
                  </ButtonSearch>
                ) : (
                  <ButtonClearSearch
                    className="text-light"
                    variant="info"
                    onClick={handleResetInputSearch}
                  >
                    <MdCleaningServices size={15} style={{ marginRight: 4 }} />{" "}
                    Limpar
                  </ButtonClearSearch>
                )}
              </FormFilter>
            </CardHeader>
            <Card.Body>
              {loading ? (
                <div className="d-flex mt-4 justify-content-center">
                  <Loading />
                </div>
              ) : (
                <>
                  {organData && organData?.results.length > 0 ? (
                    <CustomTable responsive="md">
                      <thead className="table-header">
                        <tr>
                          <th>Sigla</th>
                          <th>Nome</th>
                          <th>Ativo</th>
                          <th>Adicionado em</th>
                          <th>Última atualização</th>
                          <th>Ações</th>
                        </tr>
                      </thead>
                      <tbody className="table-body">
                        {organData?.results.map((organ) => (
                          <tr key={organ.id}>
                            <td>{organ.sigla}</td>
                            <td>{organ.nome}</td>
                            <td>
                              <Status type={"true"} />
                            </td>
                            <td>{dateFormatToBR(organ.created_at, true)}</td>
                            <td>{dateFormatToBR(organ.updated_at, true)}</td>
                            <td>
                              <CustomButton
                                variant="outline-secondary"
                                onClick={() => {
                                  history.push(`/orgaos/${organ.id}`);
                                }}
                              >
                                <MdVisibility size={20} className="icon" />
                              </CustomButton>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </CustomTable>
                  ) : (
                    <NoFoundData>Sem resultados!</NoFoundData>
                  )}
                </>
              )}
            </Card.Body>
            <CardFooterCustom>
              {organData?.total_pages > 0 ? (
                <Paginate
                  flowData={organData}
                  setPage={getOrganList}
                  page={organData?.current}
                />
              ) : undefined}
            </CardFooterCustom>
          </Card>
        </Col>
      </Row>
    </ContainerMain>
  );
};

export default Organ;
