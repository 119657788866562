import { Box, CircularProgress } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { MdClose, MdSave, MdSettings } from "react-icons/md";
import { RiCloseCircleFill } from "react-icons/ri";
import {
  getSectorDataNoPaginate,
  putSectorUser,
} from "../../../services/organ";
import { BoxCloseModal, HeaderModal, Info, TitleModal } from "./style";
import MultiSelectSector from "../MultiSelectSector";
import { Sector } from "../MultiSelectSector/types";

interface Setores {
  first_name: string;
  username: string;
  perfil: string;
  orgao_usuario: {
    id_usuario_unidade: number;
    id_setor: string | null;
    setor_sigla: string | null;
  }[];
}

interface Props {
  organId: number;
  userId: number;
  show: boolean;
  handleReload: ({ page }) => void;
  handleShow: () => void;
  organUsers?: Setores[];
  userName?: string;
}
const ModalSector = ({
  show,
  handleShow,
  handleReload,
  organId,
  userId,
  organUsers,
  userName,
}: Props) => {
  const [loading, setLoading] = useState(false);
  const [sectors, setSectors] = useState<any[]>([]);
  const [selected, setSelected] = useState<Sector[]>([]);
  const [preSetSectors, setPreSetSectors] = useState<string[]>([]);

  const getSectors = async () => {
    try {
      const { data } = await getSectorDataNoPaginate(organId);
      setSectors(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSectors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (organUsers && userName) {
      const user = organUsers.find((data) => data.username === userName);
      if (user && user.orgao_usuario) {
        const sectorIds = user.orgao_usuario.map((setor) => setor.id_setor);
        setPreSetSectors(sectorIds);
      }
    }
  }, [organUsers, userName, userId]);

  const handleChangeSector = async () => {
    try {
      setLoading(true);
      const IDs = selected.map((sectors) => sectors.id);
      await putSectorUser(userId, organId, IDs);
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false);
      handleReload({ page: 1 });
      handleShow();
    }
  };

  return (
    <Modal show={show} onHide={handleShow} centered size="lg">
      <HeaderModal>
        <Info>
          <MdSettings size={22} />
          <TitleModal>Editar setor do usuário</TitleModal>
        </Info>
        <BoxCloseModal onClick={handleShow}>
          <MdClose size={22} />
        </BoxCloseModal>
      </HeaderModal>
      <Modal.Body>
        <Form.Label required>Selecione um setor:</Form.Label>
        <MultiSelectSector
          data={sectors}
          preSetSectors={preSetSectors}
          onSelectedSectors={(sector) => {
            setSelected(sector);
          }}
        />
        <Box
          style={{
            display: "flex",
            marginLeft: 0,
            marginTop: 20,
          }}
        >
          <Button
            id="saveData"
            style={{
              backgroundColor: "#27AB6E",
              border: 0,
              display: "flex",
              alignItems: "center",
              marginRight: 20,
            }}
            type="submit"
            disabled={loading}
            onClick={handleChangeSector}
          >
            {loading ? (
              <CircularProgress />
            ) : (
              <>
                <MdSave size={20} />
                <span style={{ marginLeft: 8 }}>Salvar</span>
              </>
            )}
          </Button>
          <Button
            className="button"
            disabled={loading}
            style={{
              backgroundColor: "#EC4C47",
              border: 0,
              display: "flex",
              alignItems: "center",
            }}
            onClick={handleShow}
          >
            <RiCloseCircleFill size={20} />
            <span style={{ marginLeft: 8 }}>Cancelar</span>
          </Button>
        </Box>
      </Modal.Body>
    </Modal>
  );
};

export default ModalSector;
