import React from 'react';
import { MdClose } from 'react-icons/md';
import { useStateValue } from '../../providers/StateProvider';
import { actionTypes } from '../../store/reducer';
import { CustomToastContainer, ToastContent, ToastHeader, TaskAltIcon, ToastTitle, ToastCloseButton, ToastBody, ToastMessages } from './styles';


const ToastMessage = (): JSX.Element => {
  const [{ flashMessage }, dispatch] = useStateValue();

  const handelClose = () => {
    dispatch({
      type: actionTypes.SET_FLASH_MESSAGE,
      flashMessage: null,
    });

  };

    return (
        <>
        {flashMessage && (<CustomToastContainer>
          <ToastContent show={flashMessage?.show} onClose={handelClose} delay={5000} type={flashMessage?.type} autohide>
            <ToastHeader>
              <TaskAltIcon type={flashMessage?.type} />
              <ToastTitle type={flashMessage?.type}>{flashMessage?.title}</ToastTitle>
              <ToastCloseButton type={flashMessage?.type} onClick={handelClose}>
              <MdClose />
              </ToastCloseButton>
            </ToastHeader>
            <ToastBody>
              <ToastMessages type={flashMessage?.type}>{flashMessage?.message}</ToastMessages>
            </ToastBody>
          </ToastContent>
        </CustomToastContainer>)}
        </>);
    };
    
    export default ToastMessage;