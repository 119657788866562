import React from 'react';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import InputMask from 'react-input-mask';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: '#264259',
      },
    },
  },
  labelRoot: {
    backgroundColor: '#fff',
  },
  input: {
    color: theme.palette.primary.main,
  },
  textField: {
    marginBottom: theme.spacing(1),
  },
  control: {
    marginBottom: theme.spacing(1),
  }
}));

const InputMaskText: React.FC = (props: any) => {
  const { mask, ...other }: any = props;

  return <InputMask {...other} mask={props.mask} maskChar=" " />;
}

export default function Text(props): JSX.Element {
  const classes = useStyles();

  return (
    <FormControl
      fullWidth
      variant="outlined"
      className={classes.control}
    >
      <InputLabel
        classes={{ root: classes.labelRoot }}
        {...props}
      >
        {props.label}
      </InputLabel>
      <OutlinedInput
        inputProps={{
          className: classes.input,
          mask: props.mask,
        }}
        inputComponent={InputMaskText}
        {...props}
        autoComplete='off'
      />
      {props.helperText && (
        <FormHelperText
          error={props.error}
        >{props.helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
}