import React, { useState } from 'react';
import { Modal} from 'react-bootstrap';
import { MdPassword } from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import { useStateValue } from '../../../../providers/StateProvider';
import { actionTypes } from '../../../../store/reducer';
import { ButtonCancel, ButtonSave, CardFooterCustom, CardIcon } from '../../styles';
import { BodyModal, ButtonSendCode, DescriptionModal, InputCode, MessageError, MessageSuccess, SendCodeMessage, TitleModal } from './styles';


type Props = {
    show: boolean;
    setShow: (show: boolean) => void;
};

const ModalCodeValidate = ({ show, setShow }:Props): JSX.Element => {
    const history = useHistory();
    const [code, setCode] = useState<string>('');
    const handleClose = () => setShow(false);
    const [errorInput, setErrorInput] = useState<boolean>(false);
    const [successInput, setSuccessInput] = useState<boolean>(false);
    const [,dispatch] = useStateValue();

    const handleAssignCode = (e) => {
        const value:string = e.target.value.replace(/\D/g, '');
        setCode(value);
        if (value.length !== 6) {
            setErrorInput(true);
            setSuccessInput(false);
        } else {
            setErrorInput(false);
            setSuccessInput(true);

        }
    }

    const handleSave = () => {
        history.goBack();
        dispatch({
            type: actionTypes.SET_FLASH_MESSAGE,
            flashMessage: {
                show: true,
                type: 'success-alt',
                title: 'Sucesso',
                message: 'Documento criado!',
            },
        });
    }

    return (
        <>
            <Modal size='lg' show={show} centered>
                <Modal.Body>
                    <BodyModal>
                        <CardIcon>
                            <MdPassword />
                        </CardIcon>
                        <TitleModal>Assinatura eletronica</TitleModal>
                        <DescriptionModal>Assine a tramitação com o código enviado para seu email: <strong>Fel***@gmail.com</strong></DescriptionModal>
                        <InputCode placeholder='000000' maxLength={6} value={code}
                            onChange={handleAssignCode}
                            error={errorInput}
                            success={successInput}
                        />
                        {errorInput && <MessageError>Error</MessageError>}

                        {successInput && <MessageSuccess>Verificado</MessageSuccess>}
                        <SendCodeMessage>Não recebeu o código? <ButtonSendCode>Reenviar</ButtonSendCode></SendCodeMessage>

                    </BodyModal>
                </Modal.Body>
                <CardFooterCustom>
                    <ButtonCancel onClick={handleClose}>
                        Cancelar
                    </ButtonCancel>
                    <ButtonSave onClick={handleSave} disabled={!successInput}>
                        Finalizar
                    </ButtonSave>
                </CardFooterCustom>
            </Modal>
        </>
    );
};

export default ModalCodeValidate;