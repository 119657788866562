import { useEffect, useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { getUserData, setCookie } from "../../services/auth";

export default function RedirectRequestDetails() {
  const history = useHistory();
  function useQuery() {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
  }
  const query = useQuery();

  async function handleGetUserData(token: string) {
    try {
      const data = await getUserData(token);
      return data;
    } catch (error) {
      console.log(error);
    }
  }

  const handleSetData = async () => {
    if (
      query.get('token') &&
      query.get('protocolo') &&
      query.get('protocolo_formflow')) {
      const data = await handleGetUserData(query.get('token'));
      if (data) {
        setCookie("gov_user_data", JSON.stringify(data), 1);
        setCookie('gov_access_token', query.get('token'), 1);
        history.push(`/solicitacoes/detalhes/${query.get('protocolo_formflow')}/visualizar-simples/${query.get('protocolo')}`);
      }
    }
  }

  useEffect(() => {
    handleSetData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, query]);

  return (
    <></>
  )
}