import { useEffect, useState } from "react";
import { Card, Form } from "react-bootstrap";
import { MdAddCircle, MdSettings } from "react-icons/md";
import { useHistory, useParams } from "react-router-dom";
import Loading from "../../../../Components/Loading";
import MyEditor from "../../../../Components/MyEditor";
import SelectWithSearch from "../../../../Components/SelectWithSearch";
import { useStateValue } from "../../../../providers/StateProvider";
import {
  createFluxo,
  getAllServices,
  getFluxo,
  updateFluxo,
} from "../../../../services/flow";
import { getOrganDataList } from "../../../../services/organ";
import { actionTypes } from "../../../../store/reducer";
import { CardIcon } from "../../styles";
import {
  CardFooterCustom,
  CardHeader,
  ContainerMain,
  FormGroupCustom,
  FormLabelCustom,
  Title,
  ActionButton,
  ContainerOption,
  MainOption,
  ContainerList,
  OptionList,
} from "./styles";
import getCookie from "../../../../utils/getCookies";
import { Checkbox, FormControlLabel, Grid } from "@material-ui/core";

const dataAccessFlowSelects = [
  {
    id: 0,
    value: "Ambos",
  },
  {
    id: 1,
    value: "Externo",
  },
  {
    id: 2,
    value: "Interno",
  },
];

const dataValidateList = [
  {
    id: 0,
    value: "Dias",
  },
  {
    id: 1,
    value: "Meses",
  },
  {
    id: 2,
    value: "Anos",
  },
];

const dataTypeList = [
  {
    id: 0,
    value: "Registro",
  },
  {
    id: 1,
    value: "Solicitação",
  },
];

const dataSubscriptionType = [
  {
    id: 0,
    value: "Código",
  },
  {
    id: 1,
    value: "Automática",
  },
];

const ManageFlow = () => {
  const history = useHistory();
  const [titulo, setTitulo] = useState<string>("");
  const [prazoRestituicao, setPrazoRestituicao] = useState<string>("1");
  const [descricao, setDescricao] = useState<string>("");
  const [organSelected, setOrgaoSelected] = useState<number>(0);
  const [organSlugSelected, setOrgaoSlugSelected] = useState<string>("");
  const [ativo, setAtivo] = useState<boolean>(true);
  const [integracaoExterna, setIntegracaoExterna] = useState<boolean>(true);
  const [automatico, setAutomatico] = useState<boolean>(false);
  const [terceiros, setTerceiros] = useState<boolean>(false);
  const [hasCancellation, setHasCancellation] = useState<boolean>(false);
  const [showMessageError, setShowMessageError] = useState<boolean>(false);
  const [orgaoList, setOrgaoList] = useState<any>(null);
  const [orgao, setOrgao] = useState<any>(null);
  const [orgaoAll, setOrgaoAll] = useState<any>();
  const [servicesList, setServicesList] = useState<any>(null);
  const [serviceSelected, setServiceSelected] = useState<any>(null);
  const [service, setService] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [, dispatch] = useStateValue();
  const { flow_slug } = useParams<{ flow_slug: string }>();
  const [loadingOrgao, setLoadingOrgao] = useState<boolean>(false);
  const [loadingServico, setLoadingServico] = useState<boolean>(false);
  const [hasModelFlow, setHasModelFlow] = useState<boolean>(false);
  const [checkedValidate, setCheckedValidate] = useState<boolean>(false);
  const [partialSetData, setPartialSetData] = useState<boolean>(false);
  const [validateSelected, setValidateSelected] = useState<string | null>(null);
  const [amount, setAmount] = useState<number | string>(null);
  const [flowAccess, setFlowAccess] = useState<string | null>(null);
  const [typeFlow, setTypeFlow] = useState<string | null>(null);
  const [rectificationAllowed, setRectificationAllowed] = useState<boolean>(false);
  const [typeExecutionFlow, setTypeExecutionFlow] = useState<string>('Manual');
  const [subscriptionType, setSubscriptionType] = useState<string | null>(null);

  const setOrgaoValueFormatted = (values) => {
    if (values) {
      let orgaoOptions = values.map((value) => ({
        label: `${value.nome} - ${value?.sigla}`,
        value: value.slug,
      }));
      setOrgaoList(orgaoOptions);
    }
  };

  const setServicoValueFormatted = (values) => {
    if (values) {
      let serviceOptions = values.map((value) => ({
        label: value.titulo,
        value: value.slug,
      }));
      setServicesList(serviceOptions);
      if (serviceSelected) {
        const service = handleGetById(serviceSelected, serviceOptions);
        if (service?.length) {
          setService({ label: service[0].label, value: service[0].value });
        }
      }
    }
  };

  const handleGetById = (slug: string, items: any) => {
    if (slug) {
      return items.filter((item) => item.slug === slug);
    }
  };

  const getOrgans = async () => {
    const tokenSSO: string | null = getCookie("gov_access_token");
    try {
      setLoadingOrgao(true);
      const { data } = await getOrganDataList(tokenSSO);
      if (data) {
        setOrgaoValueFormatted(data);
        setOrgaoAll(data);
        return data;
      }
    } catch (error) {
    } finally {
      setLoadingOrgao(false);
    }
  };

  const handleSave = async (e) => {
    e.preventDefault();

    try {
      if (
        (!hasModelFlow &&
          descricao &&
          serviceSelected &&
          subscriptionType &&
          organSelected &&
          flowAccess &&
          (flowAccess === "Interno" ? typeFlow : true) &&
          (checkedValidate ? validateSelected && amount : true)) ||
        (hasModelFlow && titulo && descricao)
      ) {
        setShowMessageError(false);
        console.log(typeFlow);
        const returnTypeFlow =
          flowAccess !== "Interno" ? "Solicitação" : typeFlow;
        if (flow_slug) {
          await updateFluxo(
            flow_slug,
            titulo,
            descricao,
            serviceSelected || "",
            ativo,
            organSelected,
            hasModelFlow,
            automatico,
            terceiros,
            flowAccess,
            returnTypeFlow,
            subscriptionType,
            validateSelected,
            Number(amount),
            integracaoExterna,
            typeExecutionFlow,
            hasCancellation,
            rectificationAllowed,
            partialSetData,
            prazoRestituicao ? Number(prazoRestituicao) : null,
          );
        } else {
          await createFluxo({
            titulo: titulo,
            descricao: descricao,
            servico_slug: serviceSelected || "",
            ativo: ativo,
            orgao: organSelected,
            modelo: hasModelFlow,
            automatico: automatico,
            terceiros: terceiros,
            para: flowAccess,
            tipo: returnTypeFlow,
            tipo_assinatura: subscriptionType,
            tipo_validade: validateSelected,
            validade: Number(amount),
            integracao_externa: integracaoExterna,
            tipo_execucao: !typeExecutionFlow ? "Manual" : typeExecutionFlow,
            permitir_cancelamento_cidadao: hasCancellation,
            permitir_retificacao: rectificationAllowed,
            permitir_preenchimento_parcial: partialSetData,
            tempo_resposta_restituido: prazoRestituicao ? Number(prazoRestituicao) : null,
          });
        }
        history.push("/fluxo");
        dispatch({
          type: actionTypes.SET_FLASH_MESSAGE,
          flashMessage: {
            show: true,
            type: "success-alt",
            title: "Sucesso",
            message: "Fluxo salvo!",
          },
        });
      } else {
        setShowMessageError(true);
      }
    } catch (err: any) {
      if (err?.response?.data?.message) {
        dispatch({
          type: actionTypes.SET_FLASH_MESSAGE,
          flashMessage: {
            show: true,
            type: "error-alt",
            title: "Error ao salvar",
            message: err?.response?.data?.message,
          },
        });
      }
      console.error(err?.response?.data?.message);
    }
  };

  const handleCloseCancel = () => {
    history.push("/fluxo");
  };

  const getServices = async (orgaoSlug?: string) => {
    setLoadingServico(true);
    try {
      const { data } = await getAllServices(orgaoSlug);
      if (data) {
        setServicoValueFormatted(data);
        return data;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingServico(false);
    }
  };

  useEffect(() => {
    if (organSelected && organSlugSelected) {
      getServices(organSlugSelected);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organSelected, organSlugSelected]);

  useEffect(() => {
    const getAllData = async () => {
      try {
        setLoading(true);
        const listOrgans: any = await getOrgans();
        const { data } = await getFluxo(flow_slug);
        if (data) {
          if (listOrgans?.length) {
            const orgaoSlug = listOrgans.filter(
              (item) => item.id === data.orgao
            );
            if (orgaoSlug?.length) {
              setOrgaoSelected(orgaoSlug[0].id);
              setOrgaoSlugSelected(orgaoSlug[0].slug);
              setOrgao({
                label: `${orgaoSlug[0].nome} - ${orgaoSlug[0].sigla}`,
                value: orgaoSlug[0].slug,
              });
              const listService = await getServices(orgaoSlug[0].slug);
              if (listService?.length) {
                const serviceSlug = listService.filter(
                  (item) => item.slug === data.servico_slug
                );
                if (serviceSlug?.length) {
                  setService({
                    label: serviceSlug[0].titulo,
                    value: serviceSlug[0].slug,
                  });
                }
              }
            }
          }
          setDescricao(data.descricao);
          setTitulo(data.titulo);
          setServiceSelected(data.servico_slug);
          setAtivo(data.ativo);
          setHasModelFlow(data.modelo);
          setAutomatico(data.automatico);
          setTerceiros(data.terceiros);
          setFlowAccess(data.para);
          setTypeFlow(data.tipo);
          setSubscriptionType(data.tipo_assinatura);
          setIntegracaoExterna(data.integracao_externa);
          setTypeExecutionFlow(data.tipo_execucao);
          setHasCancellation(data?.permitir_cancelamento_cidadao);
          setPartialSetData(data?.permitir_preenchimento_parcial);
          setRectificationAllowed(data?.permitir_retificacao);
          setPrazoRestituicao(data?.tempo_resposta_restituido);
          if (data?.tipo_validade) {
            setValidateSelected(data.tipo_validade);
            setAmount(data.validade);
            setCheckedValidate(true);
          }
        }
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    if (flow_slug) {
      getAllData();
    } else {
      getOrgans();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flow_slug]);

  const handleSelectOrgao = (value: { label: string; value: string }) => {
    if (value) {
      let [orgao] = handleGetById(value.value, orgaoAll);
      setOrgao(value);
      setOrgaoSelected(orgao.id);
      setOrgaoSlugSelected(orgao.slug);
      setShowMessageError(false);
      onRemoveServiceValues();
    }
  };

  const handleSelectService = (value: { label: string; value: string }) => {
    if (value) {
      setServiceSelected(value.value);
      setService(value);
      setShowMessageError(false);
    }
  };

  const handleCheckValidate = () => {
    setCheckedValidate(!checkedValidate);
  };

  const onRemoveServiceValues = () => {
    setServicesList([]);
    setServiceSelected("");
    setService(null);
  };

  useEffect(() => {
    if (hasModelFlow) {
      setServiceSelected(null);
    }
  }, [hasModelFlow]);

  return (
    <>
      {loading ? (
        <div className="d-flex mt-4 justify-content-center">
          <Loading />
        </div>
      ) : (
        <ContainerMain>
          <form onSubmit={handleSave}>
            <Card>
              <CardHeader>
                <div>
                  <CardIcon>
                    {flow_slug ? (
                      <MdSettings size={20} />
                    ) : (
                      <MdAddCircle size={20} />
                    )}
                  </CardIcon>
                </div>
                <Title>{`${flow_slug ? "Gerenciar" : "Novo"} Fluxo`}</Title>
              </CardHeader>
              <Card.Body>
                <FormGroupCustom className="mb-3">
                  <FormLabelCustom required>
                    Deseja tornar este fluxo como modelo?
                  </FormLabelCustom>
                  <ContainerOption>
                    <Form.Check
                      type="radio"
                      id="radio-hasmodelflow"
                      label="Sim"
                      name="radio-modelflow"
                      value="true"
                      checked={hasModelFlow ? true : false}
                      onChange={() => setHasModelFlow(true)}
                    />
                    <Form.Check
                      type="radio"
                      id="radio-no-hasmodelflow"
                      label="Não"
                      value="false"
                      name="radio-modelflow"
                      checked={hasModelFlow ? false : true}
                      onChange={() => setHasModelFlow(false)}
                    />
                  </ContainerOption>
                </FormGroupCustom>
                <FormGroupCustom className="mb-3" controlId="formBasicEmail">
                  <FormLabelCustom required>Título</FormLabelCustom>
                  <Form.Control
                    type="text"
                    value={titulo}
                    required
                    onChange={(event) => setTitulo(event.target.value)}
                  />
                  <Form.Text className="text-muted">
                    Informe um título para o fluxo
                  </Form.Text>
                </FormGroupCustom>

                <FormGroupCustom>
                  <FormLabelCustom required>Órgão Responsável</FormLabelCustom>
                  <SelectWithSearch
                    name="orgao"
                    options={orgaoList}
                    isDisabled={!orgaoList || loadingOrgao}
                    value={orgao}
                    isLoading={loadingOrgao}
                    onChange={handleSelectOrgao}
                    placeholder="Selecione ou digite o nome do órgão"
                  />
                  <Form.Text className="text-muted">
                    Informe o órgão responsável pelo fluxo
                  </Form.Text>
                  {showMessageError && !organSelected && (
                    <p
                      style={{
                        color: "red",
                        fontSize: 14,
                        fontWeight: "bold",
                      }}
                    >
                      Este campo não pode ser vazio.
                    </p>
                  )}
                </FormGroupCustom>

                <FormGroupCustom>
                  <FormLabelCustom required>Descrição</FormLabelCustom>
                  <MyEditor value={descricao} setValue={setDescricao} />
                  <Form.Text className="text-muted">
                    Descreva o que é o fluxo
                  </Form.Text>
                  {showMessageError && !descricao && (
                    <p
                      style={{
                        color: "red",
                        fontSize: 14,
                        fontWeight: "bold",
                      }}
                    >
                      Este campo não pode ser vazio.
                    </p>
                  )}
                </FormGroupCustom>
                {!hasModelFlow && (
                  <FormGroupCustom>
                    <FormLabelCustom required>
                      Identificador do serviço
                    </FormLabelCustom>
                    <SelectWithSearch
                      name="servicos"
                      value={service}
                      isDisabled={!servicesList?.length || loadingServico}
                      onChange={handleSelectService}
                      isLoading={loadingServico}
                      options={servicesList}
                      placeholder="Selecione ou digite o nome do serviço"
                    />
                    <Form.Text className="text-muted">
                      {!servicesList?.length
                        ? "Nenhum serviço listado"
                        : "Informe o identificador do serviço correspondente ao fluxo"}
                    </Form.Text>
                    {showMessageError && !serviceSelected && (
                      <p
                        style={{
                          color: "red",
                          fontSize: 14,
                          fontWeight: "bold",
                        }}
                      >
                        Este campo não pode ser vazio.
                      </p>
                    )}
                  </FormGroupCustom>
                )}
                {!hasModelFlow && (
                  <FormGroupCustom>
                    <FormLabelCustom required>Acesso ao fluxo</FormLabelCustom>
                    <Form.Select
                      required
                      value={flowAccess || "none"}
                      onChange={(e) => {
                        const findAccess = dataAccessFlowSelects?.find(
                          (access) => access.value === e.target.value
                        );
                        if (findAccess) setFlowAccess(findAccess.value);
                      }}
                    >
                      <option value="none">Selecione</option>
                      {dataAccessFlowSelects?.map((access) => (
                        <option
                          key={`flow-access-${access.id + 1}`}
                          value={access.value}
                        >
                          {access.value}
                        </option>
                      ))}
                    </Form.Select>
                    {showMessageError && !flowAccess ? (
                      <p
                        style={{
                          color: "red",
                          fontSize: 14,
                          fontWeight: "bold",
                        }}
                      >
                        Este campo não pode ser vazio.
                      </p>
                    ) : (
                      <Form.Text className="text-muted">
                        Escolha se o fluxo será respondido pelo cidadão ou pelo
                        atendente do órgão
                      </Form.Text>
                    )}
                    <ContainerList>
                      <MainOption>
                        <OptionList>
                          <strong>Externo:</strong>
                          Quando o fluxo se torna disponível nos canais usados
                          diretamente pelo cidadão. Portal, Workspace ou
                          Aplicativo.
                        </OptionList>
                        <OptionList>
                          <strong>Interno:</strong>
                          Quando o fluxo está disponível para o atendente
                          realizar o registro pelo cidadão. Atendimento online,
                          presencial ou por telefone.
                        </OptionList>
                        <OptionList>
                          <strong>Ambos:</strong>O fluxo está disponível para
                          ambos os caso
                        </OptionList>
                      </MainOption>
                    </ContainerList>
                  </FormGroupCustom>
                )}
                {!hasModelFlow && flowAccess === "Interno" && (
                  <FormGroupCustom>
                    <FormLabelCustom required>Tipo de Fluxo</FormLabelCustom>
                    <Form.Select
                      required
                      value={typeFlow || "none"}
                      onChange={(e) => {
                        const findType = dataTypeList?.find(
                          (type) => type.value === e.target.value
                        );
                        if (findType) setTypeFlow(findType.value);
                      }}
                    >
                      <option value="none">Selecione</option>
                      {dataTypeList?.map((type) => (
                        <option
                          value={type.value}
                          key={`type-flow-${type.id + 1}`}
                        >
                          {type.value}
                        </option>
                      ))}
                    </Form.Select>
                    {showMessageError && !typeFlow ? (
                      <p
                        style={{
                          color: "red",
                          fontSize: 14,
                          fontWeight: "bold",
                        }}
                      >
                        Este campo não pode ser vazio.
                      </p>
                    ) : (
                      <ContainerList>
                        <MainOption>
                          <OptionList>
                            <strong>Registro:</strong>O fluxo será usado apenas
                            para registro interno e não terá tramitação.
                          </OptionList>
                          <OptionList>
                            <strong>Solicitação:</strong>O fluxo será usado para
                            tramitação e atendimento de solicitações do cidadão.
                          </OptionList>
                        </MainOption>
                      </ContainerList>
                    )}
                  </FormGroupCustom>
                )}

                {!hasModelFlow && (
                  <FormGroupCustom>
                    <FormLabelCustom required>
                      Tipo de Assinatura
                    </FormLabelCustom>
                    <Form.Select
                      required
                      value={subscriptionType || "none"}
                      onChange={(e) => {
                        const findType = dataSubscriptionType?.find(
                          (type) => type.value === e.target.value
                        );
                        if (findType) setSubscriptionType(findType.value);
                      }}
                    >
                      <option value="none">Selecione</option>
                      {dataSubscriptionType?.map((type) => (
                        <option
                          key={`subscription-type-${type.id + 1}`}
                          value={type.value}
                        >
                          {type.value}
                        </option>
                      ))}
                    </Form.Select>
                    {showMessageError && !subscriptionType ? (
                      <p
                        style={{
                          color: "red",
                          fontSize: 14,
                          fontWeight: "bold",
                        }}
                      >
                        Este campo não pode ser vazio.
                      </p>
                    ) : (
                      <ContainerList>
                        <MainOption>
                          <OptionList>
                            <strong>Código:</strong>
                            Requisitar ao cidadão / atendente que seja enviado
                            um email com código para confirmar a assinatura.
                          </OptionList>
                          <OptionList>
                            <strong>Automática:</strong>O sistema gera um hash
                            para o usuário autenticado e valida o documento(Não
                            recomendado).
                          </OptionList>
                        </MainOption>
                      </ContainerList>
                    )}
                  </FormGroupCustom>
                )}
                {!hasModelFlow && (
                  <FormGroupCustom>
                    <FormLabelCustom required>
                      Qual o tipo de Fluxo
                    </FormLabelCustom>
                    <Form.Check
                      type="radio"
                      id="radio-type-flow-manual"
                      label="Manual"
                      name="radio-type-flow"
                      value="Manual"
                      checked={typeExecutionFlow === "Manual"}
                      onChange={() => setTypeExecutionFlow("Manual")}
                    />
                    {/* <Form.Check
                      type="radio"
                      id="radio-type-flow-semi"
                      label="Semi-Automático"
                      name="radio-type-flow"
                      value="Semi-Automático"
                      checked={typeExecutionFlow === "Semi-Automático"}
                      onChange={() => setTypeExecutionFlow("Semi-Automático")}
                    /> */}
                    <Form.Check
                      type="radio"
                      id="radio-type-flow-auto"
                      label="Automático"
                      name="radio-type-flow"
                      value="Automático"
                      checked={typeExecutionFlow === "Automático"}
                      onChange={() => setTypeExecutionFlow("Automático")}
                    />
                    <Form.Check
                      type="radio"
                      id="radio-type-flow-autosservico"
                      label="Autosserviço"
                      name="radio-type-flow"
                      value="Autosserviço"
                      checked={typeExecutionFlow === "Autosserviço"}
                      onChange={() => setTypeExecutionFlow("Autosserviço")}
                    />
                  </FormGroupCustom>
                )}
                {!hasModelFlow && (
                  <FormGroupCustom>
                    <FormLabelCustom required>
                      Serviço do tipo integração externa?
                    </FormLabelCustom>
                    <Form.Check
                      type="radio"
                      id="radio-external-integration"
                      label="Sim"
                      name="radio-external-integration"
                      value="true"
                      checked={integracaoExterna ? true : false}
                      onChange={() => setIntegracaoExterna(true)}
                    />
                    <Form.Check
                      type="radio"
                      id="radio-no-external-integration"
                      label="Não"
                      value="false"
                      name="radio-external-integration"
                      checked={integracaoExterna ? false : true}
                      onChange={() => setIntegracaoExterna(false)}
                    />
                  </FormGroupCustom>
                )}
                {!hasModelFlow && (
                  <FormGroupCustom>
                    <FormLabelCustom required>Está ativo?</FormLabelCustom>
                    <Form.Check
                      type="radio"
                      id="radio-active"
                      label="Sim"
                      name="radio-active"
                      value="true"
                      checked={ativo ? true : false}
                      onChange={() => setAtivo(true)}
                    />
                    <Form.Check
                      type="radio"
                      id="radio-no-active"
                      label="Não"
                      value="false"
                      name="radio-active"
                      checked={ativo ? false : true}
                      onChange={() => setAtivo(false)}
                    />
                  </FormGroupCustom>
                )}
                {!hasModelFlow && (
                  <FormGroupCustom>
                    <FormLabelCustom required>
                      Resposta automática
                    </FormLabelCustom>
                    <Form.Check
                      type="radio"
                      id="radio-active-auto"
                      label="Sim"
                      name="radio-active-auto"
                      value="true"
                      checked={automatico ? true : false}
                      onChange={() => setAutomatico(true)}
                    />
                    <Form.Check
                      type="radio"
                      id="radio-no-active-auto"
                      label="Não"
                      value="false"
                      name="radio-active-auto"
                      checked={automatico ? false : true}
                      onChange={() => setAutomatico(false)}
                    />
                  </FormGroupCustom>
                )}
                {!hasModelFlow && (
                  <FormGroupCustom>
                    <FormLabelCustom required>
                      Permitir retificação
                    </FormLabelCustom>
                    <Form.Check
                      type="radio"
                      id="radio-rectification-auto"
                      label="Sim"
                      name="radio-rectification-auto"
                      value="true"
                      checked={rectificationAllowed ? true : false}
                      onChange={() => setRectificationAllowed(true)}
                    />
                    <Form.Check
                      type="radio"
                      id="radio-no-rectification-auto"
                      label="Não"
                      value="false"
                      name="radio-rectification-auto"
                      checked={rectificationAllowed ? false : true}
                      onChange={() => setRectificationAllowed(false)}
                    />
                  </FormGroupCustom>
                )}
                {!hasModelFlow && (
                  <FormGroupCustom>
                    <FormLabelCustom required>
                      O serviço pode ser solicitado para terceiros?
                    </FormLabelCustom>
                    <Form.Check
                      type="radio"
                      id="radio-terceiros-auto"
                      label="Sim"
                      name="radio-terceiros-auto"
                      value="true"
                      checked={terceiros ? true : false}
                      onChange={() => setTerceiros(true)}
                    />
                    <Form.Check
                      type="radio"
                      id="radio-no-terceiros-auto"
                      label="Não"
                      value="false"
                      name="radio-terceiros-auto"
                      checked={terceiros ? false : true}
                      onChange={() => setTerceiros(false)}
                    />
                  </FormGroupCustom>
                )}
                {!hasModelFlow && (
                  <FormGroupCustom>
                    <FormLabelCustom required>
                      O serviço pode ser cancelado pelo usuário?
                    </FormLabelCustom>
                    <Form.Check
                      type="radio"
                      id="radio-cancelamento-user-auto"
                      label="Sim"
                      name="radio-cancelamento-user-auto"
                      value="true"
                      checked={hasCancellation ? true : false}
                      onChange={() => setHasCancellation(true)}
                    />
                    <Form.Check
                      type="radio"
                      id="radio-no-cancelamento-user-auto"
                      label="Não"
                      value="false"
                      name="radio-cancelamento-user-auto"
                      checked={hasCancellation ? false : true}
                      onChange={() => setHasCancellation(false)}
                    />
                  </FormGroupCustom>
                )}
                {!hasModelFlow && (
                  <FormGroupCustom>
                    <FormLabelCustom required>
                      Permitir preenchimento parcial?
                    </FormLabelCustom>
                    <Form.Check
                      type="radio"
                      id="radio-parcial-preenchimento"
                      label="Sim"
                      name="radio-parcial-preenchimento"
                      value="true"
                      checked={partialSetData ? true : false}
                      onChange={() => setPartialSetData(true)}
                    />
                    <Form.Check
                      type="radio"
                      id="radio-no-parcial-preenchimento"
                      label="Não"
                      value="false"
                      name="radio-parcial-preenchimento"
                      checked={partialSetData ? false : true}
                      onChange={() => setPartialSetData(false)}
                    />
                  </FormGroupCustom>
                )}
                {!hasModelFlow && (
                  <FormGroupCustom className="mb-3">
                    <FormLabelCustom>
                      Prazo de resposta a restituição (Em dias)
                    </FormLabelCustom>
                    <Form.Control
                      type="number"
                      style={{ width: "80px" }}
                      min={1}
                      value={prazoRestituicao}
                      onChange={(event) =>
                        setPrazoRestituicao(event.target.value)
                      }
                    />
                  </FormGroupCustom>
                )}
                {!hasModelFlow && (
                  <>
                    <FormGroupCustom>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={checkedValidate}
                            onChange={handleCheckValidate}
                            name="accept"
                            color="primary"
                          />
                        }
                        label="Adicionar tempo de validade para a solicitação deste fluxo?"
                      />
                    </FormGroupCustom>
                    {checkedValidate && (
                      <FormGroupCustom>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <FormLabelCustom required>
                              Tipo de validade
                            </FormLabelCustom>
                            <Form.Select
                              required
                              value={validateSelected || "none"}
                              onChange={(e) => {
                                const findValidate = dataValidateList?.find(
                                  (validate) =>
                                    validate.value === e.target.value
                                );
                                if (findValidate)
                                  setValidateSelected(findValidate.value);
                              }}
                            >
                              <option value="none">Selecione</option>
                              {dataValidateList?.map((validate) => (
                                <option
                                  key={`validate-${validate.id + 1}`}
                                  value={validate.value}
                                >
                                  {validate.value}
                                </option>
                              ))}
                            </Form.Select>
                            {showMessageError && !validateSelected ? (
                              <p
                                style={{
                                  color: "red",
                                  fontSize: 14,
                                  fontWeight: "bold",
                                }}
                              >
                                Este campo não pode ser vazio.
                              </p>
                            ) : (
                              <Form.Text className="text-muted">
                                Escolha o tipo de validade
                              </Form.Text>
                            )}
                          </Grid>
                          <Grid item>
                            <FormLabelCustom required>
                              Quantidade
                            </FormLabelCustom>
                            <Form.Control
                              type="number"
                              value={amount}
                              required
                              min={1}
                              onChange={(event) => {
                                setAmount(event.target.value);
                              }}
                            />
                            <Form.Text className="text-muted">
                              Informe a quantidade
                            </Form.Text>
                          </Grid>
                        </Grid>
                      </FormGroupCustom>
                    )}
                  </>
                )}
              </Card.Body>
              <CardFooterCustom>
                <ActionButton
                  className="button"
                  style={{ backgroundColor: "#fff", color: "#506176" }}
                  onClick={handleCloseCancel}
                >
                  <span>Cancelar</span>
                </ActionButton>
                <ActionButton
                  style={{ backgroundColor: "#27AB6E" }}
                  type="submit"
                >
                  <span>Salvar</span>
                </ActionButton>
              </CardFooterCustom>
            </Card>
          </form>
        </ContainerMain>
      )}
    </>
  );
};

export default ManageFlow;
