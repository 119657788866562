import React from "react"
import { Button, Modal } from "react-bootstrap";
import { MdClose, MdVisibility } from 'react-icons/md';


import { HeaderModal, Info, TitleModal, Description, BoxCloseModal } from './styles';
import { sanitizeHtml } from "../../utils/sanitizeHtml";

interface Props {
  isActive: boolean;
  handleActive: () => void;
  data: any;
}

const TermModal = ({
  isActive,
  handleActive,
  data
}: Props) => {

  return (
    <Modal show={isActive} onHide={handleActive}>
      <HeaderModal>
        <Info>
          <MdVisibility size={22} />
          <TitleModal>{data.titulo} - Versão ({data.versao})</TitleModal>
        </Info>
        <BoxCloseModal onClick={handleActive}>
          <MdClose size={22} />
        </BoxCloseModal>
      </HeaderModal>
      <Modal.Body>
        <Description dangerouslySetInnerHTML={{ __html: sanitizeHtml(data.texto) }}></Description>
      </Modal.Body>
      <Modal.Footer className="justify-content-start">
        <Button
          variant="danger"
          onClick={handleActive}
        >
          Fechar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default TermModal;