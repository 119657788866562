import { AxiosResponse } from "axios";
import { HomeResponse } from "../@types/home";
import { PermissionsData } from "../interfaces/permissions";
import { apinewforms, apisiseci } from "./api";
import { answerProps } from "../interfaces/form-flow";
import getCookie from "../utils/getCookies";

export const allFluxos = (
  page: number,
  search?: string,
  active?: string
): Promise<AxiosResponse> => {
  if (search !== undefined) {
    return apinewforms.get(`/flow/fluxo-apagar/`, {
      params: {
        ativo: active,
        search: search === "" ? undefined : search,
        page,
      },
      headers: {
        Authorization: `Bearer ${getCookie("gov_access_token")}`,
      },
    });
  }

  return apinewforms.get(`/flow/fluxo-apagar/?page=${page}`, {
    headers: {
      Authorization: `Bearer ${getCookie("gov_access_token")}`,
    },
  });
};

export const getFluxo = (slug_fluxo: string): Promise<AxiosResponse> =>
  apinewforms.get(`/flow/fluxo-apagar/${slug_fluxo}`, {
    headers: {
      Authorization: `Bearer ${getCookie("gov_access_token")}`,
    },
  });

export const createFluxo = (params: {
  titulo: string;
  descricao: string;
  servico_slug: string;
  ativo: boolean;
  orgao: number;
  modelo: boolean;
  automatico: boolean;
  terceiros: boolean;
  para: string;
  tipo: string;
  tipo_assinatura: string;
  tipo_validade: string;
  validade: number | string;
  integracao_externa: boolean;
  tipo_execucao: string;
  permitir_cancelamento_cidadao: boolean;
  permitir_retificacao: boolean;
  permitir_preenchimento_parcial: boolean;
  tempo_resposta_restituido: number | null;
}): Promise<AxiosResponse> =>
  apinewforms.post(`/flow/fluxo-apagar/`, params, {
    headers: {
      Authorization: `Bearer ${getCookie("gov_access_token")}`,
    },
  });

export const updateFluxo = (
  slug_fluxo: string,
  titulo: string,
  descricao: string,
  servico_slug: string,
  ativo: boolean,
  orgao: number,
  modelo: boolean,
  automatico: boolean,
  terceiros: boolean,
  para: string,
  tipo: string,
  tipo_assinatura: string,
  tipo_validade: string,
  validade: number | string,
  integracao_externa: boolean,
  tipo_execucao: string,
  permitir_cancelamento_cidadao: boolean,
  permitir_retificacao: boolean,
  permitir_preenchimento_parcial: boolean,
  tempo_resposta_restituido: number | null
): Promise<AxiosResponse> =>
  apinewforms.put(
    `/flow/fluxo-apagar/${slug_fluxo}/`,
    {
      slug: slug_fluxo,
      titulo,
      descricao,
      servico_slug,
      ativo,
      orgao,
      modelo,
      automatico,
      terceiros,
      para,
      tipo,
      tipo_assinatura,
      tipo_validade,
      validade,
      integracao_externa,
      tipo_execucao,
      permitir_cancelamento_cidadao,
      permitir_retificacao,
      permitir_preenchimento_parcial,
      tempo_resposta_restituido,
    },
    {
      headers: {
        Authorization: `Bearer ${getCookie("gov_access_token")}`,
      },
    }
  );

export const getDataHome = (token): Promise<AxiosResponse<HomeResponse>> =>
  apinewforms.get(`/flow/home`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const getMyPermissions = (
  token
): Promise<AxiosResponse<PermissionsData>> =>
  apinewforms.get(`/core/my-permissions`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const postDocumentForm = (
  flow: string,
  cabecalho: string,
  conteudo: string,
  assunto: string,
  tipo: string,
  conteudo_editavel: boolean,
  sistema_assina: boolean,
  usuario_assina: boolean
): Promise<AxiosResponse> =>
  apinewforms.post(
    "/flow/formato-documento/",
    {
      flow,
      cabecalho,
      conteudo,
      assunto,
      tipo,
      conteudo_editavel,
      sistema_assina,
      usuario_assina,
    },
    {
      headers: {
        Authorization: `Bearer ${getCookie("gov_access_token")}`,
      },
    }
  );

export const getDocumentForm = (flow_slug: string): Promise<AxiosResponse> =>
  apinewforms.get(`/flow/formato-documento/?flow_id=${flow_slug}`, {
    headers: {
      Authorization: `Bearer ${getCookie("gov_access_token")}`,
    },
  });

export const getDocumentFormById = (
  flow_slug: string,
  document_id: string
): Promise<AxiosResponse> =>
  apinewforms.get(
    `/flow/formato-documento/${document_id}/?flow_id=${flow_slug}`,
    {
      headers: {
        Authorization: `Bearer ${getCookie("gov_access_token")}`,
      },
    }
  );

export const putDocumentForm = (
  document_id: string,
  flow_slug: string,
  cabecalho_id: string,
  assunto: string,
  conteudo: string,
  tipo: string,
  conteudo_editavel: boolean,
  sistema_assina: boolean,
  usuario_assina: boolean,
  ativo: boolean
): Promise<AxiosResponse> =>
  apinewforms.put(
    `/flow/formato-documento/${document_id}/?flow_id=${flow_slug}`,
    {
      id: document_id,
      flow: flow_slug,
      assunto,
      cabecalho: cabecalho_id,
      conteudo,
      tipo,
      conteudo_editavel,
      sistema_assina,
      usuario_assina,
      ativo,
    },
    {
      headers: {
        Authorization: `Bearer ${getCookie("gov_access_token")}`,
      },
    }
  );

export const getTramiteOrgan = (identificador: string, token: string) =>
  apinewforms.get(
    `/flow/tramitacao-pendente?identificador_solicitacao=${identificador}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const postTramite = (
  orgao: number,
  status: string,
  descricao: string,
  identificador: string,
  token: string,
  tramitacao_id: string,
  setor?: string
) =>
  apinewforms.post(
    "/flow/tramitar-solicitacao",
    {
      orgao,
      status,
      descricao,
      identificador,
      tramitacao_id,
      setor,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const duplicateFlow = (
  flow: string,
  titulo: string,
  orgao: number,
  servico: string,
  ativo: boolean,
  descricao: string,
  tokenSSO: string
) =>
  apinewforms.post(
    "/flow/duplicar/",
    {
      flow,
      titulo,
      orgao,
      servico,
      ativo,
      descricao,
    },
    {
      headers: {
        Authorization: `Bearer ${tokenSSO}`,
      },
    }
  );

export const checkTask = (task_id: string, tokenSSO: string) =>
  apinewforms.post(
    "/flow/checar-task/",
    {
      task_id,
    },
    {
      headers: {
        Authorization: `Bearer ${tokenSSO}`,
      },
    }
  );

export const getAllServices = (organ_slug: string) =>
  apisiseci.get(`/cms/serv_filter/?orgao_slug=${organ_slug}`);

export const getServiceTerms = (
  tokenSSO: string,
  flow_id: string,
  page?: number
) =>
  apinewforms.get(`/flow/termo-fluxo/`, {
    params: {
      flow: flow_id,
      page,
    },
    headers: {
      Authorization: `Bearer ${tokenSSO}`,
    },
  });

export const getServiceTermsById = (
  tokenSSO: string,
  flow_id: string,
  id: string
) =>
  apinewforms.get(`/flow/termo-fluxo/${id}`, {
    params: {
      flow: flow_id,
    },
    headers: {
      Authorization: `Bearer ${tokenSSO}`,
    },
  });

export const saveServiceTerms = (
  tokenSSO: string,
  flow_id: string,
  body: {
    titulo: string;
    versao: string;
    texto: string;
  }
) =>
  apinewforms.post(
    `/flow/termo-fluxo/?flow=${flow_id}`,
    {
      ...body,
      flow: flow_id,
    },
    {
      headers: {
        Authorization: `Bearer ${tokenSSO}`,
      },
    }
  );

export const getFluxoFlowBySlug = (
  tokenSSO: string,
  slug: string
): Promise<AxiosResponse> =>
  apinewforms.get(`flow/fluxo-atendente/?servico_slug=${slug}`, {
    headers: {
      Authorization: `Bearer ${tokenSSO}`,
    },
  });

export const getFormBySlugFluxo = (
  tokenSSO: string,
  slug: string
): Promise<AxiosResponse> =>
  apinewforms.get(`/flow/formularios-atendente/?flow_slug=${slug}`, {
    headers: {
      Authorization: `Bearer ${tokenSSO}`,
    },
  });

export const getFormBySlug = (
  tokenSSO: string,
  slug: string
): Promise<AxiosResponse> =>
  apinewforms.get(`/flow/formularios-atendente/?slug=${slug}`, {
    headers: {
      Authorization: `Bearer ${tokenSSO}`,
    },
  });

export const sendFile = (
  tokenSSO: string,
  arquivo,
  onlyImage: boolean = true
) => {
  const formData = new FormData();
  formData.append("arquivo", arquivo);

  if (onlyImage) {
    return apinewforms.post(`/flow/imagens-upload/`, formData, {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: `Bearer ${tokenSSO}`,
      },
    });
  }

  return apinewforms.post(`/flow/arquivo-upload/`, formData, {
    headers: {
      "content-type": "multipart/form-data",
      Authorization: `Bearer ${tokenSSO}`,
    },
  });
};

export const getAnswerForm = (
  tokenSSO: string,
  params: {
    identificador?: number;
    cpf?: string;
  }
): Promise<AxiosResponse> =>
  apinewforms.get("/flow/respostas-formulario/", {
    params,
    headers: {
      Authorization: `Bearer ${tokenSSO}`,
    },
  });

export const answerForm = (
  tokenSSO: string,
  answer: answerProps
): Promise<AxiosResponse> =>
  apinewforms.post("/flow/responder-formulario-atendente/", answer, {
    headers: {
      Authorization: `Bearer ${tokenSSO}`,
    },
  });

export const getStatusFormFlow = (
  tokenSSO: string,
  params: {
    identificador?: number;
    cpf?: string;
  }
): Promise<AxiosResponse> =>
  apinewforms.get("/flow/rastreamento", {
    params,
    headers: {
      Authorization: `Bearer ${tokenSSO}`,
    },
  });

export const startRequestFlow = (
  tokenSSO: string,
  flow: string,
  cpf: string,
  protocolo_atendimento: string,
  canal: string,
  nome?: string | null
): Promise<AxiosResponse> =>
  apinewforms.post(
    "/flow/responder-fluxo-atendente/",
    {
      flow,
      cpf,
      protocolo_atendimento,
      canal,
      nome,
    },
    {
      headers: {
        Authorization: `Bearer ${tokenSSO}`,
      },
    }
  );

export const onTermFlowAccept = (
  tokenSSO: string,
  solicitacao: string,
  termo: string
): Promise<AxiosResponse> =>
  apinewforms.post(
    `/flow/termo-fluxo-concordar/`,
    {
      solicitacao,
      termo,
    },
    {
      headers: {
        Authorization: `Bearer ${tokenSSO}`,
      },
    }
  );

export const onConfirmWithoutSignature = (
  tokenSSO: string,
  identificador: string,
  cpf: string,
  tipo: string,
  internal_state: string
): Promise<AxiosResponse> =>
  apinewforms.post(
    `/flow/confirmar-tramitacao-sem-assinatura`,
    {
      identificador,
      cpf,
      tipo,
      internal_state,
    },
    {
      headers: {
        Authorization: `Bearer ${tokenSSO}`,
      },
    }
  );

export const getWorkflowByFlowId = ({
  tokenSSO,
  slug_fluxo,
  workflow_id,
}: {
  tokenSSO: string;
  slug_fluxo?: string;
  workflow_id?: string;
}): Promise<AxiosResponse> => {
  if (tokenSSO && !slug_fluxo && workflow_id) {
    return apinewforms.get(`/flow/workflow/${workflow_id}/`, {
      headers: {
        Authorization: `Bearer ${tokenSSO}`,
      },
    });
  } else {
    return apinewforms.get(`/flow/workflow/?flow=${slug_fluxo}`, {
      headers: {
        Authorization: `Bearer ${tokenSSO}`,
      },
    });
  }
};

export const createWorkflowByFlowId = (
  tokenSSO: string,
  body: {
    titulo: string;
    descricao: string;
    url: string;
    mapa: {
      id: string;
      valor: string;
    };
    flow: string;
  }
): Promise<AxiosResponse> =>
  apinewforms.post(`/flow/workflow/`, body, {
    headers: {
      Authorization: `Bearer ${tokenSSO}`,
    },
  });

export const updateWorkflowByFlowId = (
  { id, tokenSSO }: { id: number; tokenSSO: string },
  body: {
    titulo: string;
    descricao: string;
    url: string;
    mapa: {
      id: string;
      valor: string;
    };
    flow: string;
  }
): Promise<AxiosResponse> =>
  apinewforms.put(`/flow/workflow/${id}/`, body, {
    headers: {
      Authorization: `Bearer ${tokenSSO}`,
    },
  });

export const deleteWorkflowByFlowId = ({
  tokenSSO,
  id,
}: {
  tokenSSO: string;
  id: string;
}): Promise<AxiosResponse> =>
  apinewforms.delete(`/flow/workflow/${id}/`, {
    headers: {
      Authorization: `Bearer ${tokenSSO}`,
    },
  });

export const workflowRequest = ({
  tokenSSO,
  page,
}: {
  tokenSSO: string;
  page?: number;
}): Promise<AxiosResponse> =>
  apinewforms.get(`/flow/workflow-executions/`, {
    headers: {
      Authorization: `Bearer ${tokenSSO}`,
    },
    params: {
      page,
    },
  });

export const getWebhooksRequest = (tokenSSO: string): Promise<AxiosResponse> =>
  apinewforms.get(`/flow/webhooks/`, {
    headers: {
      Authorization: `Bearer ${tokenSSO}`,
    },
  });
