import { Form } from "react-bootstrap";
import styled, { css } from "styled-components";


export const BodyModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 500px;
  justify-content: center;
  `;

export const IconModal = styled.div``;

export const TitleModal = styled.h1`
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  color: #1E212A;
  margin-top: 30px;
`;

export const DescriptionModal = styled.p`
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  text-align: center;
  color: #506176;
  width: 400px;
`;

type PropsInput = {
  error: boolean;
  success: boolean;
}

export const InputCode = styled(Form.Control)<PropsInput>`
  height: 44px;
  width: 346px;
  border-radius: 4px;
  background: #F3F5FB;
  border: 1px solid #E1E6EF;
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  letter-spacing: 20px;
  margin-top: 15px;
  color: #333333;
  ${({ error }) => error && css`
    &:focus {
      outline: 0;
      background: #EC4C4714;
      border: 1px solid #EC4C47;
    };
    border: 1px solid #EC4C47;
    background: #EC4C4714;
  `}
  ${({ success }) => success && css`
    &:focus {
      outline: 0;
      background: #27AB6E14;
      border: 1px solid #27AB6E;
    };
    border: 1px solid #27AB6E;
    background: #27AB6E14;
  `}
`;

export const SendCodeMessage = styled.p`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
  margin-top: 30px;
  color: #506176;
`;

export const ButtonSendCode = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  font-weight: 500;
`;

export const MessageError = styled.p`
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  color: #EC4C47;
  margin-top: 10px;
`;

export const MessageSuccess = styled.p`
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  color: #27AB6E;
  margin-top: 10px;
`;