import React, { useEffect, useState } from "react";
import { useParams, } from "react-router-dom";
import { getRequestForId } from "../../services/requests";
import { Box, CircularProgress, } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import DefaultRequestPath from "../DefaultRequestPath";
import RequestSimplePath from "../RequestSimpleDetails";
import { setCookie } from "../../services/auth";
import getCookie from "../../utils/getCookies";

type Params = {
  identificador: string;
  enviado?: string;
};

const useStyles = makeStyles(() => ({
  main: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '50px 0px'
  }
}));

const RedirectRequest = (): JSX.Element => {
  const classes = useStyles();
  const { identificador, enviado } = useParams<Params>();
  const [currentType, setCurrentType] = useState<'Solicitação' | 'Registro' | null>(null)
  const [loading, setLoading] = useState(true);
  const sendedSolicitation = !!enviado || !!getCookie('sended_solicitation')

  useEffect(() => {
    async function getSolicitationData() {
      try {
        const { data } = await getRequestForId(identificador, undefined, sendedSolicitation || undefined);
        setCurrentType(data?.flow?.tipo);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    }

    getSolicitationData();
  }, [sendedSolicitation, identificador])

  useEffect(() => {
    if (enviado) {
      setCookie('sended_solicitation', `${!!enviado}`, 1)
    }
  }, [enviado]);

  if (loading) {
    return (
      <Box className={classes.main}>
        <CircularProgress size={50} />
      </Box>
    )
  }

  if (currentType === 'Solicitação') {
    return (
      <DefaultRequestPath
        enviado={sendedSolicitation || undefined}
        identificadorParams={identificador}
      />
    )
  } else {
    return (
      <RequestSimplePath
        identificadorParams={identificador}
      />
    )
  }
};
export default RedirectRequest;
