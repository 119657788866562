import React from 'react';
import {
  Step,
  StepLabel,
  Stepper,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Formulario } from '../../interfaces/form-flow';
import { StepIconProps } from '@material-ui/core/StepIcon';
import clsx from 'clsx';
import { MdCheck, MdFlag } from 'react-icons/md'

interface Props {
  activeStep: number;
  itens: Formulario[];
}

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: '#fff',
    border: '1px solid #ccc',
    color: '#ccc',
    padding: 5,
    textAlign: 'center',
    borderRadius: '50%',
    width: 30,
    height: 30,
  },
  active: {
    border: '1px solid #264259',
    backgroundColor: '#264259',
    color: '#fff',
  },
  completed: {
    border: '1px solid #264259',
    backgroundColor: '#264259',
    color: '#fff',
  },
});

const ColorlibStepIcon = (props: StepIconProps) => {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {completed ? <MdCheck style={{ height: 20, width: 20 }} /> : props.icon}
    </div>
  );
}

export default function StepperCard({
  activeStep,
  itens,
}: Props): JSX.Element {

  return (
    <Stepper activeStep={activeStep} alternativeLabel>
      {itens &&
        itens.map((item: Formulario, i: number) => (
          <Step key={i}>
            <StepLabel StepIconComponent={ColorlibStepIcon}></StepLabel>
          </Step>
        ))
      }
      <Step>
        <StepLabel StepIconComponent={ColorlibStepIcon} icon={<MdFlag />}></StepLabel>
      </Step>
    </Stepper>
  );
}
