/* eslint-disable no-useless-concat */
import { useRef } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { sendFile } from "../../services/files";

type SelectorProps = Array<{
  ordem: number;
  slug: string;
  titulo: string;
  campos: Array<{
    id: number;
    ordem: number;
    tipo: string;
    titulo: string;
  }>;
}>;

type Props = {
  id?: string;
  value?: string;
  setValue?: (value: string) => void;
  onChange?: (value: string) => void;
  modeEdit?: "basic" | "full";
  addPlugins?: string[];
  addConf?: object;
  addToolbar?: string[];
  disabled?: boolean;
  print?: boolean;
  selectors?: SelectorProps;
};

const MyEditor = (props: Props) => {
  const editor = useRef(null);
  const imageUploadHandle = (blobInfo, progress) =>
    new Promise(async (resolve, reject) => {
      try {
        const { data } = await sendFile(blobInfo.blob());
        if (data) {
          resolve(data.arquivo || "");
        }
      } catch (error) {
        console.log(error);
        reject("error");
      }
    });

  const getToolbar = (isprint, mode) => {
    if (isprint) {
      return ["print"];
    }
    if (mode === "full") {
      return [
        "undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl | seletores | code",
      ];
    }
    return [];
  };

  /*  const getPlugins = (modeEdit: 'basic' | 'full') => {
         const plugins = [];
         if (props.print) {
             plugins.push('print preview', 'autoresize')
         }
         if (modeEdit === 'full') {
             plugins.push('print preview paste code searchreplace directionality visualblocks visualchars image link table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons');
         }
         return plugins;
     };
  */

  const mainFixedMenu = {
    titulo: "Seletores fixos",
    campos: [
      {
        id: "numero_continuo",
        titulo: "Número continuo",
        tipo: "Texto",
      },
      {
        id: "ano_corrente",
        titulo: "Ano corrente",
        tipo: "Texto",
      },
      {
        id: "dia_mes_ano",
        titulo: "Data de hoje",
        tipo: "Data",
      },
      {
        id: "validade_solicitacao",
        titulo: "Validade da Solicitação",
        tipo: "Data",
      },
      {
        id: "protocolo",
        titulo: "Protocolo",
        tipo: "Texto",
      },
    ],
  };

  return (
    <Editor
      tinymceScriptSrc="/tinymce/js/tinymce/tinymce.min.js"
      ref={editor}
      value={props.value}
      id={props.id}
      onEditorChange={(content) => {
        props.setValue && props.setValue(content);
      }}
      init={{
        min_height: 500,
        language: "pt_BR",
        language_url: "/langs/pt_BR.js",
        fontsize_formats:
          "8pt 9pt 10pt 11pt 12pt 14pt 18pt 24pt 30pt 36pt 48pt 60pt 72pt 96pt",
        content_style: "@page { size: A4 portrait; margin: 10mm 20mm;}",
        plugins: [
          "image",
          "accordion",
          "anchor",
          "media",
          "table",
          "link",
          "autolink",
          "autoresize",
          "charmap",
          "code",
          "codesample",
          "directionality",
          "emoticons",
          "fullscreen",
          "help",
          "importcss",
          "insertdatetime",
          "link",
          "lists",
          "nonbreaking",
          "pagebreak",
          "preview",
          "quickbars",
          "searchreplace",
          "visualblocks",
          "visualchars",
          "wordcount",
        ],
        images_upload_handler: imageUploadHandle,
        convert_urls: false,
        promotion: false,
        automatic_uploads: true,
        toolbar_sticky: true,
        autoresize: true,
        toolbar: [
          ...getToolbar(props.print, props.modeEdit),
          ...(props?.addToolbar || []),
        ],
        menubar: props.print ? "" : "file edit view insert format tools table",
        image_caption: true,
        quickbars_selection_toolbar:
          "fontselect fontsizeselect formatselect |alignleft aligncenter alignright alignjustify bold italic | quicklink h2 h3 blockquote quickimage quicktable seletores",
        contextmenu: "link image imagetools table",
        quickbars_insert_toolbar: "seletores",
        external_plugins: {
          tiny_mce: "/tinymce/tinymce.min.js",
        },
        ...props.addConf,
        ...props.addToolbar,
        setup: function (editor) {
          editor.ui.registry.addMenuButton("marginsPrint", {
            text: "Margens de impressão",
            fetch: function (callback) {
              let items: any[] = [];
              callback(items);
            },
          });
          if (props.selectors) {
            editor.ui.registry.addMenuButton("seletores", {
              text: "{} Seletores",
              fetch: function (callback) {
                let items: any[] = [];
                let combinedSelectors = [mainFixedMenu, ...props.selectors];
                combinedSelectors.map((select) => {
                  if ("campos" in select) {
                    items.push({
                      type: "nestedmenuitem",
                      text: select?.titulo,
                      getSubmenuItems: () => {
                        if (select?.campos?.length > 0) {
                          return (
                            select.campos as {
                              id: number;
                              ordem: number;
                              tipo: string;
                              titulo: string;
                            }[]
                          ).map((campo) => ({
                            type: "menuitem",
                            text: campo?.titulo,
                            onAction: function () {
                              editor.insertContent(
                                "<b>${" +
                                  `${campo.id} - ${campo.titulo}` +
                                  "}</b>"
                              );
                            },
                          }));
                        } else {
                          return "";
                        }
                      },
                    });
                  }
                });
                callback(items);
              },
            });
          }
        },
      }}
      disabled={props.disabled}
    />
  );
};

export default MyEditor;
