import { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  ContainerMain,
  CardHeader,
  Title,
  FormGroupCustom,
  FormLabelCustom,
  CardFooterCustom,
} from "./style";
import { CardIcon } from "../style";
import { Button, Card, Form } from "react-bootstrap";
import { Box } from "@material-ui/core";
import { MdAddCircle, MdEdit } from "react-icons/md";
import {
  createOrganHeader,
  getHeaderById,
  updateOrganHeader,
} from "../../../services/organ";
import Loading from "../../../Components/Loading";
import CancelModal from "../../../Components/CancelModal";
import { useStateValue } from "../../../providers/StateProvider";
import { actionTypes } from "../../../store/reducer";
import MyEditor from "../../../Components/MyEditor";

const ManageHeader = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [titulo, setTitulo] = useState<string>("");
  const [conteudo, setConteudo] = useState<string>("");
  const [showMessageError] = useState<boolean>(false);
  const [showModalCancel, setShowModalCancel] = useState<boolean>(false);
  const [, dispatch] = useStateValue();
  const history = useHistory();

  const { id, orgao_id } = useParams<{ id: string; orgao_id: string }>();
  const organNumber = parseInt(orgao_id);

  const handleShowModal = (back?: boolean) => {
    if (back) {
      setShowModalCancel(!showModalCancel);
      history.goBack();
      dispatch({
        type: actionTypes.SET_FLASH_MESSAGE,
        flashMessage: {
          show: true,
          type: "error-alt",
          title: "Cancelado",
          message: "Cabeçalho não foi criado!",
        },
      });
    } else {
      setShowModalCancel(!showModalCancel);
    }
  };
  const getHeader = async () => {
    try {
      setLoading(true);
      const { data } = await getHeaderById(id, organNumber);
      if (data) {
        setTitulo(data.titulo);
        setConteudo(data.conteudo);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const manageHeader = async () => {
    if (id) {
      try {
        setLoading(true);
        await updateOrganHeader(id, organNumber, titulo, conteudo);
        history.goBack();
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
        dispatch({
          type: actionTypes.SET_FLASH_MESSAGE,
          flashMessage: {
            show: true,
            type: "success-alt",
            title: "Sucesso",
            message: "Cabeçalho atualizado",
          },
        });
      }
    } else {
      try {
        setLoading(true);
        await createOrganHeader(titulo, organNumber, conteudo);
        history.goBack();
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
        dispatch({
          type: actionTypes.SET_FLASH_MESSAGE,
          flashMessage: {
            show: true,
            type: "success-alt",
            title: "Sucesso",
            message: "Cabeçalho criado",
          },
        });
      }
    }
  };
  useEffect(() => {
    getHeader();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loading ? (
        <div className="d-flex mt-4 justify-content-center">
          <Loading />
        </div>
      ) : (
        <ContainerMain>
          <form onSubmit={(e) => e.preventDefault()}>
            <Card>
              <CardHeader>
                <Box>
                  <CardIcon>
                    {id ? <MdEdit size={20} /> : <MdAddCircle size={20} />}
                  </CardIcon>
                </Box>
                <Title>{id ? "Editar Cabeçalho" : "Criar Cabeçalho"}</Title>
              </CardHeader>
              <Card.Body>
                <FormGroupCustom className="mb-3" controlId="formBasicEmail">
                  <FormLabelCustom required>Título</FormLabelCustom>
                  <Form.Control
                    type="text"
                    value={titulo}
                    required
                    onChange={(event) => setTitulo(event.target.value)}
                  />
                  <Form.Text className="text-muted">Informe</Form.Text>
                </FormGroupCustom>
                <FormGroupCustom>
                  <FormLabelCustom required>Conteúdo</FormLabelCustom>
                  <MyEditor
                    value={conteudo}
                    setValue={setConteudo}
                    modeEdit='full'
                    addConf={{
                      height: 400,
                    }}
                  />
                  <Form.Text className="text-muted">Descreva</Form.Text>
                  {showMessageError && !conteudo && (
                    <p
                      style={{
                        color: "red",
                        fontSize: 14,
                        fontWeight: "bold",
                      }}
                    >
                      Este campo não pode ser vazio.
                    </p>
                  )}
                </FormGroupCustom>
              </Card.Body>
              <CardFooterCustom>
                <Button
                  className="button"
                  style={{
                    backgroundColor: "#fff",
                    border: "none",
                    display: "flex",
                    justifyContent: "center",
                    width: "50%",
                    padding: "15px 0",
                  }}
                  onClick={() => handleShowModal(false)}
                >
                  <span
                    style={{
                      color: "#506176",
                      fontWeight: "600",
                    }}
                  >
                    Cancelar
                  </span>
                </Button>
                <Button
                  style={{
                    backgroundColor: "#27AB6E",
                    border: "none",
                    borderRadius: 0,
                    display: "flex",
                    justifyContent: "center",
                    width: "50%",
                    padding: "15px 0",
                  }}
                  type="submit"
                  onClick={manageHeader}
                >
                  <span style={{ color: "#fff", fontWeight: "600" }}>
                    Salvar
                  </span>
                </Button>
              </CardFooterCustom>
            </Card>
          </form>
        </ContainerMain>
      )}
      <CancelModal show={showModalCancel} handleShow={handleShowModal} />
    </>
  );
};

export default ManageHeader;
