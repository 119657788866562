import React, { useEffect, useState } from "react";
import { MdVisibility } from "react-icons/md";
import Status from "../../../../Components/Status";
import { getRequestForStatus } from "../../../../services/requests";
import { CardFooterCustom, CustomTable, NoFoundData } from "../../styles";
import Loading from "../../../../Components/Loading";
import { RequestsProps } from "../../../../@types/requests";
import { cpfMask } from "../../../../utils/cpfFormat";
import StatusRequest from "../StatusRequest";
import Paginate from "../../../../Components/Paginate";
import { useHistory } from "react-router-dom";
import { CustomButton } from "./styles";
import dateFormat from "../../../../utils/dateFormat";

type Props = {
  isSearch: boolean;
  filter: string;
  dataInicial: any;
  dataFinal: any;
  search: string;
  status?:
  | "Aguardando"
  | "Solicitado"
  | "Em Andamento"
  | "Concluído"
  | "Cancelado"
  | "Restituído"
  | "Encaminhado"
  | "Cancelado por falta de resposta"
  | "Outros"
  | "Enviado";
  enviado?: boolean;
};

const RequestsList = ({
  status,
  isSearch,
  filter,
  search,
  dataInicial,
  dataFinal,
  enviado,
}: Props): JSX.Element => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [requestData, setRequestData] = useState<RequestsProps | undefined>(
    undefined
  );

  const getRequest = async ({ page }: { page: number | 1 }) => {
    setLoading(true);
    setRequestData(undefined);
    try {
      let params = {
        page,
      };

      if (filter.length && search.length) {
        params["filtro"] = filter;
        if (filter === "cpf") {
          //   alert("CPF filter");
          params["busca"] = search.replace(/\D/g, "");
        } else {
          params["busca"] = search;
        }
      }

      if (filter === "data") {
        params["filtro"] = filter;
        params["data_inicio"] = dataInicial;
        params["data_fim"] = dataFinal;
      }

      const { data } = await getRequestForStatus(status, params);
      setRequestData(data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getRequest({ page: 1 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getRequest({ page: 1 });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSearch]);

  return (
    <>
      {loading && <Loading />}

      {requestData && requestData.results.length > 0 ? (
        <CustomTable responsive>
          <thead className="table-header">
            <tr>
              <th>#</th>
              <th>Situação</th>
              <th>Protocolo</th>
              <th>Assinado</th>
              <th>Solicitado por</th>
              <th>Órgão/Setor</th>
              <th>Serviço</th>
              <th>Atendente</th>
              <th>Atualizado em</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody className="table-body">
            {requestData.results.map((request, index) => {
              const firstName =
                status !== "Solicitado"
                  ? request.historico_flow_resposta
                    ?.map((item) => item?.user?.first_name)
                    .filter(Boolean)[0] || ""
                  : request.documento_resposta[0]?.user?.first_name || "";

              return (
                <tr key={request.identificador}>
                  <td>{index + 1}</td>
                  <td>
                    <StatusRequest type={request.status} />
                  </td>
                  <td>{request.protocolo}</td>
                  <td>
                    <Status type={request.concluido.toString()} />
                  </td>
                  <td>{cpfMask(request.cpf)}</td>
                  <td>
                    {request?.setor ? (
                      <>
                        {`${request?.orgao?.sigla} / ${request?.setor.sigla}`}
                      </>
                    ) : (
                      <>{request?.orgao?.sigla}</>
                    )}
                  </td>
                  <td>
                    {request.servico_titulo
                      ? request.servico_titulo
                      : "Não Consta"}
                  </td>
                  <td>{firstName ? firstName : "Não consta"}</td>
                  <td>{dateFormat(request.updated_at, true)}</td>
                  <td>
                    <CustomButton
                      variant="outline-secondary"
                      onClick={() => {
                        history.push(
                          {
                            pathname: `/solicitacoes/detalhes/${request.identificador}/${status === 'Enviado' ? 'enviado' : ''}`,
                          }
                        );
                      }}
                    >
                      <MdVisibility size={20} className="icon" />
                    </CustomButton>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </CustomTable>
      ) : (
        <>{!loading && <NoFoundData>Sem resultados!</NoFoundData>}</>
      )}
      {requestData?.results?.length > 0 && (
        <CardFooterCustom>
          <Paginate
            flowData={requestData}
            setPage={getRequest}
            page={requestData?.current}
          />
        </CardFooterCustom>
      )}
    </>
  );
};

export default RequestsList;
