/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  Container,
  Card,
  CardContent,
  CircularProgress,
} from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { Formulario } from '../../interfaces/form-flow';
import useStyles from './styles';
import {
  getFormBySlug,
} from '../../services/flow';
import getCookie from '../../utils/getCookies';
import { FormAttendant } from '../../Components/Form/FormAttendant';

export default function Forms(): JSX.Element {
  const tokenSSO: string | null = getCookie('gov_access_token');
  const { servico_slug, id, identificador, protocolo_atendimento } = useParams<{
    servico_slug: string;
    id: string;
    identificador: string;
    protocolo_atendimento: string;
  }>();
  const [forms, setForms] = useState<Formulario[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const classes = useStyles();

  const getFormsFlow = async (slug: string) => {
    setLoading(true);
    try {
      const { data } = await getFormBySlug(tokenSSO, slug);
      if (data) {
        setForms(data.results);
      }
    } catch (err) {
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (servico_slug) {
      getFormsFlow(servico_slug);
    }
  }, [servico_slug]);

  return (
    <Container>
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        spacing={2}
      >
        <Grid item xs={12}>
          <Card className={classes.mainCard}>
            <CardContent className={classes.cardContent}>
              {loading ? (
                <Box>
                  <CircularProgress />
                </Box>
              ) : (
                <>
                  {forms?.length > 0 ? (
                    <Box>
                      <FormAttendant
                        formData={forms}
                        id={parseInt(id)}
                        identificador={identificador}
                        protocolo={protocolo_atendimento}
                      />
                    </Box>
                  ) : (
                    <h4>Sem formulários disponíveis</h4>
                  )}
                </>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}