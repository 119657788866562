import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';

import { makeStyles, Theme } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { checkCodeSignIsValid, requestCodeSign, sendCodeSignToValidate } from '../../services/signature';
import { onTermFlowAccept } from '../../services/flow';
import { hidePartOfEmail } from '../../utils/str';
import Terms from '../Terms';
import SuccessContent from '../SuccessContentForm';
import ConfirmWithoutSignature from './components/ConfirmWithoutSignature';
import getCookie from '../../utils/getCookies';

type TermsProps = {
  titulo: string;
  conteudo: string;
  versao: string;
  id: string;
}
interface Props {
  page: number;
  handleStep: (pageOrStep: number) => void;
  handleSubmit: (values: any, errors?: any) => void;
  hasTerms?: TermsProps | null;
  subscriptionType?: 'Código' | 'Automática' | null;
  typeFlow?: 'Registro' | 'Solicitação' | null;
  protocolo?: string | null;
  application?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: '#264259',
      },
    },
  },
  title: {
    fontFamily: 'Roboto',
    fontWeight: 500,
    color: '#264259',
    textTransform: 'uppercase',
    textAlign: 'center',
    fontSize: 16,
    padding: 20,
  },
  subtitle: {
    opacity: 0.6,
    textAlign: 'center',
    fontWeight: 'normal',
    fontSize: 16,
  },
  buttonForm: {
    backgroundColor: '#264259',
  },
  input: {
    color: theme.palette.primary.main,
  },
  textField: {
  },
}));

export default function SignForm({
  hasTerms,
  subscriptionType,
  typeFlow,
  protocolo,
  application
}: Props): JSX.Element {
  const tokenSSO: string | null = getCookie('gov_access_token');

  const classes = useStyles();
  const history = useHistory();
  const [valueCode, setValueCode] = useState<string>('');
  const [, setHashSign] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [stateCode, setStateCode] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [endSign, setEndSign] = useState<boolean>(false);
  const [stepLocal, setStepLocal] = useState<number>(0);
  const [loadingTerms, setLoadingTerms] = useState(false);

  const requestCode = async () => {
    const userData = JSON.parse(getCookie('gov_user_data') || '{}')
    try {
      const { data } = await requestCodeSign(userData?.cpf, userData?.contato?.email, tokenSSO);
      // b422c0706b3f44b48c8cc627f932a6c3
      setStateCode(data.state);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    const userData = JSON.parse(getCookie('gov_user_data') || '{}')
    setEmail(userData?.contato?.email)
    if (userData?.cpf && userData?.contato?.email && subscriptionType === 'Código') {
      requestCode();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscriptionType]);

  const checkSign = async (hash: string, attempts: number = 0) => {
    const MAX_ATTEMPTS = 3;
    const dataForm = JSON.parse(getCookie('gov_user_form_started') || '{}')

    try {
      setLoading(true)
      if (dataForm?.identificador) {
        const { data } = await checkCodeSignIsValid(dataForm.identificador, hash, tokenSSO);

        if (!data.validado && attempts < MAX_ATTEMPTS) {
          attempts++
          await checkSign(hash, attempts);
        }
      }

    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const sendCodetoValidate = async () => {
    try {
      const dataForm = JSON.parse(getCookie('gov_user_form_started') || '{}')

      if (valueCode && dataForm?.identificador) {
        setLoading(true);
        const { data } = await sendCodeSignToValidate({
          code: valueCode,
          state: stateCode,
          identificador: dataForm?.identificador,
          tipo: 'Solicitacao',
          internal_state: stateCode,
          client_id: process.env.REACT_APP_FORMS_CLIENT_ID ?? '',
          token: tokenSSO
        });
        if (data) {
          setHashSign(data.hash);
          checkSign(data.hash);
        }
        setEndSign(true);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      alert(error?.response?.data?.message);
      console.log(error);
    }
  }

  const handleSubmitTerms = async () => {
    const dataForm = JSON.parse(getCookie('gov_user_form_started') || '{}')

    if (!hasTerms && !dataForm) return;
    try {
      setLoadingTerms(true);
      const { data } = await onTermFlowAccept(tokenSSO, dataForm.id, hasTerms.id);
      setLoadingTerms(false);
      setStepLocal(1);
    } catch (error) {
      console.log(error);
      setLoadingTerms(false);
    }
  }

  const onFinishButton = () => {
    const dataForm = JSON.parse(getCookie('gov_user_form_started') || '{}');
    if (dataForm && dataForm?.identificador && typeFlow === 'Solicitação') {
      history.push(`/solicitacoes/detalhes/${dataForm.identificador}/visualizar`);
    } else if (dataForm && dataForm?.identificador && typeFlow === 'Registro') {
      history.push(`/solicitacoes/detalhes/${dataForm.identificador}/visualizar-simples/${protocolo}`);
    }
  }

  useEffect(() => {
    if (!hasTerms) {
      setStepLocal(1);
    }
  }, [hasTerms]);

  return (
    <Box>
      {stepLocal === 0 && hasTerms && (
        <Terms
          titulo={hasTerms.titulo}
          conteudo={hasTerms.conteudo}
          onSubmit={handleSubmitTerms}
          versao={hasTerms.versao}
          loading={loadingTerms}
        />
      )}

      {stepLocal === 1 && (
        <>
          {!endSign && subscriptionType !== 'Automática' && <>
            <Grid
              container
              direction="column"
              alignItems="center"
              justifyContent="center"
            >
              <Grid>
                <Typography variant="h6" className={classes.title}>
                  Assinatura
                </Typography>
              </Grid>
              <Grid style={{ textAlign: 'center', marginBottom: 20 }}>
                <Typography className={classes.subtitle}>
                  Assine a solicitação com o código enviado para seu email: {hidePartOfEmail(email || '')}
                </Typography>
              </Grid>

              <TextField
                id='codeValidate'
                fullWidth
                label="Digite o código de 6 dígitos"
                variant="outlined"
                className={classes.textField}
                inputProps={{
                  className: classes.input,
                }}
                onChange={(e) => setValueCode(e.target.value)}
                disabled={loading}
              />
              {loading ? <CircularProgress /> : (<>
                <Button
                  className={classes.buttonForm}
                  variant="contained"
                  fullWidth
                  color="primary"
                  type="submit"
                  style={{ marginTop: 20 }}
                  onClick={sendCodetoValidate}
                  disabled={!stateCode}
                >
                  Assinar
                </Button>
                <Button
                  style={{ color: '#0F6FB7' }}
                  variant='text'
                  onClick={requestCode}>Reenviar e-mail</Button>
              </>)}

            </Grid>
          </>}
          {!endSign && subscriptionType === 'Automática' && (
            <ConfirmWithoutSignature
              handleSetSignIn={setEndSign}
            />
          )}
        </>
      )}

      {endSign && <SuccessContent
        loading={loading}
        onFinishButton={onFinishButton}
        showButton={(application === '156') ? false : true}
      />}
    </Box>
  );
}
