import { Card, Container, Form } from "react-bootstrap";
import { MdOutlineUploadFile } from "react-icons/md";
import styled from "styled-components";

export const ContainerMain = styled(Container)`
  margin-top: 56px;
  margin-bottom: 20px;
`;

export const CardHeader = styled(Card.Header)`
  display: flex;
  background-color: ${({ theme }) => theme.colors.shape};
  align-items: center;
  padding: 20px;
`;
export const CardIcon = styled.div`
  width: 56px;
  height: 56px;
  background-color: ${({ theme }) => theme.colors.background_icon};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  color: ${({ theme }) => theme.colors.primary};
  margin-right: 16px;
`;

export const Title = styled.p`
  font-size: 24px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.secundary};
  margin: 0;
`;

export const FormGroupCustom = styled(Form.Group)`
  padding: 0px 40px;
  margin: 20px 0px;
`;

export const FormLabelCustom = styled(Form.Label)`
  &:after {
    color: ${({ theme }) => theme.colors.error};
    content: ' *';
    display: ${({ required }) => required ? 'inline' : 'none'};
  }
`;

export const CardFooterCustom = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ButtonCancel = styled.button`
  flex: 0.5;
  border: 1px solid #E1E6EF;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  color: #506176;
  height: 48px;
  background-color: #FFFFFF;
`;

export const ButtonSave = styled.button`
  flex: 0.5;
  border: 1px solid #E1E6EF;
  height: 48px;
  background-color: #27AB6E;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  color: #FFFFFF;
`;


export const DropZoneContainer = styled.div``;

export const DropZone= styled.div`
  border: 1.5px dashed #E1E6EF;
  height: 240px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const UploadIcon = styled(MdOutlineUploadFile).attrs({
  size: 40,
})`
  color: ${({ theme }) => theme.colors.primary};
`;



export const TextDropZone = styled.p`
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  margin-top: 30px;
  color: #1E212A;
  margin-bottom: 10px;
`;

export const SubTextDropZone = styled.p`
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  color: #506176;
  margin-bottom: 20px;
`;

export const ButtonDropZone = styled.button.attrs({
  type: 'button',
})`
  background: rgba(14, 134, 254, 0.08);
  padding: 5px 10px;
  color: ${({ theme }) => theme.colors.primary};
  border: 1px dotted ${({ theme }) => theme.colors.primary};
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
`;


export const DocAttached = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #E1E6EF;
  margin-top: 20px;
  padding: 0;


`;

export const AttachedUploadIcon = styled(MdOutlineUploadFile).attrs({
  size: 30,
})`
  color: ${({ theme }) => theme.colors.primary};
  margin-right: 10px;
`;


export const TextDocAttached = styled.p`

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  padding-top: 20px;
  padding-left: 20px;

`;

export const RemoveAttached = styled.button.attrs({
  type: 'button',
})`
  border: none;
  background-color: transparent;
  border-left: 1px solid #E1E6EF;
  width: 50px;

`;

