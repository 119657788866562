import { Form } from 'react-bootstrap';
import styled from 'styled-components';

export const FormGroupCustom = styled(Form.Group)`
  padding: 0px 40px;
  margin: 20px 0px;
`;

export const FormLabelCustom = styled(Form.Label)`
  &:after {
    color: ${({ theme }) => theme.colors.error};
    content: ' *';
    display: ${({ required }) => (required ? 'inline' : 'none')};
  }
`;

export const ContainerList = styled.div`
  display: flex;
`;

export const MainOption = styled.ul`
  margin: 10px 0px;
`;

export const OptionList = styled.li`
  margin: 5px 0px;
  font-size: 14px;
  strong {
    margin-right: 8px;
  }
`;
