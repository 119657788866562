import React from "react";

import { Container, CircleBox, Title } from './styles';

type TypeProps = "Aguardando" | "Solicitado" | "Em Andamento" | "Concluído" | "Cancelado" | "Restituído";
interface StatusProps {
  type: TypeProps;
}

const StatusRequest = ({ type }: StatusProps) => {
  return (
    <Container>
      <CircleBox type={type} />
      <Title type={type}>{type}</Title>
    </Container>
  );
}

export default StatusRequest;