import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { MdPassword } from 'react-icons/md';
import { ButtonCancel, ButtonSave, CardFooterCustom, CardIcon } from '../../styles';
import {
    BodyModal,
    ButtonSendCode,
    DescriptionModal,
    InputCode,
    MessageError,
    MessageSuccess,
    SendCodeMessage,
    TitleModal
} from './styles';


type Props = {
    show: boolean;
    setShow: (show: boolean) => void;
    handleSave: (code: string) => void;
    email?: string;
    sendEmail?: () => void;
    hasValidCode: boolean;
    setHasValidCode: (value: boolean) => void;
};

const ModalCodeValidate = ({ show, setShow, handleSave, email, sendEmail, hasValidCode, setHasValidCode }: Props): JSX.Element => {
    const [code, setCode] = useState<string>('');
    const handleClose = () => setShow(false);
    const [errorInput, setErrorInput] = useState<boolean>(false);
    const [successInput, setSuccessInput] = useState<boolean>(false);

    const handleAssignCode = (e) => {
        const value: string = e.target.value.replace(/\D/g, '');
        setCode(value);
        if (value.length !== 6) {
            setErrorInput(true);
            setSuccessInput(false);
        } else {
            setErrorInput(false);
            setSuccessInput(true);
            setHasValidCode(false);
        }
    }

    return (
        <>
            <Modal size='lg' show={show} centered>
                <Modal.Body>
                    <BodyModal>
                        <CardIcon>
                            <MdPassword />
                        </CardIcon>
                        <TitleModal>Assinatura eletrônica</TitleModal>
                        <DescriptionModal>Assine a tramitação com o código enviado para seu email: <strong>{email}</strong></DescriptionModal>
                        <InputCode placeholder='000000' maxLength={6} value={code}
                            onChange={handleAssignCode}
                            error={(errorInput || hasValidCode)}
                            success={(successInput && !hasValidCode)}
                        />
                        {(errorInput || hasValidCode) && <MessageError>Código expirado ou inexistente</MessageError>}

                        {!hasValidCode && successInput && <MessageSuccess>Verificado</MessageSuccess>}
                        <SendCodeMessage>Não recebeu o código? <ButtonSendCode onClick={sendEmail}>Reenviar</ButtonSendCode></SendCodeMessage>

                    </BodyModal>
                </Modal.Body>
                <CardFooterCustom>
                    <ButtonCancel onClick={handleClose}>
                        Cancelar
                    </ButtonCancel>
                    <ButtonSave onClick={() => handleSave(code)} disabled={!successInput}>
                        Finalizar
                    </ButtonSave>
                </CardFooterCustom>
            </Modal>
        </>
    );
};

export default ModalCodeValidate;