import { MdBlock } from "react-icons/md";
import {
  CustomFooter,
  BoxWarning,
  CardIcon,
  CustomModal,
  TitleWarning,
  Warning,
  CustomBody,
  ButtonDeny,
  ButtonConfirmed,
} from "./style";
interface Props {
  show: boolean;
  handleShow: (del?:boolean) => void;
}

const DeleteModal = ({ show, handleShow }: Props) => {
  return (
    <CustomModal show={show} onHide={handleShow} centered>
      <CustomBody>
        <BoxWarning>
          <CardIcon>
            <MdBlock size={20} />
          </CardIcon>
          <TitleWarning>Excluir cabeçalho</TitleWarning>
          <Warning>
            <span>Tem certeza de que deseja excluir este cabeçalho?</span>{" "}
            <span>Essa ação não pode ser desfeita.</span>
          </Warning>
        </BoxWarning>
      </CustomBody>
      <CustomFooter>
        <ButtonDeny onClick={()=> handleShow(false)}>Não</ButtonDeny>
        <ButtonConfirmed onClick={()=> handleShow(true)}>Sim</ButtonConfirmed>
      </CustomFooter>
    </CustomModal>
  );
};

export default DeleteModal;
