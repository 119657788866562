import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Avatar as MdAvatar } from '@material-ui/core';
import { FaUserCircle } from 'react-icons/fa';
import { IoMdPower } from 'react-icons/io';
import { MdClose } from 'react-icons/md';

import {
  Container as BContainer,
  Navbar,
  NavDropdown,
  Button,
  Dropdown,
  Offcanvas,
} from 'react-bootstrap';

export const Container = styled.div`
  width: 100%;
`;

export const CustomNavBar = styled(Navbar).attrs(({ theme }) => ({
  expand: 'lg',
  variant: 'dark',
}))`
  background-color: ${({ theme }) => theme.colors.secundary};
`;
export const NavMenu = styled.nav`
  @media screen and (max-width: 991px) {
    display: none;
  }
`;
export const MenuSideBar = styled.div`
  display: flex;
  flex-direction: column;
`;
export const Content = styled(BContainer)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const BoxActions = styled.div`
  display: flex;
  align-items: center;
`;

export const BoxLogo = styled(Navbar.Brand)`
  font-family: 'Roboto';
  font-size: 24px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.shape};
  letter-spacing: 0.3px;
`;
export const ItemMenu = styled(Link)`
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.3px;
  color: ${({ theme }) => theme.colors.shape} !important;
  text-decoration: none;
  margin: 0 25px;
  &:hover {
    cursor: pointer;
    color: ${({ theme }) => theme.colors.shape};
  }
`;
export const BoxUserData = styled(NavDropdown)`
  /* display: flex;
  align-items: center;
  justify-content: space-between; */
`;
export const ButtonLogin = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  text-transform: uppercase;
  border: 2px solid #fff !important;
  border-radius: 3px;
  background-color: transparent !important;
  box-shadow: none !important;

  @media screen and (max-width: 600px) {
    padding: 5px;
  }
`;
export const IconUser = styled(FaUserCircle)`
  width: 20px;
  height: 20px;
  margin-right: 4px;
`;
export const ButtonDropDown = styled(Dropdown.Toggle)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
`;
export const MenuDropDown = styled(Dropdown.Menu)`
  margin-top: 8px !important;
  left: -25px !important;
  @media screen and (max-width: 768px) {
    left: auto !important;
  }
`;
export const BoxUserButton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 5px;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;
export const BoxUser = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
export const TextUser = styled.p`
  font-size: 14px;
  margin: 0;
  font-weight: 500;
  text-transform: capitalize;
`;
export const TextCpf = styled.p`
  font-size: 12px;
  margin: 0;
  font-weight: 400;
`;
export const Avatar = styled(MdAvatar)`
  width: 32px;
  height: 32px;
  border: 1px solid ${({ theme }) => theme.colors.shape};
  margin-right: 10px;
`;
export const IconLogout = styled(IoMdPower)`
  color: ${({ theme }) => theme.colors.error};
  width: 16px;
  height: 16px;
  margin-right: 10px;
`;
export const ButtonLogout = styled(Dropdown.Item)`
  color: ${({ theme }) => theme.colors.error};
  font-size: 14px;
  font-weight: 400;
  background-color: ${({ theme }) => theme.colors.shape};
  &:hover {
    color: ${({ theme }) => theme.colors.error};
    background-color: ${({ theme }) => theme.colors.shape};
  }
  &:active {
    color: ${({ theme }) => theme.colors.error};
    background-color: transparent;
  }
`;
export const SideBar = styled(Offcanvas)`
  width: 320px;
`;
export const CloseSideBar = styled(MdClose)`
  color: ${({ theme }) => theme.colors.secundary};
  width: 30px;
  height: 30px;
  cursor: pointer;
`;
export const TitleSideBar = styled(Offcanvas.Title)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: 'Roboto';
  margin: 20px;
  color: ${({ theme }) => theme.colors.secundary};
`;
export const ItemMenuSidebar = styled(Link)`
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.3px;
  color: ${({ theme }) => theme.colors.secundary};
  text-decoration: none;
  margin: 16px 30px;
  &:hover {
    cursor: pointer;
    color: ${({ theme }) => theme.colors.secundary};
  }
`;
export const BoxNotification = styled.div`
  margin-left: 20px;
  margin-right: 20px;
  position: relative;
`;
export const LabelNotification = styled.div`
  background-color: ${({ theme }) => theme.colors.primary};
  position: absolute;
  z-index: 1;
  top: -10px;
  margin-left: 20px;
  width: 20px;
  height: 20px;
  text-align: center;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.shape};
  border-radius: 50%;
`;

export const NameUserOutMenu = styled.p`
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 12;
  color: ${({ theme }) => theme.colors.shape};
`;

export const CPFUserOutMenu = styled.p`
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 12;
  color: ${({ theme }) => theme.colors.shape};
`;
export const NameUserInMenu = styled.p`
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 12;
  color: ${({ theme }) => theme.colors.text};
`;

export const CPFUserInMenu = styled.p`
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 12;
  color: ${({ theme }) => theme.colors.text};
`;
