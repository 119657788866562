import { Box, Button, CircularProgress, Typography } from "@material-ui/core";
import { Theme, makeStyles } from '@material-ui/core/styles';
import { onConfirmWithoutSignature } from "../../../../services/flow";
import { useEffect, useState } from "react";
import { Info } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import getCookie from "../../../../utils/getCookies";

interface ConfirmWithoutSignatureProps {
  handleSetSignIn: (value: boolean) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%'
  },
  text: {
    fontSize: theme.typography.pxToRem(18),
    color: '#333',
    fontWeight: 400,
    fontFamily: 'Roboto',
  },
  buttonForm: {
    backgroundColor: '#264259',
  },
}));

export default function ConfirmWithoutSignature({
  handleSetSignIn,
}: ConfirmWithoutSignatureProps): JSX.Element {
  const [loading, setLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const classes = useStyles();
  const history = useHistory();

  const onReturnProcess = () => {
    history.go(0);
  }

  const handleOnConfirmWithoutSignature = async () => {
    const tokenSSO: string | null = getCookie('gov_access_token');
    const user = JSON.parse(getCookie('gov_user_data') || '{}');
    const formDataUser = JSON.parse(getCookie('gov_user_form_started') || '{}');

    if (!user || !formDataUser) return;
    try {

      const { data, status } = await onConfirmWithoutSignature(
        tokenSSO,
        formDataUser?.identificador,
        user?.cpf,
        'Solicitação',
        formDataUser?.identificador
      );
      if (data && status === 201) {
        handleSetSignIn(true);
      }
    } catch (error) {
      setHasError(true);
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    handleOnConfirmWithoutSignature();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box className={classes.main}>
      {(hasError && !loading) ? (
        <>
          <Info />
          <Typography className={classes.text}>Algo deu errado!</Typography>
          <Button
            className={classes.buttonForm}
            variant="contained"
            fullWidth
            color="primary"
            type="submit"
            style={{ marginTop: 20 }}
            onClick={onReturnProcess}
          >
            Tentar novamente
          </Button>
        </>
      ) : (
        <>
          <CircularProgress />
          <Typography className={classes.text}>Um momento por favor...</Typography>
        </>
      )}
    </Box>
  )
}