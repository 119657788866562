import { AxiosResponse } from 'axios';
import { apisso } from './api';

export const requestCodeSign = (
  cpf: string,
  email: string,
  token?: string,
): Promise<AxiosResponse> =>
  apisso.post(
    '/admin/assinatura/code/',
    {
      cpf,
      email,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

type AssinaturaProps = {
  code: string;
  state: string;
  identificador: string;
  tipo: string;
  internal_state: string;
  client_id: string;
  token?: string;
};

export const sendCodeSignToValidate = (
  params: AssinaturaProps
): Promise<AxiosResponse> =>
  apisso.post('/admin/assinatura/validar_codigo/', {
    client_id: params.client_id,
    code: params.code,
    identificador: params.identificador,
    internal_state: params.internal_state,
    state: params.state,
    tipo: params.tipo,
  }, {
    headers: {
      Authorization: `Bearer ${params.token}`,
    },
  });

export const checkCodeSignIsValid = (
  identificador: string,
  hash: string,
  token: string
): Promise<AxiosResponse> =>
  apisso.post(
    '/admin/assinatura/checar/',
    {
      identificador,
      hash,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
