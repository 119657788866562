import { useEffect, useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { getUserData, setCookie } from "../../services/auth";

export default function RedirectForm() {
  const history = useHistory();
  function useQuery() {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
  }
  const query = useQuery();

  async function handleGetUserData(token: string) {
    try {
      const data = await getUserData(token);
      return data;
    } catch (error) {
      console.log(error);
    }
  }

  const handleSetData = async () => {
    if (
      query.get("slug_servico") &&
      query.get("token") &&
      query.get("cpf_cidadao")
    ) {
      const data = await handleGetUserData(query.get("token"));
      if (data) {
        setCookie("gov_user_data", JSON.stringify(data), 1);
        setCookie("gov_access_token", query.get("token"), 1);
        let urlRedirect = new URL(`${process.env.REACT_APP_URL}/formularios/`);
        urlRedirect.searchParams.set(
          "slug_servico",
          query.get("slug_servico") || ""
        );
        urlRedirect.searchParams.set("aplicacao", query.get("aplicacao") || "");
        urlRedirect.searchParams.set(
          "protocolo_atendimento",
          query.get("protocolo_atendimento") || ""
        );
        urlRedirect.searchParams.set(
          "cpf_cidadao",
          query.get("cpf_cidadao") || ""
        );
        urlRedirect.searchParams.set("nome", query.get("nome") || "");
        urlRedirect.searchParams.set("cpf", query.get("cpf") || "");
        urlRedirect.searchParams.set("email", query.get("email") || "");
        urlRedirect.searchParams.set("telefone", query.get("telefone") || "");
        window.location.href = urlRedirect.toString();
      }
    }
  };

  useEffect(() => {
    handleSetData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, query]);

  return <></>;
}
