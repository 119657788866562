import { Button } from "react-bootstrap";
import styled from "styled-components";

export const Box = styled.div`
  display: flex;
  align-items: center;
`;

export const CustomButton = styled(Button)`
  background-color: none;
  border-color: 'rgb(225, 230, 239)';
  color: ${({ theme }) => theme.colors.text_secundary};
  :hover{
      background-color: #6c757d !important;
      border-color: #6c757d !important;
    }

    :active{
      background-color: #6c757d !important;
      border-color: #6c757d !important;
    }
    :focus{
      background-color: #6c757d !important;
      border-color: #6c757d !important;
    }

    :hover> .icon{
      color: #fff;
    }
    
    :active{
      color: #fff !important;
    }

    :focus > .icon{
      color: #fff !important;
    }
`