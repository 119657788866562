import { UserData } from '../interfaces/user';

interface InitState {
  user: null | UserData;
  flashMessage: null | {
    show: boolean;
    type: 'success' | 'success-alt' | 'error' | 'error-alt';
    title: string;
    message: string;
  };
}

export const initialState: InitState = {
  user: null,
  flashMessage: null,
};

export const actionTypes = {
  SET_USER: 'SET_USER',
  SET_FLASH_MESSAGE: 'SET_FLASH_MESSAGE',
};

const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.SET_USER:
      return {
        ...state,
        user: action.user,
      };
    case actionTypes.SET_FLASH_MESSAGE:
      return {
        ...state,
        flashMessage: action.flashMessage,
      };
    default:
      return state;
  }
};

export default reducer;
