import React from 'react';
import {
  OutlinedInput,
  FormControl,
  InputLabel,
  FormHelperText,
} from '@material-ui/core';
import { InputProps } from '@material-ui/core/Input';
import { makeStyles, Theme } from '@material-ui/core/styles';
import InputMask from 'react-input-mask';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: '#264259',
      },
    },
  },
  labelRoot: {
    backgroundColor: '#fff',
  },
  inputColor: {
    color: theme.palette.primary.main,
  },
  control: {
    marginBottom: theme.spacing(1),
  }
}));

interface TextMaskCustomProps {
  inputRef: (ref: HTMLInputElement | null) => void;
}

interface TextPhoneProps {
  label: string;
  mask?: string;
  helperText?: string;
}

const InputMaskPhone: React.FC<TextMaskCustomProps & TextPhoneProps> = (props) => {
  const { inputRef, ...other } = props;

  return <InputMask {...other} mask={`(99) 99999-9999`} maskChar=" " />;
}

const Phone: React.FC<InputProps & TextPhoneProps> = (props) => {
  const { id, value, required, helperText, ...rest } = props;
  const classes = useStyles();

  return (
    <FormControl
      fullWidth
      variant="outlined"
      className={classes.control}
    >
      <InputLabel
        classes={{ root: classes.labelRoot }}
        required={required}
        shrink={value ? true : false}
        htmlFor={id}
      >
        {props.label}
      </InputLabel>
      <OutlinedInput
        inputProps={{
          className: classes.inputColor,
        }}
        name={`${props.id}-phone`}
        id={id}
        value={value}
        required={required}
        inputComponent={InputMaskPhone as any}
        {...rest}
        autoComplete='off'
      />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
}

export default Phone;
