import { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Dropdown from "react-bootstrap/Dropdown";
import { MultiSelectSectorProps, Sector } from "./types";
import { CircularProgress } from "@material-ui/core";

const MultiSelectSector = ({
  data,
  preSetSectors,
  onSelectedSectors,
}: MultiSelectSectorProps) => {
  const [selectedSectors, setSelectedSectors] = useState<Sector[]>([]);
  const [selectAll, setSelectAll] = useState(false);
  const [isDataLoaded, setIsDataLoaded] = useState(false);

  useEffect(() => {
    if (data.length > 0) {
      const preSet = data.filter((sector) => preSetSectors.includes(sector.id));
      setSelectedSectors(preSet);
      setSelectAll(preSet.length === data.length);
      setIsDataLoaded(true);
    }
  }, [data, preSetSectors]);

  const handleOnChange = (sector: Sector) => {
    if (!isDataLoaded) return;

    if (selectedSectors.includes(sector)) {
      setSelectedSectors(
        selectedSectors.filter((sectorId) => sectorId !== sector)
      );
      setSelectAll(false);
    } else {
      setSelectedSectors([...selectedSectors, sector]);
    }
  };

  const handleSelectAllChange = () => {
    if (!isDataLoaded) return;

    if (selectAll) {
      setSelectedSectors([]);
    } else {
      setSelectedSectors(data);
    }
    setSelectAll(!selectAll);
  };

  useEffect(() => {
    onSelectedSectors(selectedSectors);
  }, [selectedSectors]);

  return (
    <Dropdown style={{ width: "100%" }}>
      <Dropdown.Toggle
        disabled={!isDataLoaded}
        id="dropdown-basic"
        style={{
          width: "100%",
          backgroundColor: "#f8f9fa",
          color: "#000",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          border: "1px solid #ced4da",
        }}
      >
        {!isDataLoaded ? (
          <CircularProgress />
        ) : selectedSectors.length > 0 ? (
          `${selectedSectors.length} selecionados`
        ) : (
          "Ver todos"
        )}
      </Dropdown.Toggle>
      <Dropdown.Menu style={{ width: "100%", padding: "10px" }}>
        <Form.Check
          type="checkbox"
          label="Marcar Todos"
          id="checkbox-select-all"
          onChange={handleSelectAllChange}
          checked={selectAll}
          disabled={!isDataLoaded}
        />
        {data.map((sector) => (
          <Form.Check
            key={sector.id}
            type="checkbox"
            label={sector.nome}
            id={`checkbox-${sector.id}`}
            onChange={() => handleOnChange(sector)}
            checked={selectedSectors.includes(sector)}
            disabled={!isDataLoaded}
          />
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default MultiSelectSector;
