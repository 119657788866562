import { useHistory, useParams } from "react-router-dom";
import { useStateValue } from "../../providers/StateProvider";
import { Fragment, useCallback, useEffect, useState } from "react";
import { createWorkflowByFlowId, getWorkflowByFlowId, updateWorkflowByFlowId } from "../../services/flow";
import getCookie from "../../utils/getCookies";
import { getSelectorsByFlow } from "../../services/field";

import { Button, Card, Col, Dropdown, Form } from "react-bootstrap";
import { MdRemoveCircle, MdSettings } from "react-icons/md";
import {
  ContainerMain,
  CardHeader,
  Title,
  CustomRow,
  FormLabel,
  Box,
  CardIcon,
  CardFooterCustom,
  ActionButton,
} from "../Information/components/ManageForm/styles";
import { actionTypes } from "../../store/reducer";
import { ContainerList, FormLabelCustom, MainOption, OptionList } from "./styles";

const dataTypeList = [{
  id: 0,
  value: 'Entrada',
}, {
  id: 1,
  value: 'Saída'
}]

export default function ManageWorkflow(): JSX.Element {
  const tokenSSO: string | null = getCookie('gov_access_token');
  const history = useHistory();
  const { flow_slug, workflow_id } = useParams<{ flow_slug: string; workflow_id?: string }>();
  const [, dispatch] = useStateValue();
  const [loading, setLoading] = useState<boolean>(false);
  const [selectors, setSelectors] = useState([]);
  const [showMessageError, setShowMessageError] = useState<boolean>(false);
  const [mapa, setMapa] = useState<any>([
    {
      chave: "",
      valor: "",
    },
  ]);
  const [workflow, setWorkflow] = useState<any>({
    descricao: "",
    titulo: "",
    url: "",
    credencial: null,
    flow: flow_slug,
    tipo: "",
    mapa: {},
  });


  const getWorkflow = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await getWorkflowByFlowId({ tokenSSO, workflow_id });
      if (data) {
        const workflowData = data;
        setWorkflow({
          id: workflowData.id,
          titulo: workflowData.titulo,
          descricao: workflowData.descricao,
          url: workflowData.url,
          credencial: workflowData.credencial,
          flow: workflowData.flow,
          tipo: workflowData.tipo,
        });
        const mapaFormatted = Object.entries(workflowData.mapa).map(([chave, valor]) => ({
          chave,
          valor,
        }));
        setMapa(mapaFormatted);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [tokenSSO, workflow_id]);


  const getAllSelectors = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await getSelectorsByFlow(flow_slug, true);
      setSelectors(data);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  }, [flow_slug]);

  const updateWorkflow = async (workflowData) => {
    const mapaFormatted = Object.fromEntries(mapa.map((item) => [item.chave, item.valor]));
    await updateWorkflowByFlowId(
      {
        tokenSSO,
        id: workflowData.id
      },
      { ...workflowData, mapa: mapaFormatted }
    );
  };

  const createWorkflow = async (workflowData) => {
    const mapaFormatted = Object.fromEntries(mapa.map((item) => [item.chave, item.valor]));
    await createWorkflowByFlowId(tokenSSO, {
      ...workflowData,
      mapa: mapaFormatted,
    });
  };


  const handleSave = async (e) => {
    e.preventDefault();
    if (workflow?.tipo?.length === 0) {
      setShowMessageError(true);
      return;
    }
    try {

      if (workflow.id) {
        await updateWorkflow(workflow);
      } else {
        await createWorkflow(workflow);
      }

      dispatch({
        type: actionTypes.SET_FLASH_MESSAGE,
        flashMessage: {
          show: true,
          type: "success-alt",
          title: "Sucesso",
          message: "Workflow salvo com sucesso!",
        },
      });

      history.push(`/informacoes/${flow_slug}`);
    } catch (err) {
      if (err?.response?.status === 400) {
        dispatch({
          type: actionTypes.SET_FLASH_MESSAGE,
          flashMessage: {
            show: true,
            type: "error-alt",
            title: "Erro",
            message: err?.response?.data?.detail || 'Erro ao salvar workflow! Tente novamente',
          },
        });
      } else {
        dispatch({
          type: actionTypes.SET_FLASH_MESSAGE,
          flashMessage: {
            show: true,
            type: "error-alt",
            title: "Erro",
            message: "Erro ao salvar workflow! Tente novamente",
          },
        });
      }
      console.error(err);
    }
  };

  useEffect(() => {
    async function getDataWorkflow() {
      await getWorkflow();
    }

    if (workflow_id) {
      getDataWorkflow();
    }
  }, [getWorkflow, workflow_id])

  useEffect(() => {
    async function getSelectors() {
      await getAllSelectors();
    }

    if (flow_slug) {
      getSelectors();
    }
  }, [flow_slug, getAllSelectors])

  console.log(selectors, 'mapa')

  return (
    <ContainerMain>
      <form onSubmit={handleSave}>
        <Card>
          <CardHeader>
            <Box>
              <CardIcon>
                <MdSettings size={20} />
              </CardIcon>
            </Box>
            <Title>{"Gerenciar Workflow"}</Title>
          </CardHeader>

          <Card style={{ border: 0, padding: "40px 40px 0px 40px" }}>
            <CustomRow>
              <Col xs={12} md={12}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <FormLabel>Título</FormLabel>
                  <Form.Control
                    disabled={loading}
                    required
                    value={workflow.titulo}
                    onChange={(event) => setWorkflow({ ...workflow, titulo: event.target.value })}
                  />
                  <Form.Text className="text-muted">Informe o título.</Form.Text>
                </Form.Group>
              </Col>
            </CustomRow>

            <CustomRow>
              <Col xs={12} md={12}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <FormLabel>Descrição</FormLabel>
                  <Form.Control
                    disabled={loading}
                    required
                    value={workflow.descricao}
                    onChange={(event) => setWorkflow({ ...workflow, descricao: event.target.value })}
                  />
                  <Form.Text className="text-muted">Informe o Descrição.</Form.Text>
                </Form.Group>
              </Col>
            </CustomRow>

            <CustomRow>
              <Col xs={12} md={12}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <FormLabel>URL</FormLabel>
                  <Form.Control
                    disabled={loading}
                    required
                    value={workflow.url}
                    onChange={(event) => setWorkflow({ ...workflow, url: event.target.value })}
                  />
                  <Form.Text className="text-muted">Informe a url de integração.</Form.Text>
                </Form.Group>
              </Col>
            </CustomRow>
            <CustomRow>
              <Col xs={12} md={12}>
                <FormLabelCustom required>
                  Tipo
                </FormLabelCustom>
                <Form.Select
                  required
                  onChange={(e) => {
                    const findType = dataTypeList?.find((type) => type.value === e.target.value);
                    if (findType) {
                      setShowMessageError(false);
                      setWorkflow({ ...workflow, tipo: findType.value });
                    }
                  }}
                >
                  <option value="none">Selecione</option>
                  {dataTypeList?.map((type) => (
                    <option
                      selected={type.value === workflow.tipo}
                      value={type.value}>{type.value}</option>
                  ))}
                </Form.Select>
                <Form.Text className="text-muted">Informe o tipo de integração.</Form.Text>
                {showMessageError && !workflow?.tipo ? (
                  <p
                    style={{
                      color: "red",
                      fontSize: 14,
                      fontWeight: "bold",
                    }}
                  >
                    Este campo não pode ser vazio.
                  </p>
                ) : (
                  <ContainerList>
                    <MainOption>
                      <OptionList>
                        <strong>
                          Saída:
                        </strong>
                        O workflow é executado quando a solicitação é enviada.
                      </OptionList>
                      <OptionList>
                        <strong>
                          Entrada:
                        </strong>
                        O workflow é executado antes do preenchimento da solicitação.
                      </OptionList>
                    </MainOption>
                  </ContainerList>
                )}
              </Col>
            </CustomRow>
            <CustomRow>
              <Form.Group className="mb-3">
                <FormLabel>Mapa</FormLabel>
                <Form.Text className="text-muted">
                  O mapa define os campos que serão utilizados na integração
                </Form.Text>
                {mapa.map((map, index) => (
                  <CustomRow key={index.toString()}>
                    <Col md={4}>
                      <FormLabel>Chave</FormLabel>
                      <Form.Control
                        disabled={loading}

                        required
                        value={map.chave}
                        onChange={(event) => {
                          const newMapa = [...mapa];
                          newMapa[index].chave = event.target.value;
                          setMapa(newMapa);

                        }}
                      />
                    </Col>
                    <Col md={6}>
                      <CustomRow>
                        <Col md={8}>
                          <FormLabel>Valor</FormLabel>
                          <Form.Control disabled={loading} required value={map.valor}
                            onChange={(event) => {
                              const newMapa = [...mapa];
                              newMapa[index].valor = event.target.value;
                              setMapa(newMapa);
                            }}
                          />
                        </Col>
                        <Col md={4} >
                          <Dropdown style={{
                            marginTop: 30,
                          }}>
                            <Dropdown.Toggle id="dropdown-basic" disabled={loading}>
                              Campos formulário
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              {selectors.map((item) => (
                                <Fragment key={item?.slug}>
                                  <Dropdown.Item disabled>Formulário: {item?.titulo || ""}</Dropdown.Item>
                                  {item?.campos?.map((campo) => (
                                    <Dropdown.Item onClick={() => {
                                      const newMapa = [...mapa];
                                      newMapa[index].valor = "${" + `${campo.id} - ${campo.titulo}` + "}";
                                      setMapa(newMapa);
                                    }} key={campo?.id}>
                                      {campo?.titulo || ""}
                                    </Dropdown.Item>
                                  ))}
                                </Fragment>
                              ))}
                            </Dropdown.Menu>
                          </Dropdown>
                        </Col>
                      </CustomRow>
                    </Col>
                    <Col >
                      {mapa.length > 1 && (<Button
                        variant="danger"
                        onClick={() => {
                          setMapa(mapa.filter((item, i) => i !== index));
                        }}
                        style={{
                          marginTop: 30,
                        }}
                      >
                        <MdRemoveCircle /> Remover
                      </Button>)}

                    </Col>
                  </CustomRow>
                ))}
                <CustomRow>
                  <Col>
                    <Button
                      variant="primary"
                      onClick={() => {
                        setMapa([
                          ...mapa,
                          {
                            chave: "",
                            valor: "",
                          },
                        ]);
                      }}
                      disabled={loading}
                    >
                      Adicionar campo ao mapa
                    </Button>
                  </Col>
                </CustomRow>
              </Form.Group>
            </CustomRow>
          </Card>
          <CardFooterCustom>
            <ActionButton
              className="button"
              style={{ backgroundColor: "#fff", color: "#506176" }}
              onClick={() => history.push(`/informacoes/${flow_slug.trim()}`)}
            >
              <span style={{ marginLeft: 8 }}>Cancelar</span>
            </ActionButton>
            <ActionButton style={{ backgroundColor: "#27AB6E" }} type="submit">
              <span style={{ marginLeft: 8 }}>Salvar</span>
            </ActionButton>
          </CardFooterCustom>
        </Card>
      </form >
    </ContainerMain>
  )
}