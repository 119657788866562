import { Button, Card, Container, Form, Row } from 'react-bootstrap';
import styled from 'styled-components';

export const ContainerMain = styled(Container)`
  margin-top: 56px;
`;

export const CardHeader = styled(Card.Header)`
  display: flex;
  background-color: ${({ theme }) => theme.colors.shape};
  align-items: center;
  padding: 30px;
`;
export const CardBody = styled(Card.Body)`
  padding: 40px;
  @media screen and (max-width: 999px) {
    padding: 20px;
  } ;
`;
export const Title = styled.p`
  font-size: 24px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.secundary};
  margin: 0 0 0 10px;
`;
export const FormLabel = styled(Form.Label)`
  :after {
    content: ' *';
    color: ${({ theme }) => theme.colors.error};
  }
`;
export const CustomRow = styled(Row)`
  margin-bottom: 30px;
`;
export const CustomButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  @media screen and (max-width: 767px) {
    justify-content: center;
  }
`;

export const Box = styled.div`
  display: flex;
  align-items: center;
`;

export const CardIcon = styled.div`
  width: 56px;
  height: 56px;
  background-color: ${({ theme }) => theme.colors.background_icon};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  color: ${({ theme }) => theme.colors.primary};
  margin-right: 16px;
`;

export const CardFooterCustom = styled(Card.Footer)`
  background-color: ${({ theme }) => theme.colors.shape};
  display: flex;
  justify-content: center;
  padding: 0px;
`;

export const ActionButton = styled(Button)`
  border: none;
  border-radius: 0px;
  display: flex;
  justify-content: center;
  width: 50%;
  padding: 15px 0;
`;
