import { useHistory, useParams } from "react-router-dom";
import { Button, Card, Form, ListGroup, Modal, Spinner } from "react-bootstrap";

import { MdSettings } from "react-icons/md";
import { CardIcon, ContainerMain } from "../Flow/styles";
import { Title, CardHeader } from "../Flow/components/ManageFlow/styles";
import { Box, Checkbox } from "@material-ui/core";
import { RiCloseCircleFill } from "react-icons/ri";
import { useCallback, useEffect, useRef, useState } from "react";
import {
  deleteField,
  getFields,
  postField,
  updateField,
} from "../../services/field";
import { useStateValue } from "../../providers/StateProvider";
import { actionTypes } from "../../store/reducer";
import CancelModal from "../../Components/CancelModal";

import {
  ButtonHeader,
  CustomFormControlLabel,
  FormLabelCustom,
} from "./styles";
import { schema } from "./validation";
import { getMaxNumberInData } from "../../utils/getMaxNumberInData";
import { Alert } from "@material-ui/lab";

type DataField = {
  id?: number;
  ordem: string;
  titulo: string;
  texto_interno: string;
  formulario: string;
  mascara: number | string;
  validador: string;
  tipo: string;
  texto_ajuda: string;
  obrigatorio: boolean;
  alternativas: any;
  tipo_alternativa?: string;
  limite_replicacoes?: number;
  tipo_anexo: null | string;
  editavel: boolean;
  titulo_condicional?: string | null;
  tamanho_campo: number;
  anexo_confidencial?: boolean;
  tipo_arquivo: string | null;
  tipo_regiao_cep_permitida?: "Salvador" | "Bahia";
  exibir_mapa: boolean;
};

const Build = () => {
  const isMounted = useRef(true);
  const history = useHistory();
  const { form_slug, flow_slug } = useParams<{
    form_slug: string;
    flow_slug: string;
  }>();
  const [tipoCampo, setTipoCampo] = useState("Texto");
  const [, setShowOptionsOne] = useState(false);
  const [showOptionsTwo, setShowOptionsTwo] = useState(false);
  const [loading, setLoading] = useState(false);
  const [hasConditional, setHasConditional] = useState(false);

  const [, dispatch] = useStateValue();

  const [selectedId, setSelectedId] = useState<number | undefined>(undefined);

  const intialState: DataField = {
    ordem: "1",
    titulo: "",
    texto_interno: "",
    formulario: form_slug,
    mascara: "",
    validador: "",
    tipo: "Texto",
    texto_ajuda: "",
    obrigatorio: true,
    alternativas: [],
    limite_replicacoes: 0,
    tipo_anexo: null,
    editavel: true,
    titulo_condicional: null,
    tamanho_campo: 0,
    anexo_confidencial: false,
    tipo_arquivo: null,
    exibir_mapa: false,
  };
  interface FieldErrorType {
    index: number;
    error: boolean;
    type: "nome_campo" | "alternativa_titulo";
  }
  const [fieldError, setFieldError] = useState<FieldErrorType[]>(
    [] as FieldErrorType[]
  );
  const [formData, setFormData] = useState<DataField>(intialState);
  const [formsData, setFormsData] = useState<Array<DataField>>([]);
  const [showModalCancel, setShowModalCancel] = useState(false);
  const [errorsFieldRequired, setErrorsFieldRequired] = useState<
    any | undefined
  >();
  const noValidationFields = ["Alternativas_JSON"];

  const handleRemove = async (id: number) => {
    try {
      setLoading(true);
      await deleteField(id, form_slug);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
    await getAllFields();
    setShowModalCancel(false);
  };

  const [showModalNewInput, setShowModalNewInput] = useState(false);
  const handleCloseModalNewInput = () => {
    const getMaxOrder =
      (formsData && getMaxNumberInData<DataField>(formsData, "ordem")) || 0;
    intialState.ordem = (getMaxOrder + 1).toString();
    setFormData(intialState);
    setSelectedId(undefined);
    setShowModalNewInput(false);
    setTipoCampo("Texto");
  };
  const handleShowModalNewInput = () => setShowModalNewInput(true);

  const getAllFields = async () => {
    try {
      setLoading(true);
      const { data } = await getFields(form_slug);
      if (data.length) {
        setFormsData(data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const verifyHasProperty = <T,>(alternatives: T) => {
    return alternatives?.hasOwnProperty("value");
  };

  const returnFields = () => {
    if (formData?.alternativas?.length > 0) {
      return formData.alternativas
        .filter(verifyHasProperty)
        .map((alternativa) => ({
          campo: alternativa.campo,
          ordem: alternativa.ordem,
          titulo: alternativa.titulo,
          nome_campo: alternativa.nome_campo,
          value: JSON.stringify(alternativa.value),
          valor: alternativa?.valor,
          mascara: alternativa?.mascara || "",
          obrigatorio: alternativa?.obrigatorio,
          validador: alternativa?.validador || "",
          texto_ajuda: alternativa?.texto_ajuda || "",
        }));
    }
  };

  useEffect(() => {
    if (isMounted.current) {
      getAllFields();
    }

    return () => {
      isMounted.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form_slug]);

  useEffect(() => {
    if (formsData && formsData.length > 0) {
      let getMaxOrder = getMaxNumberInData<DataField>(formsData, "ordem");
      setFormData({
        ...formData,
        ordem: (getMaxOrder + 1).toString(),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formsData]);

  useEffect(() => {
    if (
      tipoCampo === "Texto" ||
      tipoCampo === "Numero" ||
      tipoCampo === "Data" ||
      tipoCampo === "Area" ||
      tipoCampo === "Telefone" ||
      tipoCampo === "Arquivo" ||
      tipoCampo === "Imagem" ||
      tipoCampo === "Email"
    ) {
      setShowOptionsOne(true);
      setShowOptionsTwo(false);
    } else if (
      tipoCampo === "Alternativas" ||
      tipoCampo === "Alternativas_API" ||
      tipoCampo === "Matriz" ||
      tipoCampo === "Multiplos" ||
      tipoCampo === "Multiplos_API" ||
      tipoCampo === "Multiplos_Arquivos"
    ) {
      if (tipoCampo === "Matriz") {
        setFormData({ ...formData, tipo_alternativa: "Campo" });
      }
      if (tipoCampo === "Matriz" && returnFields()) {
        setHasConditional((formData as any).condicional);
        setFormData({
          ...formData,
          alternativas: returnFields(),
          tipo_alternativa: "Campo",
        });
      }
      setShowOptionsOne(false);
      setShowOptionsTwo(true);
    } else {
      setShowOptionsOne(false);
      setShowOptionsTwo(false);
    }
    if (tipoCampo === "Alternativas" && !formData.tipo_alternativa) {
      setFormData({
        ...formData,
        tipo_alternativa: "Select",
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tipoCampo]);

  const getAlternativas = useCallback(() => {
    if (typeof formData?.alternativas === "string") {
      return JSON.parse(formData.alternativas);
    } else {
      if (formData?.tipo === "Matriz") {
        const newData = formData?.alternativas?.map((alternativa) => {
          return {
            campo: alternativa.campo,
            ordem: alternativa.ordem,
            titulo: alternativa.titulo,
            nome_campo: alternativa.nome_campo,
            value:
              typeof alternativa?.value === "string"
                ? JSON.parse(alternativa.value)
                : "[]",
            valor: alternativa?.valor,
            mascara: alternativa?.mascara || "",
            obrigatorio: alternativa?.obrigatorio,
            validador: alternativa?.validador || "",
            texto_ajuda: alternativa?.texto_ajuda || "",
          };
        });

        return newData;
      } else {
        return formData.alternativas;
      }
    }
  }, [formData]);

  const handleErrors = (data: any) => {
    let errors = {};
    schema.validate(data, { abortEarly: false }).catch((err) => {
      for (let error of err.inner) {
        const { path, message } = error;
        Object.assign(errors, { [path]: message });
      }
      setErrorsFieldRequired(errors);
    });

    return errors;
  };

  const countTypeAlternativeJSONField = useCallback(() => {
    if (
      formData.tipo === "Alternativas_JSON" &&
      formData?.alternativas?.length > 0
    ) {
      return formData?.alternativas?.filter(
        (alt) => alt.campo === "Alternativas_JSON"
      )?.length;
    }
  }, [formData]);

  const structureAltJSON = `Estrutura do JSON - Padrão:
[{ 
  "id": 99,
  "label": "Bahia",
  "subvalue_data": [{
    "label": "Salvador", 
    "value": 1 
    }],
  "subvalue_label": "Estado",
  "subvalue_name": "id_estado",
  "subvalue_value_name": "",
  "value": 99
}, ...]`;

  const handleAddField = async () => {
    handleErrors(formData);
    if (
      !formData.ordem ||
      !formData.texto_ajuda ||
      !formData.titulo ||
      (formData.tipo === "Arquivo" && !formData.tipo_arquivo) ||
      (formData.tipo === "Imagem" && !formData.tipo_anexo) ||
      (formData.tipo === "Matriz" &&
        !formData.alternativas.every(
          (alternativa) => alternativa.nome_campo
        )) ||
      (formData.tipo === "Matriz" &&
        !formData.alternativas.every((alternativa) => alternativa.titulo))
    ) {
      dispatch({
        type: actionTypes.SET_FLASH_MESSAGE,
        flashMessage: {
          show: true,
          type: "error-alt",
          title: "Error",
          message: "Campos obrigatórios não foram preenchidos.",
        },
      });
      return;
    }

    if (countTypeAlternativeJSONField() > 1) {
      dispatch({
        type: actionTypes.SET_FLASH_MESSAGE,
        flashMessage: {
          show: true,
          type: "error-alt",
          title: "Error",
          message: "É permitido apenas um campo Alternativa JSON por matriz.",
        },
      });
      return;
    }

    try {
      setFieldError([]);
      setLoading(true);
      if (selectedId) {
        const { data } = await updateField(
          selectedId,
          form_slug,
          formData.ordem,
          formData.titulo,
          formData.texto_interno,
          formData.texto_ajuda,
          formData.obrigatorio,
          getAlternativas(),
          formData.tipo,
          formData.editavel,
          formData.tamanho_campo,
          hasConditional,
          formData.tipo === "CEP" ? formData.exibir_mapa : false,
          hasConditional ? formData.titulo_condicional : null,
          formData.validador,
          formData.mascara,
          formData.tipo_alternativa,
          formData.limite_replicacoes,
          formData.tipo === "Imagem" ? formData.tipo_anexo : null,
          formData.anexo_confidencial,
          formData.tipo_arquivo,
          formData.tipo === "CEP"
            ? formData.tipo_regiao_cep_permitida
            : "Salvador"
        );
        if (data) {
          handleCloseModalNewInput();
          getAllFields();
        }
      } else {
        const { data } = await postField(
          formData.ordem,
          formData.titulo,
          formData.texto_interno,
          formData.formulario,
          formData.mascara,
          formData.validador,
          formData.tipo,
          formData.texto_ajuda,
          formData.obrigatorio,
          getAlternativas(),
          formData.editavel,
          formData.tamanho_campo,
          hasConditional,
          formData.tipo === "CEP" ? formData.exibir_mapa : false,
          hasConditional ? formData.titulo_condicional : null,
          formData.tipo_alternativa,
          formData.limite_replicacoes,
          formData.tipo === "Imagem" ? formData.tipo_anexo : null,
          formData.anexo_confidencial,
          formData.tipo_arquivo,
          formData.tipo === "CEP"
            ? formData.tipo_regiao_cep_permitida
            : "Salvador"
        );
        if (data) {
          handleCloseModalNewInput();
          getAllFields();
        }
      }
      dispatch({
        type: actionTypes.SET_FLASH_MESSAGE,
        flashMessage: {
          show: true,
          type: "success",
          title: "Sucesso",
          message: "Campo salvo com sucesso",
        },
      });
    } catch (error) {
      dispatch({
        type: actionTypes.SET_FLASH_MESSAGE,
        flashMessage: {
          show: true,
          type: "error-alt",
          title: "Error",
          message: "Erro ao salvar campo.",
        },
      });
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleAddOption = () => {
    const newOption = {
      ordem: "",
      titulo: "",
    };
    setFormData({
      ...formData,
      alternativas: [...formData.alternativas, newOption],
    });
  };
  const handleChange = (event: any) => {
    const { name, value } = event.target;

    if (name === "tipo") {
      setErrorsFieldRequired(undefined);
    } else {
      console.log(errorsFieldRequired);
      errorsFieldRequired?.[name] && delete errorsFieldRequired[name];
    }

    let finalValue = name === "limite_replicacoes" ? Number(value) : value;

    setFormData({ ...formData, [name]: finalValue });
  };

  const handleChangeAlternativas = (event: any, index: number) => {
    const { name, value } = event.target;

    if (value.length > 0) {
      errorsFieldRequired?.[name] && delete errorsFieldRequired[name];
    } else {
      setFieldError([
        ...fieldError.filter(
          (error) => !(error.index === index && error.type === name)
        ),
        {
          index: index,
          error: true,
          type: name,
        },
      ]);
    }

    setFormData({ ...formData });
  };

  return (
    <ContainerMain>
      <Modal
        show={showModalNewInput}
        onHide={handleCloseModalNewInput}
        size="xl"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Inserir campo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <Form.Group className="mb-3 col-md-6">
              <Form.Label required>Escolha o tipo de Campo</Form.Label>
              <Form.Select
                placeholder="Selecione"
                value={tipoCampo}
                name="tipo"
                onChange={(e) => {
                  setTipoCampo(e.target.value);
                  handleChange(e);
                }}
              >
                <option value="Texto" selected={tipoCampo === "Texto"}>
                  Texto
                </option>
                <option value="Numero" selected={tipoCampo === "Numero"}>
                  Número
                </option>
                <option value="Data" selected={formData.tipo === "Data"}>
                  Data
                </option>
                <option value="Area" selected={formData.tipo === "Area"}>
                  Area
                </option>
                <option value="Email" selected={formData.tipo === "Email"}>
                  Email
                </option>
                <option
                  value="Telefone"
                  selected={formData.tipo === "Telefone"}
                >
                  Telefone
                </option>
                <option value="Arquivo" selected={formData.tipo === "Arquivo"}>
                  Arquivo
                </option>
                <option
                  value="Alternativas"
                  selected={formData.tipo === "Alternativas"}
                >
                  Alternativas
                </option>
                <option
                  value="Alternativas_API"
                  selected={formData.tipo === "Alternativas_API"}
                >
                  Alternativas API
                </option>
                <option
                  value="Multiplos"
                  selected={formData.tipo === "Multiplos"}
                >
                  Multiplos
                </option>
                <option
                  value="Multiplos_API"
                  selected={formData.tipo === "Multiplos_API"}
                >
                  Multiplos API
                </option>
                {/* <option value="Multiplos_Arquivos" selected={formData.tipo === 'Multiplos_Arquivos'}>Multiplos Arquivos</option> */}
                <option value="Matriz" selected={formData.tipo === "Matriz"}>
                  Matriz
                </option>
                <option value="Imagem" selected={formData.tipo === "Imagem"}>
                  Imagem
                </option>
                <option value="CEP" selected={formData.tipo === "CEP"}>
                  CEP
                </option>
              </Form.Select>
              <Form.Text className="text-muted">
                Selecione o tipo de campo adequado para a informação a ser
                inserida pelo usuário
              </Form.Text>
            </Form.Group>

            <Form.Group className="mb-3 col-md-6">
              <FormLabelCustom required>Ordem</FormLabelCustom>
              <Form.Control
                placeholder="Ordem"
                name="ordem"
                isInvalid={errorsFieldRequired && errorsFieldRequired?.ordem}
                required
                value={formData.ordem}
                onChange={handleChange}
              />
              {errorsFieldRequired && errorsFieldRequired?.ordem && (
                <Form.Control.Feedback type="invalid">
                  Campo obrigatório
                </Form.Control.Feedback>
              )}
              <Form.Text className="text-muted">
                Informe qual a ordem de exibição do campo no formulário
              </Form.Text>
            </Form.Group>

            {formData && formData.tipo === "Imagem" && (
              <Form.Group className="mb-3 col-md-4">
                <FormLabelCustom required>Tipo de anexo</FormLabelCustom>
                <Form.Select
                  required
                  placeholder="Selecione"
                  name="tipo_anexo"
                  onChange={(e) => {
                    setFormData({ ...formData, tipo_anexo: e.target.value });
                  }}
                >
                  <option value={null} disabled selected>
                    Selecione
                  </option>
                  <option
                    value="Formulário"
                    selected={formData.tipo_anexo === "Formulário"}
                  >
                    Formulário
                  </option>
                  <option
                    value="Documento"
                    selected={formData.tipo_anexo === "Documento"}
                  >
                    Documento
                  </option>
                </Form.Select>
                <Form.Text className="text-muted">
                  Informe qual tipo de anexo
                </Form.Text>
              </Form.Group>
            )}

            {formData && formData.tipo === "Arquivo" && (
              <Form.Group className="mb-3 col-md-6">
                <FormLabelCustom required>
                  Escolha o tipo de arquivo
                </FormLabelCustom>
                <Form.Select
                  required
                  placeholder="Selecione"
                  isInvalid={
                    errorsFieldRequired && errorsFieldRequired?.tipo_arquivo
                  }
                  name="tipo_arquivo"
                  defaultValue={formData.tipo_arquivo}
                  onChange={(e) => {
                    setFormData({ ...formData, tipo_arquivo: e.target.value });
                  }}
                >
                  <option value={null} disabled selected>
                    Selecione
                  </option>
                  <option
                    value={["application/pdf", "image/png", "image/jpeg"]}
                    selected={formData.tipo_arquivo === "Todos"}
                  >
                    Todos
                  </option>
                  <option
                    value="application/pdf"
                    selected={formData.tipo_arquivo === "PDF"}
                  >
                    PDF
                  </option>
                  <option
                    value="image/png"
                    selected={formData.tipo_arquivo === "PNG"}
                  >
                    PNG
                  </option>
                  <option
                    value="image/jpeg"
                    selected={formData.tipo_arquivo === "JPEG/JPG"}
                  >
                    JPEG/JPG
                  </option>
                </Form.Select>
                {errorsFieldRequired && errorsFieldRequired?.tipo_arquivo && (
                  <Form.Control.Feedback type="invalid">
                    Campo obrigatório
                  </Form.Control.Feedback>
                )}
                <Form.Text className="text-muted">
                  Selecione o tipo de arquivo aceito neste campo.
                </Form.Text>
              </Form.Group>
            )}
          </div>

          <div className="row">
            <Form.Group className="mb-3 col-md-6">
              <Form.Label>Editável</Form.Label>
              <Form.Select
                placeholder="Selecione"
                name="editavel"
                id="select-editavel"
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    editavel: e.target.value === "true",
                    obrigatorio:
                      e.target.value === "true" ? formData.obrigatorio : false,
                  });
                }}
              >
                <option
                  value="true"
                  id="option-editavel-true"
                  selected={formData.editavel}
                >
                  Sim
                </option>
                <option
                  value="false"
                  id="option-editavel-false"
                  selected={!formData.editavel}
                >
                  Não
                </option>
              </Form.Select>
              <Form.Text className="text-muted">
                Informe se o campo será editável ou não
              </Form.Text>
            </Form.Group>
            {formData.editavel && (
              <Form.Group className="mb-3 col-md-6">
                <Form.Label>Obrigatório</Form.Label>
                <Form.Select
                  placeholder="Selecione"
                  name="obrigatorio"
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      obrigatorio: e.target.value === "true",
                    });
                  }}
                >
                  <option value="true" selected={formData.obrigatorio}>
                    Sim
                  </option>
                  <option value="false" selected={!formData.obrigatorio}>
                    Não
                  </option>
                </Form.Select>
                <Form.Text className="text-muted">
                  Informe se o campo terá preenchimento obrigatório ou não
                </Form.Text>
              </Form.Group>
            )}
          </div>
          {formData.tipo === "CEP" && (
            <div className="row">
              <Form.Group className="mb-3 col-md-6">
                <Form.Label>Região do CEP</Form.Label>
                <Form.Select
                  placeholder="Selecione"
                  name="regiao-cep"
                  id="select-regiao-cep"
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      tipo_regiao_cep_permitida: e.target.value as
                        | "Salvador"
                        | "Bahia",
                    });
                  }}
                >
                  <option
                    value="Salvador"
                    id="option-regiao-cep-1"
                    selected={formData.tipo_regiao_cep_permitida === "Salvador"}
                  >
                    Salvador
                  </option>
                  <option
                    value="Bahia"
                    id="option-regiao-cep-2"
                    selected={formData.tipo_regiao_cep_permitida === "Bahia"}
                  >
                    Bahia
                  </option>
                </Form.Select>
                <Form.Text className="text-muted">
                  Informe a região que o campo CEP aceitará como válida
                </Form.Text>
              </Form.Group>
              <Form.Group className="mb-3 col-md-6">
                <Form.Label>Exibir mapa</Form.Label>
                <Form.Select
                  placeholder="Exibir Mapa"
                  name="mapa"
                  id="select-mapa"
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      exibir_mapa: e.target.value === "true",
                    });
                  }}
                >
                  <option
                    value="true"
                    id="option-mapa-true"
                    selected={formData.exibir_mapa}
                  >
                    Sim
                  </option>
                  <option
                    value="false"
                    id="option-mapa-false"
                    selected={!formData.exibir_mapa}
                  >
                    Não
                  </option>
                </Form.Select>
                <Form.Text className="text-muted">
                  Indique se deseja que o mapa seja exibido para auxiliar no
                  preenchimento do endereço.{" "}
                </Form.Text>
              </Form.Group>
            </div>
          )}
          {(formData.tipo === "Arquivo" || formData.tipo === "Imagem") && (
            <>
              <div className="row">
                <Form.Group className="mb-3 col-md-12">
                  <Form.Label>Arquivo confidencial</Form.Label>
                  <Form.Select
                    placeholder="Selecione"
                    name="anexo_confidencial"
                    id="select-anexo_confidencial"
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        anexo_confidencial: e.target.value === "true",
                      });
                    }}
                  >
                    <option
                      value="true"
                      id="option-confidencial-true"
                      selected={formData.anexo_confidencial}
                    >
                      Sim
                    </option>
                    <option
                      value="false"
                      id="option-confidencial-false"
                      selected={!formData.anexo_confidencial}
                    >
                      Não
                    </option>
                  </Form.Select>
                  <Form.Text className="text-muted">
                    Informe se o arquivo é confidencial ou não
                  </Form.Text>
                </Form.Group>
              </div>
            </>
          )}

          <div className="row">
            <Form.Group className="mb-3 col-md-6">
              <FormLabelCustom required>Título</FormLabelCustom>
              <Form.Control
                placeholder="Título"
                isInvalid={errorsFieldRequired && errorsFieldRequired?.titulo}
                required
                name="titulo"
                value={formData.titulo}
                onChange={handleChange}
              />
              {errorsFieldRequired && errorsFieldRequired?.titulo && (
                <Form.Control.Feedback type="invalid">
                  Campo obrigatório
                </Form.Control.Feedback>
              )}
              <Form.Text className="text-muted">
                Informe um título adequado para o campo
              </Form.Text>
            </Form.Group>
            <Form.Group className="mb-3 col-md-6">
              <FormLabelCustom>Tamanho do campo</FormLabelCustom>
              <Form.Control
                type="number"
                placeholder="Tamanho do campo"
                name="tamanho_campo"
                required
                value={formData.tamanho_campo}
                onChange={handleChange}
              />
              <Form.Text className="text-muted">
                Informe o tamanho do campo permitido.
              </Form.Text>
            </Form.Group>
          </div>
          <div className="row">
            <Form.Group className="mb-3 col-md-6">
              <FormLabelCustom required>Texto de Ajuda</FormLabelCustom>
              <Form.Control
                placeholder="Texto de Ajuda"
                name="texto_ajuda"
                isInvalid={
                  errorsFieldRequired && errorsFieldRequired?.texto_ajuda
                }
                required
                value={formData.texto_ajuda}
                onChange={handleChange}
              />
              {errorsFieldRequired && errorsFieldRequired?.texto_ajuda && (
                <Form.Control.Feedback type="invalid">
                  Campo obrigatório
                </Form.Control.Feedback>
              )}
              <Form.Text className="text-muted">
                Essa informação irá orientar o usuário a preencher corretamente
                o campo
              </Form.Text>
            </Form.Group>
            <Form.Group className="mb-3 col-md-6">
              <Form.Label>Texto interno</Form.Label>
              <Form.Control
                placeholder="Texto interno"
                name="texto_interno"
                required
                value={formData.texto_interno}
                onChange={handleChange}
              />
              <Form.Text className="text-muted">
                Insira uma orientação de preenchimento do campo
              </Form.Text>
            </Form.Group>

            <Form.Group className="mb-3 col-md-6">
              <Form.Label>Máscara</Form.Label>
              <Form.Control
                placeholder="Máscara"
                name="mascara"
                required
                value={formData.mascara}
                onChange={handleChange}
              />
              <Form.Text className="text-muted">
                A máscara irá definir o formato do conteúdo a ser digitado no
                campo
              </Form.Text>
            </Form.Group>
            <Form.Group className="mb-3 col-md-6">
              <Form.Label>Validador</Form.Label>
              <Form.Control
                placeholder="Validador"
                name="validador"
                required
                value={formData.validador}
                onChange={handleChange}
              />
              <Form.Text className="text-muted">
                Informe uma expressão regular para validar o preenchimento do
                campo
              </Form.Text>
            </Form.Group>
          </div>
          {formData.tipo === "Multiplos_Arquivos" && (
            <div>
              <Form.Group className="mb-3 col-md-6">
                <Form.Label>Limite de Arquivos</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Limite de Arquivos"
                  name="limite_replicacoes"
                  required
                  value={formData.limite_replicacoes}
                  onChange={(event) => {
                    const rgx = /^[0-9\b]+$/;

                    if (
                      event.currentTarget.value === "" ||
                      rgx.test(event.currentTarget.value)
                    ) {
                      handleChange(event);
                    }
                  }}
                />
                <Form.Text className="text-muted">
                  Informe o limite de arquivos permitidos.
                </Form.Text>
              </Form.Group>
            </div>
          )}
          {formData.tipo === "Matriz" && (
            <div>
              <Form.Group className="mb-3 col-md-6">
                <Form.Label>Limite de Replicações</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Limite de Replicações"
                  name="limite_replicacoes"
                  required
                  value={formData.limite_replicacoes}
                  onChange={handleChange}
                />
                <Form.Text className="text-muted">
                  Informe o limite de replicações permitido.
                </Form.Text>
              </Form.Group>
            </div>
          )}
          {formData.tipo === "Matriz" && hasConditional && (
            <Form.Group className="mb-3 col-md-6">
              <Form.Label>Título da condição</Form.Label>
              <Form.Control
                placeholder="Título da condição"
                name="titulo_condicional"
                required
                value={formData.titulo_condicional}
                onChange={handleChange}
              />
              <Form.Text className="text-muted">
                Insira um título para a condição
              </Form.Text>
            </Form.Group>
          )}
          {formData.tipo === "Matriz" && (
            <div>
              <Form.Group className="mb-3 col-md-6">
                <CustomFormControlLabel
                  control={
                    <Checkbox
                      checked={hasConditional}
                      onChange={() => setHasConditional(!hasConditional)}
                      name="accept"
                      color="primary"
                    />
                  }
                  label="Marque se o campo precisa ser marcado para exibir os campos da matriz"
                />
              </Form.Group>
            </div>
          )}
          {showOptionsTwo && (
            <>
              {formData.tipo === "Alternativas" && (
                <>
                  <Form.Group className="mb-3 col-md-12">
                    <Form.Label>Tipo de Alternativa</Form.Label>
                    <Form.Select
                      placeholder="Selecione"
                      name="tipo_alternativa"
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          tipo_alternativa: e.target.value,
                        });
                      }}
                      value={formData.tipo_alternativa}
                    >
                      <option value="Select">Select</option>
                      <option value="RadioButton">RadioButton</option>
                    </Form.Select>
                  </Form.Group>
                </>
              )}

              {(formData.tipo === "Alternativas_API" ||
                formData.tipo === "Multiplos_API") && (
                <>
                  <div className="row">
                    <Form.Group className="mb-3 col-md-12">
                      <Form.Label style={{ marginRight: "10px" }}>
                        Adicionar dados (JSON)
                      </Form.Label>
                      <Form.Text className="text-muted">
                        {
                          'Estrutura do JSON - Padrão [{"name": "6A02.0", "value": "6A02.0" }, ...]'
                        }
                      </Form.Text>
                      <Form.Control
                        as="textarea"
                        placeholder="Adicionar dados como JSON"
                        style={{ height: "300px" }}
                        name="alternativas"
                        defaultValue={JSON.stringify(formData.alternativas)}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </div>
                </>
              )}

              {(formData.tipo === "Alternativas" ||
                formData.tipo === "Multiplos" ||
                formData.tipo === "Matriz") && (
                <>
                  <div className="row">
                    <div className="fw-bold">
                      Lista de{" "}
                      {formData.tipo === "Matriz" ? "Campos" : "Alternativas"}
                    </div>
                  </div>
                  {formData.alternativas.map((alternativa, index) => (
                    <div className="row" key={index.toString()}>
                      <Form.Group className="mb-3 col-md-1">
                        <Form.Label>Ordem</Form.Label>
                        <Form.Control
                          placeholder="Ordem"
                          readOnly
                          value={index + 1}
                          onChange={(e) => {
                            formData.alternativas[index].ordem = e.target.value;
                            setFormData({ ...formData });
                          }}
                        />
                      </Form.Group>
                      <Form.Group className="mb-3 col-md-4">
                        <FormLabelCustom required>Título</FormLabelCustom>
                        <Form.Control
                          placeholder="Titulo"
                          value={alternativa.titulo}
                          name="alternativa_titulo"
                          isInvalid={fieldError.some(
                            (error) =>
                              error.index === index &&
                              error.type === "alternativa_titulo"
                          )}
                          onChange={(e) => {
                            formData.alternativas[index].titulo =
                              e.target.value;
                            formData.alternativas[index].ordem = (
                              index + 1
                            ).toString();
                            handleChangeAlternativas(e, index);
                          }}
                        />
                        {fieldError.some(
                          (error) =>
                            error.index === index &&
                            error.type === "alternativa_titulo"
                        ) && (
                          <Form.Control.Feedback type="invalid">
                            Campo obrigatório
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                      {formData.tipo !== "Matriz" ? (
                        <Form.Group className="mb-3 col-md-5">
                          <Form.Label>Valor</Form.Label>
                          <Form.Control
                            placeholder="Titulo"
                            value={alternativa.valor}
                            onChange={(e) => {
                              formData.alternativas[index].valor =
                                e.target.value;
                              setFormData({ ...formData });
                            }}
                          />
                        </Form.Group>
                      ) : (
                        <>
                          <Form.Group className="mb-3 col-md-4">
                            <Form.Label required>
                              Escolha o tipo de Campo
                            </Form.Label>
                            <Form.Select
                              placeholder="Selecione"
                              value={alternativa.campo}
                              name="tipo"
                              onChange={(e) => {
                                formData.alternativas[index].validador = "";
                                formData.alternativas[index].campo =
                                  e.target.value;
                                setFormData({ ...formData });
                              }}
                            >
                              <option value={null} selected disabled>
                                Selecione
                              </option>
                              <option
                                value="Texto"
                                selected={tipoCampo === "Texto"}
                              >
                                Texto
                              </option>
                              <option
                                value="Numero"
                                selected={tipoCampo === "Numero"}
                              >
                                Número
                              </option>
                              <option
                                value="Data"
                                selected={tipoCampo === "Data"}
                              >
                                Data
                              </option>
                              <option
                                value="Area"
                                selected={tipoCampo === "Area"}
                              >
                                Area
                              </option>
                              <option
                                value="Email"
                                selected={tipoCampo === "Email"}
                              >
                                Email
                              </option>
                              <option
                                value="Telefone"
                                selected={tipoCampo === "Telefone"}
                              >
                                Telefone
                              </option>
                              <option
                                value="Alternativas_JSON"
                                selected={tipoCampo === "Alternativas_JSON"}
                              >
                                Alternativas JSON
                              </option>
                            </Form.Select>
                            <Form.Text className="text-muted">
                              Selecione o tipo de campo adequado para a
                              informação a ser inserida pelo usuário
                            </Form.Text>
                          </Form.Group>
                          <Form.Group className="mb-3 col-md-2">
                            <FormLabelCustom required>
                              Nome do campo
                            </FormLabelCustom>
                            <Form.Control
                              name="nome_campo"
                              placeholder="Nome do campo"
                              isInvalid={fieldError.some(
                                (error) =>
                                  error.index === index &&
                                  error.type === "nome_campo"
                              )}
                              value={alternativa.nome_campo}
                              onChange={(e) => {
                                formData.alternativas[index].nome_campo =
                                  e.target.value;
                                handleChangeAlternativas(e, index);
                              }}
                            />
                            {fieldError.some(
                              (error) =>
                                error.index === index &&
                                error.type === "nome_campo"
                            ) ? (
                              <Form.Control.Feedback type="invalid">
                                Campo obrigatório
                              </Form.Control.Feedback>
                            ) : (
                              <Form.Text className="text-muted">
                                Informe um identificador único para o nome do
                                campo
                              </Form.Text>
                            )}
                          </Form.Group>
                          <Form.Group className="mb-3 col-md-4">
                            <Form.Label>Máscara</Form.Label>
                            <Form.Control
                              name="mascara"
                              placeholder="Máscara"
                              value={alternativa.mascara}
                              onChange={(e) => {
                                formData.alternativas[index].mascara =
                                  e.target.value;
                                setFormData({ ...formData });
                              }}
                            />
                            <Form.Text className="text-muted">
                              A máscara irá definir o formato do conteúdo a ser
                              digitado no campo
                            </Form.Text>
                          </Form.Group>
                          {!noValidationFields.includes(
                            formData.alternativas[index].campo
                          ) && (
                            <Form.Group className="mb-3 col-md-4">
                              <Form.Label>Validador</Form.Label>
                              <Form.Control
                                name="validador"
                                placeholder="Validador"
                                value={alternativa.validador}
                                onChange={(e) => {
                                  formData.alternativas[index].validador =
                                    e.target.value;
                                  setFormData({ ...formData });
                                }}
                              />
                              <Form.Text className="text-muted">
                                Informe uma expressão regular para validar o
                                preenchimento do campo
                              </Form.Text>
                            </Form.Group>
                          )}
                          <Form.Group className="mb-3 col-md-4">
                            <Form.Label>Texto ajuda</Form.Label>
                            <Form.Control
                              name="texto_ajuda"
                              placeholder="Texto ajuda"
                              value={alternativa.texto_ajuda}
                              onChange={(e) => {
                                formData.alternativas[index].texto_ajuda =
                                  e.target.value;
                                setFormData({ ...formData });
                              }}
                            />
                            <Form.Text className="text-muted">
                              Essa informação irá orientar o usuário a preencher
                              corretamente o campo
                            </Form.Text>
                          </Form.Group>
                          <Form.Group className="mb-3 col-sm-2">
                            <Form.Label>Obrigatório</Form.Label>
                            <Form.Select
                              placeholder="Selecione"
                              name="obrigatorio"
                              onChange={(e) => {
                                formData.alternativas[index].obrigatorio =
                                  e.target.value === "true";
                                setFormData({ ...formData });
                              }}
                            >
                              <option value={null} disabled selected>
                                Selecione
                              </option>
                              <option
                                value="true"
                                selected={alternativa.obrigatorio}
                              >
                                Sim
                              </option>
                              <option
                                value="false"
                                selected={!alternativa.obrigatorio}
                              >
                                Não
                              </option>
                            </Form.Select>
                            <Form.Text className="text-muted">
                              Informe se o campo terá preenchimento obrigatório
                              ou não
                            </Form.Text>
                          </Form.Group>
                          {formData.tipo === "Matriz" &&
                            formData.alternativas[index].campo ===
                              "Alternativas_JSON" && (
                              <>
                                <Form.Group className="mb-3 col-md-6">
                                  <Form.Label>Alternativas JSON</Form.Label>
                                  <Form.Control
                                    as="textarea"
                                    placeholder="Adicionar dados como JSON"
                                    style={{ height: "150px" }}
                                    name="alternativas_json"
                                    onChange={(e) => {
                                      formData.alternativas[index].value =
                                        e.target.value;
                                      setFormData({ ...formData });
                                    }}
                                    value={alternativa.value}
                                  />
                                  <Form.Text className="text-muted">
                                    Informe as alternativas em formato json
                                  </Form.Text>
                                </Form.Group>
                                <Box
                                  style={{ order: "revert" }}
                                  className="mb-3 col-md-4"
                                >
                                  <Form.Text className="text-muted">
                                    <pre>{structureAltJSON}</pre>
                                  </Form.Text>
                                </Box>
                              </>
                            )}
                        </>
                      )}
                      <div className="col-md-2 mt-3 pt-2">
                        <Button
                          variant="danger"
                          onClick={() => {
                            const alternativas = formData.alternativas;
                            alternativas.splice(index, 1);
                            setFormData({ ...formData, alternativas });
                          }}
                        >
                          Remover
                        </Button>
                      </div>
                      <hr />
                    </div>
                  ))}
                  <Button onClick={handleAddOption}>Adicionar</Button>
                </>
              )}
            </>
          )}
          {formData.tipo === "CEP" && (
            <Alert severity="info">
              Ao criar o campo CEP, se torna extremamente necessária a criação
              dos campos Município, UF, Logradouro e Bairro.
            </Alert>
          )}
        </Modal.Body>
        <Modal.Footer>
          {loading ? (
            <Spinner animation="border" variant="primary" />
          ) : (
            <>
              <Button variant="danger" onClick={handleCloseModalNewInput}>
                Cancelar
              </Button>
              <Button variant="primary" onClick={handleAddField}>
                Salvar
              </Button>
            </>
          )}
        </Modal.Footer>
      </Modal>
      <Card>
        <CardHeader>
          <Box>
            <CardIcon>
              <MdSettings size={20} />
            </CardIcon>
          </Box>
          <Title>Gerenciar Campos</Title>
        </CardHeader>
        <Card.Body>
          <ButtonHeader onClick={handleShowModalNewInput}>
            Incluir Campo
          </ButtonHeader>
          <ListGroup>
            {formsData.map((form, index) => (
              <ListGroup.Item
                key={index.toString()}
                className="d-flex justify-content-between align-items-start"
              >
                {" "}
                <div className="ms-2 me-auto">
                  <div className="fw-bold">{form.tipo}</div>
                  {form.ordem} - {form.titulo} -{" "}
                  {form.obrigatorio ? "Campo obrigatório" : ""}
                </div>
                <div>
                  <Button
                    variant="primary m-2"
                    onClick={() => {
                      setSelectedId(form.id);
                      setTipoCampo(form.tipo);
                      setFormData(form);
                      setShowModalNewInput(true);
                    }}
                  >
                    Detalhes
                  </Button>
                  <Button
                    variant="danger"
                    onClick={() => {
                      setSelectedId(form.id);
                      setShowModalCancel(true);
                    }}
                  >
                    Remover
                  </Button>
                </div>
              </ListGroup.Item>
            ))}
          </ListGroup>
          {loading && <Spinner animation="border" variant="primary" />}
        </Card.Body>
        <Box style={{ display: "flex", marginLeft: 22, marginBottom: 46 }}>
          <Button
            className="button"
            style={{
              backgroundColor: "#EC4C47",
              border: 0,
              display: "flex",
              alignItems: "center",
            }}
            onClick={() => history.push(`/informacoes/${flow_slug}`)}
          >
            <RiCloseCircleFill size={20} />
            <span style={{ marginLeft: 8 }}>Fechar</span>
          </Button>
        </Box>
      </Card>
      <CancelModal
        show={showModalCancel}
        handleShow={setShowModalCancel}
        title="Remover Campo"
        description="Tem certeza que deseja remover o campo?"
        handleActionOk={() => handleRemove(selectedId)}
        loading={loading}
      />
    </ContainerMain>
  );
};
export default Build;
