import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { TypeProfile } from "../interfaces/permissions";
import getCookie from "../utils/getCookies";
import { getMyPermissions } from "../services/flow";

type ProfileContextType = {
    typeProfileActive: TypeProfile;
    verifyProfileAllowed: boolean;
};

const ProfileContext = createContext<ProfileContextType>({
    typeProfileActive: 'Administrador',
    verifyProfileAllowed: false,
});

export default function ProfileProvider({ children }) {
    const [typeProfileActive, setTypeProfileActive] = useState<TypeProfile>('Administrador');

    useEffect(() => {
        async function handleGetMyPermissions() {
            try {
                const token: string | null = getCookie("gov_access_token");
                const { data } = await getMyPermissions(token);

                setTypeProfileActive(data.perfil);
            } catch (err) {
                console.log(err);
            }
        }

        handleGetMyPermissions();
    }, []);


    const verifyProfileAllowed = useMemo(() => {
        return (
            typeProfileActive === 'Coordenador'
            || typeProfileActive === 'Atendente'
        );
    }, [typeProfileActive]);

    return (
        <ProfileContext.Provider
            value={{
                typeProfileActive,
                verifyProfileAllowed
            }}
        >
            {children}
        </ProfileContext.Provider>
    );
}

export const useProfile = () => useContext(ProfileContext);