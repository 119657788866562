import React from "react";

import { Container, CircleBox, Title } from './styles';

interface StatusProps {
  type: string;
}

const statusText = {
  'true': 'Sim',
  'false': 'Não'
}

const Status = ({ type }: StatusProps) => {
  return (
    <Container>
      <CircleBox type={type} />
      <Title type={type}>{statusText[type]}</Title>
    </Container>
  );
}

export default Status;