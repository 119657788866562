import { Button, Card, Container, Dropdown, Form, Table } from "react-bootstrap";
import { MdSearch } from "react-icons/md";
import styled from "styled-components";


export const Box = styled.div`
  display: flex;
  align-items: center;
`;

export const CardIcon = styled.div`
  width: 56px;
  height: 56px;
  background-color: ${({ theme }) => theme.colors.background_icon};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  color: ${({ theme }) => theme.colors.primary};
  margin-right: 16px;
`;

export const CardHeader = styled(Card.Header)`
  display: flex;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.shape};
  align-items: center;
  padding: 20px;
  flex-wrap: wrap;
`;

export const Title = styled.p`
  font-size: 24px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.secundary};
  margin: 0;
`;

export const ContainerMain = styled(Container)`
  margin-top: 56px;
  margin-bottom: 20px;
`;

export const CustomTable = styled(Table)`
  margin-top: 32px;

  .table-header {
    background-color: ${({ theme }) => theme.colors.secundary};
    color: ${({ theme }) => theme.colors.shape};
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 700;
    border: none;
  }

  .table-body td {
    padding: 30px 10px;
  }

  .table-body {
    border-top: none !important;
  }

  .table-body tr:nth-of-type(odd) {
  background-color: rgba(14, 134, 254, 0.05);
}
`;

export const DropdownCustom = styled(Dropdown)`
  .dropdown-toggle::after {
    display: none !important;
  }
  .drop-button {
    background-color: #f8f9fa00;
    border: none;
    color: ${({ theme }) => theme.colors.text}
  }
`;

export const DropdownItemCustom = styled(Dropdown.Item)`
  font-size: 16px;
  color: ${({ theme }) => theme.colors.text};
  font-weight: 400;

  .icon-action {
    color: ${({ theme }) => theme.colors.text};
    font-size: 15px;
    margin-right: 8px;
  }
`;

export const ButtonLink = styled.button`
  all: unset;
`;

export const NoFoundData = styled.h4`
  display: flex;
  justify-content: center;
  margin-top: 10px;
`;

export const CardFooterCustom = styled(Card.Footer)`
  background-color: ${({ theme }) => theme.colors.shape};
  display: flex;
  justify-content: end;
`;


export const SelectFilter = styled(Form.Select)`
  border: 1.5px solid #E1E6EF;
  border-radius: 0;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  letter-spacing: 0.3px;
  color: #1E212A;
  width: 142px;
  height: 44px;
  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 10px;
    margin-right: 10px;
  }
`;

export const FormFilter = styled.div`
  display: flex;
  margin-top: 10px;
  flex-wrap: wrap;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const OptionFilter = styled.option`

`;

export const BoxSearch = styled.div`
  display: flex;
  align-items: center;
  width: 340px;
  height: 44px;
  background: #F3F5FB;
  border: 1.5px solid #E1E6EF;
  padding-left: 10px;
  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 10px;
    margin-right: 10px;
  }
`;

export const IconSearch = styled(MdSearch)`
  color: #1E212A;
`;

export const InputSearch = styled.input`
  border: none;
  background: none;
  outline: none !important;
`;

export const InputDateInicial = styled.input`
  border: none;
  border-right: 1.5px solid #E1E6EF;
  height: 44px;
  width: 170px;
  background: none;
  outline: none !important;
  margin-right: 10px;
  align-items: center;
  padding: 5px;
`;

export const InputDateFinal = styled.input`
  border: none;
  height: 44px;
  width: 170px;
  background: none;
  outline: none !important;
  margin-left: 10px;
  align-items: center;
  padding: 5px;
`;

export const ButtonSearch = styled(Button)`
  border-radius: 0 4px 4px 0;
  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 10px;
    margin-right: 10px;
  }
`;

export const ButtonClearSearch = styled(Button)`
  border-radius: 0 4px 4px 0;
  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 10px;
    margin-right: 10px;
  }

`;