import { Button, Modal } from 'react-bootstrap';
import styled from 'styled-components';

type CardIconTypes = {
  type: 'error' | 'success';
};

export const CustomModal = styled(Modal)`
  .modal-footer > * {
    margin: 0;
  }
`;
export const CustomBody = styled(Modal.Body)`
  margin: 40px 29px;
  padding: 0;
`;
export const BoxWarning = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const CardIcon = styled.div<CardIconTypes>`
  width: 56px;
  height: 56px;
  background-color: ${({ theme }) => theme.colors.background_icon};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  color: ${({ theme }) => theme.colors.success};
`;
export const TitleWarning = styled.h4`
  font-size: 20px;
  margin-top: 24px;
  color: ${({ theme }) => theme.colors.text};
`;
export const Warning = styled.div`
  color: ${({ theme }) => theme.colors.text_secundary};
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  width: 370px;
  text-align: center;
`;

export const CustomFooter = styled(Modal.Footer)`
  flex-wrap: nowrap;
  background-color: ${({ theme }) => theme.colors.shape};
  padding: 0px;
`;

export const CustomButton = styled(Button)`
  width: 50%;
  border: none;
  border-radius: 0;
  box-shadow: none !important;
  font-size: 16px;
`;

export const ButtonConfirmed = styled(CustomButton)`
  background-color: ${({ theme }) => theme.colors.success} !important;
  color: ${({ theme }) => theme.colors.shape} !important;
`;

export const ButtonDeny = styled(CustomButton)`
  background-color: ${({ theme }) => theme.colors.shape} !important;
  color: ${({ theme }) => theme.colors.text_secundary} !important ;
`;
