import { AxiosResponse } from 'axios';
import { apiouvidoria } from './api';

export const searchCep = (
  cep: string,
  cidade: string,
  uf: string
): Promise<AxiosResponse> =>
  apiouvidoria.post('checar-cep/', {
    cep,
    cidade,
    uf,
  });
