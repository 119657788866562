import styled from "styled-components";

interface PropsTitle {
  type: string;
}

interface CircleProps {
  type: string;
}

export const Container = styled.div`
  display: flex;
  align-items: center;
`;

export const CircleBox = styled.span<CircleProps>`
  background-color: ${({ theme, type }) =>
    type === 'true' ? theme.colors.success : theme.colors.error
  };
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 8px;
`;

export const Title = styled.span<PropsTitle>`
  color: ${({ theme, type }) =>
    type === 'true' ? theme.colors.success : theme.colors.error
  };
`;