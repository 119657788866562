import styled from 'styled-components';
import { Button, Card, Container } from 'react-bootstrap';

export const ContainerMain = styled(Container)`
  margin-top: 56px;
  margin-bottom: 20px;
`;

export const CardHeader = styled(Card.Header)`
  display: flex;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.shape};
  align-items: center;
  padding: 20px;
  flex-wrap: wrap;
`;

export const Box = styled.div`
  display: flex;
  align-items: center;
`;

export const CardIcon = styled.div`
  width: 56px;
  height: 56px;
  background-color: ${({ theme }) => theme.colors.background_icon};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  color: ${({ theme }) => theme.colors.primary};
  margin-right: 16px;
`;

export const Title = styled.p`
  font-size: 24px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.secundary};
  margin: 0;
`;

export const BoxContain = styled.div`
  margin: 56px 100px 164px 100px;
`;

export const CardFooterCustom = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ButtonCancel = styled.button`
  flex: 0.5;
  border: 1px solid #e1e6ef;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  color: #506176;
  height: 48px;
  background-color: #ffffff;
  cursor: pointer;
`;

export const ButtonSave = styled.button`
  flex: 0.5;
  border: 1px solid #e1e6ef;
  height: 48px;
  background-color: #27ab6e;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  color: #ffffff;
  cursor: pointer;
`;

export const ButtonReturn = styled(Button)`
  background-color: ${({ theme }) => theme.colors.text_secundary};
  width: 100%;
  border-radius: 0;
  &:hover {
    background-color: ${({ theme }) => theme.colors.secundary} !important;
    filter: brightness(80%);
    color: ${({ theme }) => theme.colors.shape};
  }
  &:focus {
    background-color: ${({ theme }) => theme.colors.secundary} !important;
  }
`;

export const MainTitle = styled.p`
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
  font-size: 24px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.text};
`;

export const SubTitle = styled.p`
  font-weight: 500;
  font-size: 18px;
  color: ${({ theme }) => theme.colors.text};
`;

export const Description = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.text};
`;
