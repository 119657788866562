import React from "react"
import { Button, Card, Col, Modal, Row } from "react-bootstrap";
import { MdClose, MdVisibility } from 'react-icons/md';
import { HeaderModal, Info, TitleModal, BoxCloseModal, InfoTitle, InformationDescription } from './styles';
import dateFormat from "../../../../utils/dateFormat";


interface Props {
  isActive: boolean;
  handleActive: () => void;
  data: any;
}

const InformationWorkflowModal = ({
  isActive,
  handleActive,
  data
}: Props) => {
  return (
    <Modal size='lg' show={isActive} onHide={handleActive}>
      <HeaderModal>
        <Info>
          <MdVisibility size={22} />
          <TitleModal>{data?.titulo}</TitleModal>
        </Info>
        <BoxCloseModal onClick={handleActive}>
          <MdClose size={22} />
        </BoxCloseModal>
      </HeaderModal>
      <Modal.Body>
        {data && (
          <Card>

            <Card.Body>
              <Row>
                <Col sm={4}>
                  <InfoTitle>Título</InfoTitle>
                  <InformationDescription>
                    {data?.titulo}
                  </InformationDescription>
                  <InfoTitle>Descrição</InfoTitle>
                  <InformationDescription>
                    {data?.descricao}
                  </InformationDescription>
                  <InfoTitle>Tipo</InfoTitle>
                  <InformationDescription>
                    {data?.tipo}
                  </InformationDescription>
                </Col>
                <Col sm={4}>
                  <InfoTitle>URL</InfoTitle>
                  <InformationDescription>
                    {data?.url}
                  </InformationDescription>

                  <InfoTitle>Cadastrado por</InfoTitle>
                  <InformationDescription>
                    {data?.user_data?.nome}
                  </InformationDescription>

                </Col>
                <Col sm={4}>
                  <InfoTitle>Adicionado em</InfoTitle>
                  <InformationDescription>
                    {dateFormat(data?.created_at, true)}
                  </InformationDescription>
                  <InfoTitle>Atualizado em </InfoTitle>
                  <InformationDescription>
                    {dateFormat(data?.updated_at, true)}
                  </InformationDescription>

                </Col>

              </Row>
              <Row>
                <Col sm={12}>
                  <InfoTitle>Mapa</InfoTitle>
                  <InformationDescription>
                    {`{`}
                  </InformationDescription>
                  {data?.mapa && (Object.entries(data?.mapa).map(([key, value]) => (<InformationDescription>
                    &nbsp; &nbsp; &nbsp; &nbsp;{key} : {value}
                  </InformationDescription>)))}
                  <InformationDescription>
                    {`}`}
                  </InformationDescription>
                </Col>

              </Row>
            </Card.Body>
          </Card>
        )}

      </Modal.Body>
      <Modal.Footer className="justify-content-start">
        <Button
          variant="danger"
          onClick={handleActive}
        >
          Fechar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default InformationWorkflowModal;
